import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Cancel';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import EditIcon from '@material-ui/icons/Edit';
import PhoneIcon from '@material-ui/icons/Phone';
import RedoIcon from '@material-ui/icons/Redo';
import RateReviewIcon from '@material-ui/icons/RateReview';
import {
  centsToFormattedDollars,
  longDateOnly,
  mediumDateOnly,
  personName,
  timeRange,
} from '@onwardcare/core';
import LocationRow from 'components/LocationRow';
import Map from 'components/Map';
import ParagraphText from 'components/ParagraphText';
import CancelConfirmation from 'components/rides/CancelConfirmation';
import DriverCard from 'components/rides/DriverCard';
import InProgressIndicator from 'components/rides/InProgressIndicator';
import RiderCard from 'components/rides/RiderCard';
import RideTypeBadge from 'components/rides/RideTypeBadge';
import TransportTypeBadge from 'components/rides/TransportTypeBadge';
import ZeroStateText from 'components/ZeroStateText';
import { FC, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Account,
  Ride,
  useDeclineBestTimeBidsMutationMutation,
} from '../../generated/graphql';
import { AddMedicalNecessityStatementView } from './rideFormComponents/AddMedicalNecessityStatement/AddMedicalNecessityStatementView';
import { Review } from './Review';
import { useAuth } from '../../contexts/auth-context';
import { getCmFromInches, getKgFromLBS } from './rideFormComponents/helpers';
import { useVetridesContext } from '../../contexts/vetrides-context';
import ENV from '../../constants/Env';
import FlashOnOutlinedIcon from '@material-ui/icons/FlashOnOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { FeedbackForm } from './FeedbackForm';
import Rating from '@material-ui/lab/Rating/Rating';
import { BestTimeDialog } from './RideDetailsComponent/BestTimeDialog';
import Alert from '@material-ui/lab/Alert/Alert';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { useEhrContext } from '../../contexts/ehr-context';
import { readIsMetric } from '../../storages/is-metric-storage';
import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

const BATCH_RIDES = loader('../../data/queries/BatchRides.graphql');

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  reviewContainer: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(10),
  },
  timeLine: {
    lineHeight: '1.25',
  },
  locations: {
    marginTop: theme.spacing(2),
  },
  cardIcon: {
    marginRight: theme.spacing(1),
  },
  rideNotes: {
    marginTop: theme.spacing(3),
  },
  mncs: {
    marginTop: theme.spacing(3),
  },
  actionBar: {
    marginTop: theme.spacing(3),
  },
  actionButton: {
    marginRight: theme.spacing(2),
  },
  fullHeight: {
    height: '100%',
  },
  coordinator: {
    marginRight: theme.spacing(0.5),
  },
  locationPhoto: {
    paddingLeft: 24,
  },
  feedbackSection: {
    marginTop: theme.spacing(2),
  },
  feedBackHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  copyButton: {
    marginLeft: '0.5rem',
  },
  reasonForTransport: {
    marginTop: theme.spacing(2),
  },
  bestTimeInfoLink: {
    fontSize: 16,
    fontWeight: 500,
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette.info.main,
    border: 'none',
    backgroundColor: 'transparent',
    padding: 0,
  },
}));

type RideDetailsViewProps = {
  ride: Ride;
  account: Account | null;
  admin: boolean;
  getRide?:
    | ((variables?: Partial<any>) => Promise<ApolloQueryResult<any>>)
    | undefined;
};

export const RideDetailsView: FC<RideDetailsViewProps> = ({
  ride,
  account,
  admin,
  getRide,
}) => {
  const isMetric = readIsMetric();
  const { generateVetrideParams, isVetride } = useVetridesContext();
  const { webAppHost } = ENV;

  const classes = useStyles();
  const theme = useTheme();
  const [showReview, setShowReview] = useState(false);
  const [openBestTimeModal, setOpenBestTimeModal] = useState(false);
  const auth = useAuth();
  const { isEhrPatientContext } = useEhrContext();
  const search = useLocation().search;

  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [isFeedbackSent, setFeedbackSent] = useState(false);

  const queryParams = new URLSearchParams(search);
  const shouldOpenReview = queryParams.get('openReview');
  const [getBatchRides, { loading, data: batchRides, error }] = useLazyQuery(
    BATCH_RIDES,
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    if (shouldOpenReview) {
      setShowReview(true);
    }
  }, [shouldOpenReview]);

  // we show bigger dates
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

  // less padding
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [moveToRebook, setMoveToRebook] = useState(false);
  const [showMncs, setShowMncs] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // draft, pending, confirmed
  const [cancelDisabled, setCancelDisabled] = useState(
    ride.status !== 'draft' &&
      ride.status !== 'pending' &&
      ride.status !== 'confirmed',
  );

  const rebookDisabled = isVetride && ride.status !== 'cancelled';

  const mncsAnswers = useMemo(() => {
    return ride?.medicalNecessityCertificationStatement?.mncsAnswers?.filter(
      answer => !!answer.value,
    );
  }, [ride]);

  const [declineBids, { data }] = useDeclineBestTimeBidsMutationMutation({
    onCompleted: () => {
      setOpenBestTimeModal(false);
      getRide?.();
    },
    onError: () => setErrorMessage('Something wet wrong'),
  });

  useEffect(() => {
    if (data?.declineBestTimeBids?.error) {
      setErrorMessage(data?.declineBestTimeBids?.error);
    }
  }, [data]);

  useEffect(() => {
    if (!!errorMessage) {
      setTimeout(() => {
        setErrorMessage('');
      }, 7000);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (ride.batchId) {
      getBatchRides({ variables: { rideId: parseInt(ride.id) } });
    }
  }, [ride.batchId]);

  const renderPaymentMethod = () => {
    if (!ride.paymentMethod) {
      if (account?.invoiced) {
        return <Typography variant="subtitle2">Invoiced</Typography>;
      } else {
        return null;
      }
    }

    return (
      <Box display="flex">
        <CreditCardIcon color="secondary" className={classes.cardIcon} />
        <Typography variant="subtitle2">{ride.paymentMethod.name}</Typography>
      </Box>
    );
  };

  const billingText = () => {
    return ride.rideType === 'delivery'
      ? 'Billing will begin when the driver begins shopping, and end when the driver drops off the delivery. 3% service fee will be applied only to delivery expenses.'
      : 'Billing will begin when the rider is picked up, and end when the rider is dropped off.';
  };

  const passengerAndDistanceText = () => {
    let text = '';
    if (ride.rideType !== 'delivery') {
      if (!ride.passengerCount || ride.passengerCount === 1) {
        text += '1 passenger';
      } else {
        text += `${ride.passengerCount} passengers`;
      }
      if (ride.estimatedDistanceMiles) {
        text += `, ${ride.estimatedDistanceMiles} `;
        ride.estimatedDistanceMiles > 1 ? (text += 'miles') : (text += 'mile');
      }
    }
    return text;
  };

  const RideNotesDetails = () => {
    if (ride.notes && ride.notes.length) {
      return <ParagraphText>{ride.notes}</ParagraphText>;
    }
    return <ZeroStateText>No details provided</ZeroStateText>;
  };

  const RideNotes = () => (
    <Box className={classes.rideNotes}>
      <Typography variant="button">
        {ride.rideType === 'delivery' ? 'Delivery Details' : 'Ride Notes'}
      </Typography>
      <RideNotesDetails />
    </Box>
  );

  const getWeight = (weight: number | null | undefined) => {
    if (!weight) {
      return null;
    }

    let text = '';
    if (weight) {
      if (isMetric) {
        text = `Weight: ${getKgFromLBS(weight)} kg`;
      } else {
        text = `Weight: ${ride.weightLbs} lbs`;
      }
    }

    return <ParagraphText>{text}</ParagraphText>;
  };

  const getHeight = (heightInches: number | null | undefined) => {
    if (!heightInches) {
      return null;
    }
    if (isMetric) {
      return (
        <ParagraphText>{`Height: ${getCmFromInches(
          heightInches,
        )} cm`}</ParagraphText>
      );
    }

    const heightInch = (heightInches || 0) % 12;
    const heightFeet = Math.trunc((heightInches || 0) / 12);

    if (heightInch && heightFeet) {
      return (
        <ParagraphText>{`Height: ${heightFeet} ft ${heightInch} in`}</ParagraphText>
      );
    }

    if (heightInch) {
      return <ParagraphText>{`Height: ${heightInch} in`}</ParagraphText>;
    }

    if (heightFeet) {
      return <ParagraphText>{`Height: ${heightFeet} ft`}</ParagraphText>;
    }

    return null;
  };

  const TransportTypeDetails = () => {
    return ride.bariatric ||
      ride.stairsCount ||
      ride.heightInches ||
      //@ts-ignore
      ride.wheelchairOwner ||
      ride.oxygenRequired ||
      ride.contactPrecautionsRequired ||
      ride.bedsideNurse ? (
      <Box className={classes.rideNotes}>
        <Typography variant="button">Transport Type Details</Typography>
        {ride.bariatric && <ParagraphText>Bariatric</ParagraphText>}
        {ride.stairsLabel && <ParagraphText>{ride.stairsLabel}</ParagraphText>}
        {getWeight(ride.weightLbs)}
        {getHeight(ride.heightInches)}
        {/*@ts-ignore*/}
        {ride.wheelchairOwner && (
          // @ts-ignore
          <ParagraphText>{`Wheelchair Owner: ${ride.wheelchairOwner}`}</ParagraphText>
        )}
        {ride.oxygenRequired && (
          <ParagraphText>Transport to Provide Oxygen</ParagraphText>
        )}
        {ride.contactPrecautionsRequired && (
          <ParagraphText>Droplet Precautions Required</ParagraphText>
        )}
        {ride.bedsideNurse?.fullName && (
          <ParagraphText>
            {`Nurse Name: ${ride.bedsideNurse.fullName}`}
          </ParagraphText>
        )}
        {ride.bedsideNurse?.phone && (
          <ParagraphText>{`Nurse Phone: ${ride.bedsideNurse.phone}`}</ParagraphText>
        )}
      </Box>
    ) : null;
  };

  const MncsAnswers = () => {
    if (!mncsAnswers) {
      return null;
    }
    return (
      <Box className={classes.mncs}>
        <Typography variant="button">
          Medical Necessity Certification Statement
        </Typography>
        <div>
          <Link
            to=""
            onClick={e => {
              e.preventDefault();
              setShowMncs(true);
            }}
          >
            Show MNCS
          </Link>
        </div>
      </Box>
    );
  };

  const DeliverySubstitutions = () => {
    if (ride.rideType !== 'delivery') return null;

    const text =
      ride.deliverySubstitution === 'yes'
        ? 'Try to contact, and if not reachable make substitutions.'
        : 'Try to contact, and if not reachable do NOT make substitutions.';

    return (
      <Box className={classes.rideNotes}>
        <Box alignSelf="center">
          <Typography variant="button">Delivery Substitutions</Typography>
        </Box>

        <Typography variant="body2">{text}</Typography>
      </Box>
    );
  };

  const isFromRN = auth?.getFromRN(); // user login from react-native app using webview

  const RideActionButtons = () => (
    <Box
      data-testid="rideActions"
      display="flex"
      flexWrap="wrap"
      justifyContent="space-between"
      className={classes.actionBar}
    >
      <Box flexGrow={1}>
        {!cancelDisabled && (admin || !ride.driver) && (
          <Button
            data-testid="edit-btn"
            className={classes.actionButton}
            color="primary"
            variant="outlined"
            startIcon={<EditIcon />}
            component={Link}
            to={`/rides/${ride.id}/edit`}
          >
            Edit
          </Button>
        )}
        {!cancelDisabled && !admin && ride.driver && (
          <Button color="primary" startIcon={<PhoneIcon />}>
            1 (800) 700-4797 to make changes
          </Button>
        )}
        {!cancelDisabled && (
          <Button
            className={classes.actionButton}
            color="primary"
            variant="outlined"
            startIcon={<CancelIcon />}
            onClick={() => {
              setMoveToRebook(false);
              setShowCancelConfirmation(true);
            }}
          >
            Cancel
          </Button>
        )}
        {rebookDisabled ? null : (
          <Button
            startIcon={<RedoIcon />}
            className={classes.actionButton}
            color="primary"
            variant="outlined"
            component={Link}
            to={`/rides/${ride.id}/copy`}
          >
            Rebook
          </Button>
        )}

        {isFromRN &&
          ride.status === 'completed' &&
          !ride.custodianReviewedAt && (
            <Button
              startIcon={<RateReviewIcon />}
              className={classes.actionButton}
              color="primary"
              variant="outlined"
              onClick={() => {
                setShowReview(true);
                window.scrollTo({ top: 0 });
              }}
            >
              Rate Ride
            </Button>
          )}
        {!cancelDisabled && (
          <Button
            color="primary"
            variant="outlined"
            startIcon={<RedoIcon />}
            className={classes.actionButton}
            onClick={() => {
              setMoveToRebook(true);
              setShowCancelConfirmation(true);
            }}
          >
            Cancel and Rebook
          </Button>
        )}

        {!rebookDisabled && (
          <Button
            startIcon={<RedoIcon />}
            color="primary"
            variant="outlined"
            component={Link}
            to={`/rides/${ride.id}/copy?makeReturnTrip=true`}
          >
            Return Trip
          </Button>
        )}
      </Box>
    </Box>
  );

  const FlexRideLabel = () => (
    <Typography
      className={classes.timeLine}
      variant="caption"
      style={{ fontStyle: 'italic' }}
    >
      Flexible Ride - Time TBD by Rider
    </Typography>
  );

  const renderRideInfo = () => {
    const {
      startLocation,
      startLocationPhoto,
      endLocation,
      endLocationPhoto,
      custodian,
      pickupRoomNumber,
      dropoffRoomNumber,
      bidRequestedStartTime,
    } = ride;
    const transportType = ride.custodian?.availableTransportTypes?.find(
      at => at.name === ride.transportType,
    );
    const { status, flexibleRide, driver, biddingWindowEndsAt } = ride;
    const rideConfirmed = status === 'confirmed';
    const rideDeclined = ['declined', 'cancelled', 'unfulfilled'].includes(
      status,
    );

    let shouldShowBestTime =
      (!!biddingWindowEndsAt || !!bidRequestedStartTime) && !flexibleRide;
    const hideStartTime =
      flexibleRide &&
      ((rideConfirmed && driver?.isPlaceholder) || rideDeclined);

    return (
      <>
        <Grid container spacing={3}>
          {status === 'inprogress' && (
            <Grid item xs={12}>
              <InProgressIndicator />
            </Grid>
          )}

          <Grid item xs={12}>
            {!!errorMessage ? (
              <Alert severity="error">{errorMessage}</Alert>
            ) : null}
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box
              className={classes.fullHeight}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="h6">{personName(ride.rider)}</Typography>
                <Box display="flex" alignItems="center">
                  <Box>
                    <Typography
                      className={classes.timeLine}
                      variant="subtitle1"
                    >
                      {largeScreen
                        ? longDateOnly(ride.requestedStartTime, {
                            timeZone: account?.tzName,
                          })
                        : mediumDateOnly(ride.requestedStartTime, {
                            timeZone: account?.tzName,
                          })}
                    </Typography>
                    {hideStartTime ? (
                      <FlexRideLabel />
                    ) : (
                      <Typography
                        className={classes.timeLine}
                        variant="subtitle1"
                      >
                        {timeRange(
                          ride.requestedStartTime,
                          ride.requestedEndTime,
                          {
                            timeZone: account?.tzName,
                          },
                        )}
                      </Typography>
                    )}
                    {shouldShowBestTime ? (
                      <button
                        className={classes.bestTimeInfoLink}
                        onClick={() => setOpenBestTimeModal(true)}
                      >
                        Best Time Info
                      </button>
                    ) : null}
                  </Box>
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography variant="button" className={classes.coordinator}>
                  Coordinator:
                </Typography>
                <Typography variant="body2">{personName(custodian)}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="end">
              <RideTypeBadge rideType={ride.rideType} />
              <Box ml={1}>
                <TransportTypeBadge transportType={transportType} />
              </Box>
              {ride.batchId && (
                <Box ml={1}>
                  <RideTypeBadge rideType={'recurring'} />
                </Box>
              )}
            </Box>
            <Box display="flex" className={classes.locations}>
              <Box flexGrow={1}>
                <LocationRow
                  location={startLocation}
                  roomNumber={pickupRoomNumber}
                  roomNumberTitle="Pickup Room Number"
                  locationPhoto={startLocationPhoto}
                  showAddress
                  showInstructions
                />
                <LocationRow
                  location={endLocation}
                  roomNumber={dropoffRoomNumber}
                  roomNumberTitle="Dropoff Room Number"
                  locationPhoto={endLocationPhoto}
                  showAddress
                  showInstructions
                />
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Map
          // @ts-ignore
          startLocation={startLocation}
          endLocation={endLocation}
          startTime={!hideStartTime && ride.requestedStartTime}
          endTime={ride.requestedEndTime}
          tzName={account?.tzName}
          height="250px"
        />
        <Box display="flex" className={classes.locations}>
          <Box flexGrow={1}>
            {ride.estimatedCostCents !== undefined && (
              <Typography variant="subtitle2">{`Estimate: ${centsToFormattedDollars(
                ride.estimatedCostCents,
              )}`}</Typography>
            )}
            {renderPaymentMethod()}
          </Box>
          <Box>
            <Typography variant="subtitle2">
              {passengerAndDistanceText()}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography color="textSecondary" variant="caption">
            {billingText()}
          </Typography>
        </Box>
        <TransportTypeDetails />
        <MncsAnswers />
        <RideNotes />

        <AddRidePaymentMethodLink />
        <FeedbackView />
        <DeliverySubstitutions />

        <ReasonForTransport />
        <RideActionButtons />
      </>
    );
  };

  const AddRidePaymentMethodLink = () => {
    const { rideNewPaymentMethodPath } = ride;

    let content = null;

    if (rideNewPaymentMethodPath) {
      const link = `${webAppHost}${rideNewPaymentMethodPath}`;
      const payerCardName = ride.payer?.currentPaymentMethod?.name;
      const linkText = payerCardName
        ? `${payerCardName} has been added. Click to change Payment Method`
        : 'Add a Payment Method to Ride';

      content = (
        <>
          <Grid item>
            <Link
              to={rideNewPaymentMethodPath}
              target="_blank"
              rel="noopener noreferrer"
            >
              {linkText}
            </Link>
            <Button
              startIcon={<FileCopyIcon />}
              variant="outlined"
              className={classes.copyButton}
              onClick={() => navigator.clipboard.writeText(link)}
            >
              Copy URL
            </Button>
          </Grid>
        </>
      );
    }

    if (!content) {
      return null;
    }

    return (
      <Grid container className={classes.feedbackSection}>
        <Grid className={classes.feedBackHeader} item xs={12}>
          <Typography variant="button">Private Pay Ride</Typography>
        </Grid>
        {content}
      </Grid>
    );
  };

  const FeedbackView = () => {
    const { rideFeedback, rideFeedbackRequestPath } = ride;

    let content = null;

    let feedbackContent = null;

    if (!rideFeedback && !rideFeedbackRequestPath) {
      content = <Typography variant="body2">No feedback available</Typography>;
    }

    if (!rideFeedback && rideFeedbackRequestPath) {
      const link = `${webAppHost}${rideFeedbackRequestPath}`;
      content = (
        <>
          <Grid item>
            <Link
              to={rideFeedbackRequestPath}
              target="_blank"
              rel="noopener noreferrer"
            >
              Feedback Page
            </Link>
            <Button
              startIcon={<FileCopyIcon />}
              variant="outlined"
              className={classes.copyButton}
              onClick={() => navigator.clipboard.writeText(link)}
            >
              Copy URL
            </Button>
          </Grid>
          {!admin ? null : (
            <Grid item>
              <Button
                startIcon={<FlashOnOutlinedIcon />}
                className={classes.copyButton}
                disabled={isFeedbackSent}
                onClick={() => setShowFeedbackForm(true)}
                variant="outlined"
              >
                Send Feedback Request
              </Button>
            </Grid>
          )}
        </>
      );
    }

    if (rideFeedback) {
      const { driverFeedback, createdAt, stars } = rideFeedback;

      feedbackContent = (
        <Grid container>
          <Grid xs={12} item>
            <Typography>
              {mediumDateOnly(createdAt, { timeZone: account?.tzName })}
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Rating
              disabled
              name="hover-feedback"
              value={stars}
              size="large"
              precision={1}
            />
          </Grid>
          <Grid xs={12} item>
            <Typography>{driverFeedback}</Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container className={classes.feedbackSection}>
        <Grid className={classes.feedBackHeader} item xs={12}>
          <Typography variant="button">Rider Feedback</Typography>
        </Grid>
        {content}
        {feedbackContent}
      </Grid>
    );
  };

  if (showMncs && mncsAnswers) {
    return (
      <AddMedicalNecessityStatementView
        onGoBack={() => {
          setShowMncs(false);
        }}
        mncsAnswers={mncsAnswers}
      />
    );
  }

  const handleCancelRide = () => {
    if (isVetride) {
      const vetrideParams = generateVetrideParams();
      moveToRebook
        ? history.push(`/rides/${ride.id}/copy?${vetrideParams}`)
        : history.push(`/rides/new?${vetrideParams}`);
    } else {
      if (moveToRebook) {
        history.push(`/rides/${ride.id}/copy`);
      } else if (isEhrPatientContext) {
        // There should always be a rider in this context.
        history.push(`/rides/new?riderID=${ride.rider?.id}`);
      } else {
        history.replace({ pathname: '/' });
      }
    }
  };

  const ReasonForTransport = () => {
    const { transportReason, transportReasonComment } = ride;

    if (!transportReason) {
      return null;
    }

    return (
      <Grid container className={classes.reasonForTransport}>
        <Grid className={classes.feedBackHeader} item xs={12}>
          <Typography variant="button">Reason For Transport</Typography>
        </Grid>
        <Typography variant="body2">
          <p>
            Category: <b>{transportReason.categoryName}</b>
          </p>
          <p>
            Reason: <b>{transportReason.name}</b>
          </p>
          {transportReasonComment ? (
            <p>
              Comment: <b>{transportReasonComment}</b>
            </p>
          ) : null}
        </Typography>
      </Grid>
    );
  };

  return (
    <Container maxWidth="md" disableGutters={smallScreen}>
      {isVetride === false && (
        <Button
          color="primary"
          startIcon={<ArrowBackIcon />}
          onClick={showReview ? () => setShowReview(false) : history.goBack}
        >
          Back
        </Button>
      )}

      {isFromRN && ride && showReview ? (
        <Paper className={classes.reviewContainer}>
          <Review
            allowTipping
            closeReview={() => setShowReview(false)}
            // @ts-ignore
            ride={ride}
          />
        </Paper>
      ) : (
        <>
          <Paper className={classes.root}>{renderRideInfo()}</Paper>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <DriverCard ride={ride} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RiderCard ride={ride} />
            </Grid>
          </Grid>
        </>
      )}
      <CancelConfirmation
        ride={ride}
        handleClose={() => setShowCancelConfirmation(false)}
        handleCancelRide={handleCancelRide}
        showDialog={showCancelConfirmation}
        cancelDisabled={cancelDisabled}
        setCancelDisabled={setCancelDisabled}
        isBatch={!!ride.batchId}
        batchRemainingRides={batchRides?.batchRides}
        isRebook={moveToRebook}
      />

      <FeedbackForm
        email={ride.rider?.email}
        phone={ride.rider?.phone}
        showDialog={showFeedbackForm}
        handleClose={() => setShowFeedbackForm(false)}
        setFeedbackSent={sent => {
          setFeedbackSent(sent);
        }}
        rideId={ride.id}
      />

      <BestTimeDialog
        setOpenBestTimeModal={setOpenBestTimeModal}
        openBestTimeModal={openBestTimeModal}
        handleDeclineBestTime={() => {
          declineBids({ variables: { rideId: ride.id } });
        }}
        ride={ride}
      />
    </Container>
  );
};
