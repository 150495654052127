import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ZeroStateText from 'components/ZeroStateText';
import React, { useState } from 'react';
import PaymentMethodNameDialog from './PaymentMethodNameDialog';

const useStyles = makeStyles(theme => ({
  actionButton: {
    marginRight: theme.spacing(2),
  },
  cardIcon: {
    marginRight: theme.spacing(1),
  },
  rowSpacing: {
    marginBottom: theme.spacing(1),
  },
}));

const CardRow = ({ paymentMethod, onRemoveCard, onUpdatePaymentMethod }) => {
  const classes = useStyles();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const renderCardDetails = () => {
    const name = paymentMethod.name;

    if (
      (name !== null && name !== undefined) &&
      name.includes(paymentMethod.cardBrand) &&
      name.includes(paymentMethod.cardLast4)
    )
      return null;

    return (
      <ZeroStateText>{`${paymentMethod.cardBrand.toUpperCase()} **** ${paymentMethod.cardLast4}`}</ZeroStateText>
    );
  };

  return (
    <>
      <Grid container alignItems="center" className={classes.rowSpacing}>
        <Grid item sm={7}>
          <Box display="flex">
            <CreditCardIcon color="secondary" className={classes.cardIcon} />

              <div style={{
                whiteSpace: 'pre',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
                {paymentMethod.name}
              </div>
              {renderCardDetails()}
          </Box>
        </Grid>
        <Grid item xs={5} style={{ textAlign: 'center' }}>
          {smallScreen ? (
            <IconButton
              aria-controls="edit payment method"
              onClick={() => setShowEditDialog(true)}
            >
              <EditIcon />
            </IconButton>
          ) : (
            <Button
              className={classes.actionButton}
              color="primary"
              variant="outlined"
              startIcon={<EditIcon />}
              onClick={() => setShowEditDialog(true)}
            >
              Edit
            </Button>
          )}
          {smallScreen ? (
            <IconButton
              aria-controls="delete card"
              onClick={() => onRemoveCard(paymentMethod.id)}
            >
              <DeleteIcon />
            </IconButton>
          ) : (
            <Button
              className={classes.actionButton}
              color="primary"
              variant="outlined"
              startIcon={<DeleteIcon />}
              onClick={() => onRemoveCard(paymentMethod.id)}
            >
              Delete
            </Button>
          )}
        </Grid>
      </Grid>
      <PaymentMethodNameDialog
        paymentMethod={paymentMethod}
        open={showEditDialog}
        handleClose={() => setShowEditDialog(false)}
        handleUpdate={onUpdatePaymentMethod}
      />
    </>
  );
};

export default CardRow;
