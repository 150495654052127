import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { DatePicker } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { Controller, FieldErrors } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';
import { useSessionContext } from '../../../../contexts/session-context';
import { Maybe, TransportReason } from '../../../../generated/graphql';
import onwardColors from '../../../../lib/onwardColors';

export const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  fareSection: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    width: '95%',
    borderLeftColor: onwardColors.onwardBlue,
    borderLeftWidth: 3,
    borderLeftStyle: 'solid',
    paddingLeft: '15px',
  },
}));

export type Props<T> = {
  control: Control;
  transportReasonErrors: FieldErrors<T> | undefined;
  transportReasonDayErrors: FieldErrors<T> | undefined;
  transportReasonTimeErrors: FieldErrors<T> | undefined;
  transportReasonCommentErrors: FieldErrors<T> | undefined;
  transportReason: Maybe<TransportReason> | undefined;
};

export const _TransportReasonCategories = <
  T extends { transportReason: string; transportReasonComment: string }
>({
  control,
  transportReasonErrors,
  transportReason,
  transportReasonCommentErrors,
  transportReasonTimeErrors,
  transportReasonDayErrors,
}: Props<T>) => {
  const classes = useStyles();
  const { session } = useSessionContext();
  const { profile } = session || {};

  const [categoriesList, setCategories] = useState<string[] | null>(null);
  const [
    transportReasons,
    setTransportReasons,
  ] = useState<Array<TransportReason> | null>(null);

  const [
    transportReasonsFilteredByCategory,
    setTransportReasonsFilteredByCategory,
  ] = useState<Array<TransportReason> | null>(null);

  const [category, setCategory] = useState<string | null>(
    transportReason?.categoryName || null,
  );
  const [reason, setReason] = useState<string | null>(
    transportReason?.id || null,
  );

  const [commentPrompt, setCommentPrompt] = useState(
    !!transportReason?.requireComment,
  );

  const [appointmentTime, setAppointmentTime] = useState(
    !!transportReason?.requireAppointmentTime,
  );

  useEffect(() => {
    if (profile) {
      const { availableTransportReasons } = profile;
      if (availableTransportReasons && availableTransportReasons.length !== 0) {
        const categories = availableTransportReasons.map(
          atr => atr.categoryName,
        );

        setTransportReasons(availableTransportReasons);
        if (!!categories?.length) {
          control.register('ride.transportReason', { required: true });
          setCategories(Array.from(new Set(categories)));
        } else {
          control.unregister('ride.transportReason');
        }
      } else {
        control.unregister('ride.transportReason');
      }
    } else {
      control.unregister('ride.transportReason');
    }
  }, [profile]);

  useEffect(() => {
    if (
      reason &&
      transportReasonsFilteredByCategory &&
      transportReasonsFilteredByCategory.length
    ) {
      const finalReason = transportReasonsFilteredByCategory.find(
        t => t.id === reason,
      );

      if (finalReason) {
        setCommentPrompt(finalReason.requireComment);
        setAppointmentTime(finalReason.requireAppointmentTime);
        control.setValue('ride.transportReason', finalReason, {
          shouldValidate: true,
        });
      } else {
        setCommentPrompt(false);
        setAppointmentTime(false);
      }
    }
  }, [reason, transportReasonsFilteredByCategory]);

  useEffect(() => {
    if (
      transportReasonsFilteredByCategory &&
      transportReasonsFilteredByCategory.length === 1
    ) {
      setReason(transportReasonsFilteredByCategory[0].id);
    }
  }, [transportReasonsFilteredByCategory]);

  useEffect(() => {
    if (categoriesList && categoriesList.length === 1) {
      setCategory(categoriesList[0]);
    }
  }, [categoriesList]);

  useEffect(() => {
    if (transportReasons) {
      const reasonsForCategory = transportReasons?.filter(
        tr => tr.categoryName === category,
      );
      if (reasonsForCategory && reasonsForCategory.length) {
        setTransportReasonsFilteredByCategory(reasonsForCategory);
      }
    }
  }, [transportReasons, category]);

  if (!categoriesList?.length) {
    return null;
  }

  const categoryError = !!transportReasonErrors && !category;
  const reasonError = !!transportReasonErrors && !reason;

  return (
    <Box className={classes.fareSection}>
      <InputLabel className={classes.formControl}>
        Reason for Transport
      </InputLabel>
      <div>
        <FormControl error={categoryError} className={classes.formControl}>
          <InputLabel
            shrink={!!category}
            id="select-transportation-reason-category-label"
          >
            Category
          </InputLabel>
          <Select
            labelId="select-transportReasonCategories-label"
            id="rideTransportReasonCategories"
            onChange={e => {
              setCategory(e.target.value as string);
            }}
            value={category}
          >
            {categoriesList?.map(category => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
          {categoryError ? (
            <FormHelperText>Select a Category</FormHelperText>
          ) : null}
        </FormControl>
      </div>
      <div>
        <FormControl error={reasonError} className={classes.formControl}>
          <InputLabel shrink={!!reason} id="select-transportation-reason-label">
            Reason
          </InputLabel>

          <Select
            disabled={
              !transportReasonsFilteredByCategory ||
              transportReasonsFilteredByCategory?.length === 0
            }
            labelId="select-transportReasons-label"
            id="rideTransportReasons"
            value={reason}
            onChange={e => {
              setReason(e.target.value as string);
            }}
          >
            {transportReasonsFilteredByCategory?.map(reason => {
              return (
                <MenuItem key={reason.id} value={reason.id}>
                  {reason.name}
                </MenuItem>
              );
            })}
          </Select>

          {reasonError ? (
            <FormHelperText>Select a Reason</FormHelperText>
          ) : null}
        </FormControl>
      </div>

      {commentPrompt ? (
        <div>
          <FormControl
            error={!!transportReasonCommentErrors}
            className={classes.formControl}
          >
            <Controller
              control={control}
              name="ride.transportReasonComment"
              rules={{ required: true }}
              render={({ ref, onBlur, value, ...rest }) => {
                return (
                  <TextField
                    id="transportReasonComment"
                    label="Comment"
                    error={!!transportReasonCommentErrors}
                    helperText={
                      transportReasonCommentErrors && 'Comment is required'
                    }
                    InputLabelProps={{
                      shrink: !!value,
                    }}
                    onBlur={() => {
                      control.setValue(
                        'ride.transportReasonComment',
                        value?.trim(),
                      );
                    }}
                    value={value}
                    {...rest}
                  />
                );
              }}
            />
          </FormControl>
        </div>
      ) : null}

      {appointmentTime ? (
        <Grid container className={classes.formControl}>
          <Grid item xs={7}>
            <Controller
              control={control}
              name="appointmentTimeTransportReason.startAppointmentDay"
              rules={{ required: true }}
              render={({ ref, ...rest }) => (
                <DatePicker
                  id="startAppointmentDay"
                  autoOk
                  label="Select Day"
                  disablePast
                  error={!!transportReasonTimeErrors}
                  helperText={!!transportReasonDayErrors && 'Day is required'}
                  {...rest}
                />
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <Controller
              control={control}
              name="appointmentTimeTransportReason.startAppointmentTime"
              rules={{ required: true }}
              render={({ ref, ...rest }) => (
                <TextField
                  id="startAppointmentTimeTime"
                  label="Select Time"
                  type="time"
                  style={{ width: '100%' }}
                  error={!!transportReasonTimeErrors}
                  helperText={!!transportReasonTimeErrors && 'Time is required'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  {...rest}
                />
              )}
            />
          </Grid>
        </Grid>
      ) : null}
    </Box>
  );
};

export const TransportReasonCategories = React.memo(
  _TransportReasonCategories as React.FC<Props<any>>,
  (a, b) => {
    return (
      a.transportReason === b.transportReason &&
      a.transportReasonCommentErrors === b.transportReasonCommentErrors &&
      a.transportReasonErrors === b.transportReasonErrors &&
      a.transportReasonTimeErrors === b.transportReasonTimeErrors &&
      a.transportReasonDayErrors === b.transportReasonDayErrors
    );
  },
);
