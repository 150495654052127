import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { oauth2 as SMART } from 'fhirclient';
import Client from 'fhirclient/lib/Client';
import {
  deleteSession,
  readSessionObject,
  writeSessionObject,
} from '../storages/session-storage';

const sessionKey = 'ehr-session';

/**
 * All the fields are optional because we may not get all the fields from the
 * EHR. We have validation in place to make sure we don't continue without the
 * required fields.
 *
 * There are also other fields we get back from the EHR, we just don't need them
 * yet, so not adding them here.
 */
export interface TokenResponse {
  encounter?: string;
  patient?: string;
  patientDOB?: string;
  patientFirstName?: string;
  patientLastName?: string;
  userEmail?: string;
  userFirstName?: string;
  userLastName?: string;
}

export interface EhrSession {
  fhirAppId: number | null;
  fhirAppClientId: string;
  testSession: boolean;
  ehrAccessToken: TokenResponse | null;
  ehrSessionId: number | null;
}

export function saveEhrSession(session: EhrSession) {
  writeSessionObject(sessionKey, session);
}

export function readEhrSession() {
  return readSessionObject(sessionKey) as EhrSession | null;
}

export function deleteEhrSession() {
  return deleteSession(sessionKey);
}

export const FhirClientContext = createContext<
  | {
      client: Client | null;
      error: any | null;
      ehrSession: EhrSession | null;
      setEhrSession: React.Dispatch<React.SetStateAction<EhrSession | null>>;
    }
  | undefined
>(undefined);

export default function FhirClientProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [client, setClient] = useState<Client | null>(null);
  const [error, setError] = useState<any | null>(null);
  const [ehrSession, setEhrSession] = useState<EhrSession | null>(null);

  useEffect(() => {
    if (SMART === undefined) {
      return;
    }

    SMART.ready().then(
      client => {
        setClient(client);
      },
      error => {
        setError(error);
      },
    );
  }, []);

  return (
    <FhirClientContext.Provider
      value={{ client, error, ehrSession, setEhrSession }}
    >
      {children}
    </FhirClientContext.Provider>
  );
}

export function useEhrContext() {
  const context = useContext(FhirClientContext);

  if (context === undefined) {
    throw new Error('useEhrContext must be used within a FhirClientContext');
  }

  const { client, ehrSession, setEhrSession } = context;
  const [isEhr, setIsEhr] = useState<boolean>(false);
  const [isEhrPatientContext, setIsEhrPatientContext] = useState<boolean>(
    false,
  );

  useEffect(() => {
    if (!client) {
      return;
    }

    setIsEhr(true);

    const patientId = client.getState('tokenResponse.patient');

    if (patientId) {
      setIsEhrPatientContext(true);
    }
  }, [client]);

  return {
    ehrSession: ehrSession || null,
    setEhrSession,
    ehrToken: (client?.getState('tokenResponse') as TokenResponse) ?? null,
    fhirClient: client,
    isEhr,
    isEhrPatientContext,
  };
}
