import { useEffect, useState } from 'react';
import ENV from '../constants/Env';
import GraphiQL from 'graphiql';
import 'graphiql/graphiql.css';
import { OnwardToken, getToken } from '../auth/onward-token';
import { useSessionContext } from '../contexts/session-context';

type UserType = 'custodian' | 'driver';

interface GraphiQLPageProps {
  userType: UserType;
}

const GraphiQLPage: React.FC<GraphiQLPageProps> = ({ userType }) => {
  const { apiUrl } = ENV;
  const [authToken, setAuthToken] = useState<OnwardToken | null>(null);
  const { isAdmin } = useSessionContext();

  useEffect(() => {
    const token = getToken();
    setAuthToken(token);
  }, []);

  if (!authToken || !isAdmin) {
    return null;
  }

  return (
    <div style={{ height: '100vh' }}>
      <GraphiQL
        fetcher={async graphQLParams => {
          const response = await fetch(`${apiUrl}/${userType}_graphql`, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Access-Token': authToken.accessToken,
              Client: authToken.client,
              Uid: authToken.uid,
            },
            body: JSON.stringify(graphQLParams),
            credentials: 'same-origin',
          });
          return response.json();
        }}
      />
    </div>
  );
};

export default GraphiQLPage;
