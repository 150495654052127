import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  formatPhoneNumber,
  personInitials,
  personName,
} from '@onwardcare/core';
import ENV from '../../constants/Env';
import { Link as RouterLink } from 'react-router-dom';
import { RiderImpairments } from './RiderImpairments';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  media: {
    height: 140,
    backgroundColor: theme.palette.action.disabledBackground,
  },
  bio: {
    maxHeight: 40,
    overflow: 'hidden',
  },
  avatar: {
    height: 120,
    width: 120,
    backgroundColor: '#fff',
    color: theme.palette.text.primary,
  },
}));

const RiderCard = ({ rider }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea component={RouterLink} to={`/riders/${rider.id}`}>
        {rider.profilePhoto ? (
          <CardMedia
            className={classes.media}
            image={ENV.apiUrl + rider.profilePhoto}
            title={personName(rider)}
          />
        ) : (
          <CardMedia className={classes.media}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Avatar className={classes.avatar}>
                {personInitials(rider)}
              </Avatar>
            </Box>
          </CardMedia>
        )}

        <CardContent>
          <Typography variant="h5" component="h2">
            {personName(rider)}
          </Typography>
          {rider.phone && (
            <Typography variant="subtitle2">
              {formatPhoneNumber(rider.phone)}
            </Typography>
          )}
          <RiderImpairments rider={rider} />
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.bio}
          >
            {rider.notes}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default RiderCard;
