import {
  Box,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  Input,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';

const useStyles = makeStyles(theme => ({
  assistanceControl: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  assistanceIcon: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  assistanceHeader: {
    marginBottom: theme.spacing(-0.5),
  },
  accessibleBox: {
    width: '95%',
    height: '100%',
  },
}));

export function ImpairmentInput({
  Icon,
  label,
  name,
  question,
  options,
  control,
  defaultValue,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const [inputWidth, setInputWidth] = React.useState(0);
  const iconRef = React.useRef('auto');

  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  React.useLayoutEffect(() => {
    if (iconRef.current && smallScreen) {
      setInputWidth((window.innerWidth - iconRef.current.clientWidth) * 0.8);
    } else {
      setInputWidth('auto');
    }
  }, [smallScreen]);

  return (
    <Box display="flex" alignItems="stretch" className={classes.accessibleBox}>
      <Icon
        ref={iconRef}
        color="secondary"
        fontSize="large"
        className={classes.assistanceIcon}
      />
      <Box flexGrow={1} display="flex" flexDirection="column">
        <Typography
          variant="h6"
          component="h3"
          className={classes.assistanceHeader}
        >
          {label}
        </Typography>
        <Box flexGrow={1}>
          <Typography variant="body2">{question}</Typography>
        </Box>
        <Box>
          <FormControl className={classes.assistanceControl}>
            <Controller
              control={control}
              name={name}
              defaultValue={defaultValue}
              input={
                <Input
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: inputWidth,
                  }}
                />
              }
              as={
                <Select id={label}>
                  {options.map(o => (
                    <MenuItem key={o[0]} value={o[0]}>
                      <Typography variant="inherit" noWrap>
                        {o[1]}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
}
