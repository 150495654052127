export interface JsonError {
  message: string;
}

/**
 * Ensures that the error is a JSON object with a `message` key that contains
 * the error message.
 *
 * @param error The error that was thrown.
 * @returns Returns a JSON object with a `message` key that contains the error message.
 */
export function ensureErrorIsJson(error: any | null): JsonError {
  if (typeof error === 'string') {
    return { message: error };
  } else if (error instanceof Error) {
    // Although `Error` is an object, it doesn't have any keys to be valid JSON.
    return { message: error.message };
  } else if (JSON.stringify(error) === '{}') {
    // If it's an object with no properties, then just do a `toString()` on it.
    return { message: error.toString() };
  }

  return error;
}
