import { INCHES_IN_CM, LBS_IN_KG } from './constants';

export const getKgFromLBS = (weightLbs: number) => {
  return Math.floor(weightLbs * LBS_IN_KG);
};

export const getLBSFromKg = (kg: number) => {
  return Math.ceil(kg / LBS_IN_KG);
};

export const getCmFromInches = (inches: number) => {
  return Math.ceil(inches / INCHES_IN_CM);
};

export const getInchesFromCM = (Cm: number) => {
  return Math.floor(Cm * INCHES_IN_CM);
};
