import React from 'react';
import { Typography } from '@material-ui/core';

const ParagraphText = ({ children }) => {
  if (!children) return null;

  // const notes = children.split('\n').map(l => <p>{l}</p>);
  // return <Typography variant="body2">{notes}</Typography>;

  return children.split('\n').map((l, idx) => (
    <Typography variant="body2" key={`p_${idx}`}>
      {l}
    </Typography>
  ));
};

export default ParagraphText;
