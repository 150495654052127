import { memo } from 'react';
import { areEqual } from 'react-window';
import { Box, Grid, Typography } from '@material-ui/core';
import RideCard from '../RideCard';
import { styles } from './styles';
import { DashboardQueryQuery } from '../../../generated/graphql';

type RidesType = NonNullable<DashboardQueryQuery['upcomingRides']>;

const Row = memo(
  ({
    index,
    style,
    data,
  }: {
    index: number;
    style: any;
    data: Array<{ title: string; rides: RidesType }>;
  }) => {
    const { title, rides } = data[index];
    const { date } = styles();

    return (
      <Box style={style}>
        {title ? (
          <Typography className={date} variant="h6">
            {title}
          </Typography>
        ) : (
          <Grid container spacing={3}>
            {rides.map(ride => (
              <Grid item xs={12} lg={6} key={ride.id}>
                <RideCard key={ride.id} ride={ride} />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    );
  },
  areEqual,
);

export default Row;
