import { Box, useMediaQuery } from '@material-ui/core';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import Row from './Row';
import { useTheme } from '@material-ui/core/styles';

const RideList = ({ data }: { data: any[] }) => {
  const theme = useTheme();
  // on small screen we don't show the container padding
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box flex={1} mt={2}>
      <AutoSizer>
        {({ height, width }) => (
          <VariableSizeList
            height={height || 200}
            width={width || 550}
            itemCount={data.length}
            itemData={data}
            itemSize={index =>
              data[index].title ? 80 : smallScreen ? 234 : 214
            }
          >
            {Row}
          </VariableSizeList>
        )}
      </AutoSizer>
    </Box>
  );
};

export default RideList;
