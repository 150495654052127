import React from 'react';
import { Box } from '@material-ui/core';
import { RideshareComponentProps } from './types';
import { RideshareCheckboxComponent } from './RideshareCheckboxComponent';
import useStyles from './styles';
import { useSessionContext } from '../../../../contexts/session-context';

const FIRST_AVAILABLE_LABEL_TEXT = 'Book as First Available';
const FIRST_AVAILABLE_TOOLTIP_TEXT =
  'Once confirmed, the driver will have an additional 30 minutes to begin the trip';
const FLEXIBLE_RIDE_LABEL_TEXT = 'Book as Flexible Ride';
const FLEXIBLE_RIDE_TOOLTIP_TEXT =
  'This will schedule a ride for the pickup day selected, and an SMS message will be sent to the rider on the day of pickup so they may request the ride when they are ready to be picked up.';

const _RideshareComponents: React.FC<RideshareComponentProps> = ({
  control,
  isUpdate,
  showRecurringOptions,
  invalidPhone,
}) => {
  const { session } = useSessionContext();
  const classes = useStyles();
  const firstAvailableDisabled = isUpdate || showRecurringOptions;
  const flexibleRideEnabled = session?.featureFlags?.includes('flexible_ride');
  const firstAvailableEnabled = session?.account?.firstAvailableBookingEnabled;

  if (!firstAvailableEnabled && !flexibleRideEnabled) {
    return null;
  }

  return (
    <Box className={classes.fareSection}>
      {firstAvailableEnabled && (
        <RideshareCheckboxComponent
          control={control}
          name="ride.firstAvailable"
          disabled={firstAvailableDisabled}
          labelText={FIRST_AVAILABLE_LABEL_TEXT}
          tooltipText={FIRST_AVAILABLE_TOOLTIP_TEXT}
          fieldToClear="ride.flexibleRide"
        />
      )}
      {flexibleRideEnabled && (
        <RideshareCheckboxComponent
          control={control}
          name="ride.flexibleRide"
          labelText={FLEXIBLE_RIDE_LABEL_TEXT}
          tooltipText={FLEXIBLE_RIDE_TOOLTIP_TEXT}
          fieldToClear="ride.firstAvailable"
          disabled={invalidPhone}
          uncheckIfDisabled={true}
        />
      )}
    </Box>
  );
};

export const RideshareComponents = React.memo(_RideshareComponents);
