import React from 'react';
import { useStyles } from './styles';
import { Box, Grid, Typography } from '@material-ui/core';
import { RideTimeSummaryProps } from './props';
import {
  convertLocalToISOInTimeZone,
  mediumDateTime,
  updateDateWithTimeString,
} from '@onwardcare/core';
import { formatDuration, intervalToDuration } from 'date-fns';
import { useSessionContext } from '../../../../../contexts/session-context';

const _RideTimeSummary: React.FC<RideTimeSummaryProps> = ({
  arrivalDate,
  arrivalTime,
  bufferMinutes,
  rideEstimate,
  pickupTime,
}) => {
  const classes = useStyles();
  const { session } = useSessionContext();
  const timeZone = session?.account?.tzName ?? undefined;

  const item = (label: string, value: string, highlight?: boolean) => {
    return (
      <Grid item xs={12}>
        <Typography component="span" variant="body1">
          <div className={classes.root}>
            <Box display="flex">
              <Box
                flexGrow={1}
                fontWeight="fontWeightMedium"
                className={classes.label}
              >
                {label}
              </Box>
              <Box
                fontWeight="fontWeightMedium"
                className={highlight ? classes.result : classes.empty}
              >
                {value}
              </Box>
            </Box>
          </div>
        </Typography>
      </Grid>
    );
  };

  const minutesToDuration = (minutes: number) => {
    const duration = formatDuration(
      // We need to convert minutes to milliseconds.
      intervalToDuration({ start: 0, end: minutes * 60 * 1000 }),
    );

    return duration || '0 minutes';
  };

  return (
    <Grid container className={classes.root}>
      <Typography component="span" variant="h5" className={classes.title}>
        Summary
      </Typography>
      {item(
        'Arrival Date and Time',
        arrivalTime
          ? mediumDateTime(
              convertLocalToISOInTimeZone(
                updateDateWithTimeString(arrivalDate, arrivalTime),
                timeZone,
              ),
              {
                timeZone,
              },
            )
          : '',
      )}
      {item('Buffer Time', minutesToDuration(bufferMinutes))}
      {item('Ride Estimate', minutesToDuration(rideEstimate))}
      {item(
        'Proposed Pickup Time',
        pickupTime
          ? mediumDateTime(convertLocalToISOInTimeZone(pickupTime, timeZone), {
              timeZone,
            })
          : '',
        true,
      )}
    </Grid>
  );
};

export const RideTimeSummary = React.memo(_RideTimeSummary);
