import { toDate } from 'date-fns-tz';
import { Box, Container, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Skeleton, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { longDateOnly, personName } from '@onwardcare/core';
import RiderSelect from 'components/dashboard/RiderSelect';
import ZeroState from 'components/ZeroState';
import { useTracking } from 'lib/analytics/Tracker';
import React, { useEffect, useState } from 'react';
import {
  Custodian,
  DashboardQueryQuery,
  useDashboardQueryLazyQuery,
} from '../../../generated/graphql';
import { CustodianSelect } from '../CustodianSelect';
import RideList from './RideList';
import { useSessionContext } from '../../../contexts/session-context';
import { Props } from './types';
import { groupBy } from '../utils';
import { styles } from './styles';

type ridersType = NonNullable<DashboardQueryQuery['riders']>;
type RidesType = NonNullable<DashboardQueryQuery['upcomingRides']>;

type SortType = 'date' | 'rider';

const UpcomingRidesCard: React.FC<Props> = ({ custodians, updateRides }) => {
  const classes = styles();
  const [sortType, setSortType] = useState<SortType>('date');
  const [selectedRider, setSelectedRider] = useState<ridersType[number] | null>(
    null,
  );
  const [selectedCustodian, setSelectedCustodian] = useState<Custodian | null>(
    null,
  );
  const theme = useTheme();
  const tracker = useTracking();
  const { session } = useSessionContext();

  const [getDashboardData, { data: dashboardData, loading }] =
    useDashboardQueryLazyQuery({ fetchPolicy: 'no-cache' });

  const { upcomingRides: rides, riders } = dashboardData || {};

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  useEffect(() => {
    updateRides(() => {
      return getDashboardData();
    });
  }, [getDashboardData, updateRides]);

  // on small screen we don't show the container padding
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // on large screens we show multiple cards per row
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const bucketRides = (dataSource: RidesType, resultArray: Array<any>) => {
    if (largeScreen) {
      const rows = Math.ceil(dataSource.length / 2);
      for (let i = 0; i < rows; i++) {
        resultArray.push({
          rides: [dataSource[i * 2], dataSource[i * 2 + 1]].filter(
            e => e != null,
          ),
        });
      }
    } else {
      dataSource.forEach(ride => resultArray.push({ rides: [ride] }));
    }
  };

  const renderRidesByDay = () => {
    const filteredRides = selectedCustodian
      ? rides?.filter(r => r.custodian?.id === selectedCustodian?.id)
      : rides;
    const bucketRidesByDay = groupBy<RidesType>(filteredRides || [], r =>
      longDateOnly(r.requestedStartTime, {
        timeZone: session?.account?.tzName,
      }),
    );

    let ridesAndHeaders: Array<{ title: any }> = [];

    Object.keys(bucketRidesByDay)
      .map(d => [d, toDate(d)])
      // TODO: This works and is old code, but we probably need to figure out
      // how to do this to make TypeScript happy.
      // @ts-ignore
      .sort(d => d[1])
      .forEach(date => {
        const day = date[0];
        ridesAndHeaders.push({ title: day });

        // TODO: This works and is old code, but we probably need to figure out
        // how to do this to make TypeScript happy.
        // @ts-ignore
        const bucketedRides = bucketRidesByDay[day];
        bucketRides(bucketedRides, ridesAndHeaders);
      });

    return <RideList data={ridesAndHeaders} />;
  };

  const renderRidesByRider = () => {
    let ridesToBucket = selectedCustodian
      ? rides?.filter(r => r.custodian?.id === selectedCustodian?.id)
      : rides;

    if (selectedRider) {
      ridesToBucket = ridesToBucket?.filter(
        r => r.rider && r.rider.id === selectedRider.id,
      );
    }
    const bucketRidesByRider = groupBy(ridesToBucket || [], r =>
      personName(r.rider),
    );
    let ridesAndHeaders: Array<{ title: any }> = [];

    Object.keys(bucketRidesByRider)
      .sort()
      .forEach(riderName => {
        ridesAndHeaders.push({ title: riderName });
        const bucketedRides = bucketRidesByRider[riderName];
        bucketRides(bucketedRides, ridesAndHeaders);
      });
    return <RideList data={ridesAndHeaders} />;
  };

  const renderFilteredRides = () => {
    return sortType === 'date' ? renderRidesByDay() : renderRidesByRider();
  };

  const handleSortChange = (e: any, sortType: SortType) => {
    tracker.track('Dashboard Sort Change to ' + sortType);
    setSortType(sortType);
  };

  const handleRiderChange = (rider: ridersType[number]) => {
    tracker.track('Dashboard Filter By Rider');
    setSelectedRider(rider);
  };

  const handleCustodianChange = (custodians: Custodian) => {
    tracker.track('Dashboard Filter By Custodian');
    setSelectedCustodian(custodians);
  };

  const renderFilters = () => {
    const rideRiderIds = rides?.map(ride => ride.rider?.id);
    const uniqueRiderIds = new Set(rideRiderIds);
    const ridersToFilter = riders?.filter(rider =>
      uniqueRiderIds.has(rider.id),
    );

    return (
      <Box className={classes.filterContainer} display="flex">
        <ToggleButtonGroup
          size="small"
          value={sortType}
          exclusive
          onChange={handleSortChange}
          aria-label="sort type"
        >
          <ToggleButton value="date" aria-label="date sort">
            Date
          </ToggleButton>
          <ToggleButton value="rider" aria-label="date sort">
            Rider
          </ToggleButton>
        </ToggleButtonGroup>
        {sortType === 'rider' && (
          <Box className={classes.riderFilter}>
            <RiderSelect riders={ridersToFilter} onSelect={handleRiderChange} />
          </Box>
        )}
        <Box className={classes.riderFilter}>
          <CustodianSelect
            custodians={custodians?.accountCustodians}
            onSelect={(custodian: Custodian) => {
              handleCustodianChange(custodian);
            }}
            getOptionLabel={(custodian: Custodian) => {
              if (custodian.id === session?.profile?.id) {
                return 'My Rides';
              }
              return personName(custodian);
            }}
          />
        </Box>
      </Box>
    );
  };

  const renderAddRiderZeroState = () => (
    <ZeroState
      imageSrc="/traveler.png"
      text="Before you can book a ride, you'll need to add a rider."
      buttonLabel="Add Rider"
      buttonPath="/riders/new"
    />
  );

  const renderAddRideZeroState = () => (
    <ZeroState
      imageSrc="/bookRide.png"
      text="You have no upcoming rides. Book one today."
      buttonLabel="Book Transportation"
      buttonPath="/rides/new"
    />
  );

  const renderZeroStateOrRides = () => {
    // zero state illustrations from here: https://icons8.com/
    // return renderAddRiderZeroState();
    if (rides && rides.length > 0) {
      return (
        <>
          {renderFilters()}
          {renderFilteredRides()}
        </>
      );
    } else if (riders && riders.length === 0) {
      return renderAddRiderZeroState();
    } else {
      return renderAddRideZeroState();
    }
  };

  if (loading) {
    return (
      <Container>
        <Typography variant="h4" className={classes.skeletonTitle}>
          <Skeleton width="40%" />
        </Typography>

        <Skeleton variant="rect" width={300} height={200} />
      </Container>
    );
  }

  return (
    <Container disableGutters={smallScreen}>
      <Box
        paddingLeft={smallScreen ? 1 : 0}
        paddingRight={smallScreen ? 1 : 0}
        display="flex"
        flexDirection="column"
        height="100%"
      >
        <header className={classes.header}>
          <Typography variant="h4" gutterBottom>
            Upcoming Rides
          </Typography>
        </header>
        {renderZeroStateOrRides()}
      </Box>
    </Container>
  );
};

export default UpcomingRidesCard;
