const onwardColors = {
  onwardDarkGray: '#474941',
  onwardMediumDarkGray: '#656660',
  onwardLightGray: '#6D7679',
  onwardGray: '#848580',
  onwardWhite: '#fff',
  onwardWhiteGray: '#f4f4f3',
  onwardNavyBlue: '#215261',
  onwardMediumBlue: '#29758E',
  onwardBlue: '#42a7c4',
  onwardGreen: '#51ADAA',
  onwardBrightGreen: '#00725f',
  onwardRed: '#D26262',
  onwardGold: '#B69A74',
  onwardLightBlue: '#92C9D6',
  onwardDarkPurple: '#2F193B',
  onwardMedPurple: '#572E72',
  onwardLightPurple: '#8260A2',
  onwardVelvetCosmos: '#54174C',
};

export default onwardColors;
