import {
  Box,
  Button,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  text: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

export default function ZeroState({ imageSrc, text, buttonLabel, buttonPath }) {
  const theme = useTheme();
  const classes = useStyles();

  // on small screen we don't show the container padding
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const size = smallScreen ? 250 : 400;
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <img height={size} src={imageSrc} alt={buttonLabel} />
      <Typography variant="body1" className={classes.text}>
        {text}
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        component={Link}
        to={buttonPath}
      >
        {buttonLabel}
      </Button>
    </Box>
  );
}
