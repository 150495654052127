import React from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UnpackNestedValue,
} from 'react-hook-form';
import {
  FormControl,
  makeStyles,
  InputLabel,
  MenuItem,
  FormHelperText,
  Select,
  Typography,
  Link as MUILink,
} from '@material-ui/core';
import { Transport } from '../../../../generated/graphql';

export const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '95%',
  },
}));

export type Props<T> = {
  control: Control;
  transportTypeErrors: FieldErrors<any> | undefined;
  disabled: boolean;
  transportTypes: Transport[];
  watchRide: UnpackNestedValue<T>;
};

export const TransportTypeSelector = <T extends { rideType: string }>({
  control,
  transportTypeErrors,
  watchRide,
  transportTypes,
  disabled,
}: Props<T>) => {
  const classes = useStyles();

  if (transportTypes.length === 0) {
    return (
      <>
        <Typography variant="body2" color="error">
          Your account has no enabled transport types. Contact our Concierge
          team
        </Typography>
        <Typography variant="body2" color="error">
          at{' '}
          <MUILink href="tel://+18007004797" target="_blank" color="inherit">
            1 (800) 700-4797
          </MUILink>{' '}
          or via the integrated chat below
        </Typography>
      </>
    );
  }

  let Error;
  if (transportTypeErrors?.type === 'required') {
    Error = <FormHelperText>Select a transportation type</FormHelperText>;
  } else if (transportTypeErrors?.type === 'custom') {
    Error = <FormHelperText>{transportTypeErrors.message}</FormHelperText>;
  }

  return (
    <FormControl
      error={!!transportTypeErrors}
      disabled={disabled}
      className={classes.formControl}
    >
      <InputLabel id="select-transportation-type-label">
        Transportation Type
      </InputLabel>

      <Controller
        control={control}
        name="ride.transportType"
        rules={{ required: true }}
        as={
          <Select
            labelId="select-transportation-label"
            id="rideTransportationTypeSelect"
            disabled={disabled || transportTypes.length === 1}
          >
            {transportTypes.map(transportType => (
              <MenuItem
                selected={transportTypes.length === 1}
                disabled={
                  watchRide.rideType !== 'oneway' &&
                  transportType.name === 'rideshare'
                }
                key={transportType.id}
                value={transportType.name}
              >
                {transportType.fullName}
              </MenuItem>
            ))}
          </Select>
        }
      />
      {Error}
    </FormControl>
  );
};
