import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonWithName from 'components/PersonWithName';
import FavoriteDriverDialog from 'components/riders/FavoriteDriverDialog';
import _ from 'lodash';
import React from 'react';

const useStyles = makeStyles(theme => ({
  section: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  favoriteButton: {
    marginTop: theme.spacing(1),
  },
  faveDriverHelperText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  faveDrivers: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

export function FavoriteDriversTab({ rider, favoriteDrivers }) {
  const classes = useStyles();
  const [
    favoriteDriversModalOpen,
    setFavoriteDriversModalOpen,
  ] = React.useState(false);

  const favoriteDriversSummary = () => {
    if (favoriteDrivers.length === 0) {
      return <Typography variant="body1">No favorite drivers</Typography>;
    }

    return (
      <Grid container spacing={2} className={classes.faveDrivers}>
        {_.sortBy(favoriteDrivers, 'firstName').map(d => (
          <Grid item xs={12} key={d.id}>
            <PersonWithName person={d} nameType="firstL" />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <>
      <Typography variant="body1" className={classes.faveDriverHelperText}>
        {`Onward will try and match ${rider.firstName} with the drivers below whenever
        possible.`}
      </Typography>
      <Box className={classes.section}>
        <Typography variant="subtitle2">Favorite Drivers</Typography>
        {favoriteDriversSummary()}

        <Button
          className={classes.favoriteButton}
          variant="contained"
          color="secondary"
          component="span"
          onClick={() => setFavoriteDriversModalOpen(true)}
          startIcon={<FavoriteIcon />}
        >
          Update Favorite Drivers
        </Button>
      </Box>

      <FavoriteDriverDialog
        rider={rider}
        favoriteDrivers={favoriteDrivers}
        onClose={() => setFavoriteDriversModalOpen(false)}
        open={favoriteDriversModalOpen}
      />
    </>
  );
}
