import React from 'react';

export const SplitNotes: React.FC<{ notes: string | null | undefined }> = ({
  notes,
}) => {
  if (!notes) {
    return null;
  }

  return (
    <span
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '0.5rem',
        paddingTop: '0.5rem',
      }}
    >
      {notes.split('\n').map(n => (
        <span style={{ margin: 0 }} key={n}>
          {n}
        </span>
      ))}
    </span>
  );
};
