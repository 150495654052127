import Button from '@material-ui/core/Button/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';
import { useStyles } from './AccountFormStyles';

export type DialogProps = {
  open: boolean;
  onClose: (opt: false) => void;
};

export const SimpleDialog: React.FC<DialogProps> = ({ open, onClose }) => {
  const handleClose = () => {
    onClose(false);
    window.location.replace('https://dashboard.onwardrides.com/login');
  };
  const classes = useStyles();

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle>Account Created</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          Thank you for registering! Your account details are being validated.
          You will soon receive an email from info@onwardrides.com with the
          subject 'Reset password instructions' including instructions to
          complete your account setup. Thanks!
        </Typography>
        <Typography gutterBottom>
          If you have any questions please contact our Concierge team at
          <a className={classes.phoneCall} href="tel: 1(800)700-4797">
            {' '}
            1(800)700-4797{' '}
          </a>{' '}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="outlined" onClick={handleClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
