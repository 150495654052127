import onwardColors from 'lib/onwardColors';

import React from 'react';

const Coupe = ({ color = onwardColors.onwardBlue }) => (
  <svg width={83} height={28}>
    <defs>
      <path id="prefix__a" d="M.378.421H164.94V3.6H.38z" />
    </defs>
    <g transform="scale(.5)" fill="none" fillRule="evenodd">
      <g transform="translate(0 52.453)">
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <path
          d="M82.659.421c45.443 0 82.28.713 82.28 1.59 0 .878-36.837 1.59-82.28 1.59S.379 2.888.379 2.01c0-.877 36.837-1.59 82.28-1.59"
          fill="#B3B3B3"
          mask="url(#prefix__b)"
        />
      </g>
      <path
        d="M27.043 29.135c6.884 0 12.467 5.581 12.467 12.467 0 6.884-5.583 12.467-12.467 12.467-6.886 0-12.468-5.583-12.468-12.467 0-6.886 5.582-12.467 12.468-12.467zm106.403 0c6.884 0 12.466 5.581 12.466 12.467 0 6.884-5.582 12.467-12.466 12.467-6.886 0-12.468-5.583-12.468-12.467 0-6.886 5.582-12.467 12.468-12.467zm-69.84-8.936c-.332.014.51-1.538.586-2.817.13-2.163-3.824-2.323-6.274-2.165 4.753-3.535 11.808-7.298 18.124-9.486 2.223-.745 8.254-1.654 12.428-1.791l8.772-.282c.97-.013.934.395.814 1.201-.438 2.915-1.257 8.19-1.915 11.828-.323 1.788-.704 2.16-2.538 2.237L63.607 20.2zm37.116-3.36c.868-7.125 1.262-9.78 1.606-11.769.152-.869.314-1.332 1.386-1.356l2.462-.056c1.785-.041 10.376 5.653 13.807 9.218 1.985 2.06 2.791 4.959-1.59 5.163l-15.388.715c-2.477.114-2.556.323-2.283-1.914zm52.402 30.14c2.314-.192 4.817-.382 6.837-.682 1.773-.262 3.165-.698 3.744-1.233 1.831-1.703 1.621-7.01 1.59-9.729-.007-.663-1.685-.664-1.726-1.354a240.63 240.63 0 00-.996-12.12c-.188-1.781-1.668-3.24-4.005-3.97-7.089-2.21-17.532-3.625-20.891-4.606-2.475-.724-3.594-1.59-4.828-2.352-4.789-2.964-10.727-6.222-14.066-7.705-3.107-1.381-5.553-3.151-9.765-3.151-11.17 0-24.007-.766-34.407 2.54-9.959 3.165-19.036 8.885-28.482 13.344-11.433 1.215-24.126 3.536-35.709 6.407-3.746.93-5.377 2.665-6.528 4.893-1.255 2.428-1.849 4.706-2.424 6.085-.916.075-.95.353-1.242.889-.268.5-.231 2.49-.213 3.256.027 1.15.11 2.875.21 3.992.206 2.336.6 3.646 1.788 4.531.678.507 1.766.897 2.723.927l3.174.104c3.535-.46 3.218.176 2.978-3.744a28.14 28.14 0 01-.052-1.7c.002-8.95 7.258-16.206 16.209-16.206 8.95 0 16.207 7.256 16.207 16.207 0 1.515-.096 2.983-.362 4.375-.669 3.509-1.029 2.786 2.57 3.038l69.21-.076c3.475-.428 3.148.148 2.72-3.914-.117-1.102-.15-2.248-.15-3.423 0-8.951 7.257-16.207 16.208-16.207 8.95 0 16.207 7.256 16.207 16.207 0 .835.023 1.656.018 2.457-.023 3.458.518 3.164 3.453 2.92z"
        fill={color}
      />
    </g>
  </svg>
);

export default Coupe;
