import { useTransportTypeOptionsQueryLazyQuery } from 'generated/graphql';
import React from 'react';

export const useTransportTypeOptions = (transportType: string) => {
  const [
    getTransportTypeOptions,
    { data, error, loading },
  ] = useTransportTypeOptionsQueryLazyQuery();

  React.useEffect(() => {
    getTransportTypeOptions({ variables: { transportType } });
  }, [transportType, getTransportTypeOptions]);

  return { data, error, loading };
};
