import MainLayout from 'components/layout/MainLayout';
import { RiderProfileForm } from 'components/riders/RiderProfileForm';
import { loader } from 'graphql.macro';
import { useTracking } from 'lib/analytics/Tracker';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAddCustodianRiderMutationMutation } from '../generated/graphql';
import { dateStringToISO } from '@onwardcare/core';
import { useRiderProfileNavigation } from '../hooks/useRiderProfileNavigation';

const RIDERS_QUERY = loader('../data/queries/RidersQuery.graphql');

function CreateRider() {
  const [createError, setCreateError] = useState(null);
  const tracker = useTracking();

  const { generateRedirectUrl } = useRiderProfileNavigation();

  useEffect(() => {
    tracker.track('Create Rider Viewed');
  }, [tracker]);

  const history = useHistory();

  const onRiderCreated = (data: any) => {
    const error = data.addCustodianRider.error;

    if (error) {
      onRiderCreatedError(error);
    } else {
      const url = generateRedirectUrl(data?.addCustodianRider?.rider?.id);

      if (url) {
        history.push(url);
      } else {
        history.push(`/riders`);
      }
    }
  };

  const onRiderCreatedError = (error: any) => {
    if (error) {
      setCreateError(error);
    }
  };

  const [addCustodianRider, { loading }] = useAddCustodianRiderMutationMutation(
    {
      // we're using an array of strings instead of objects as this method reuses the variables,
      // which is good for recent locations
      refetchQueries: [{ query: RIDERS_QUERY }],

      onCompleted: onRiderCreated,
      onError: onRiderCreatedError,
    },
  );

  const riderToPersist = (data: any) => {
    const { profilePhoto, dob: date, ...rest } = data;
    let photo = null;

    if (profilePhoto?.length) {
      photo = profilePhoto[0];
    }

    // We have to convert the date string to ISO format.
    const dob = date ? dateStringToISO(date) : null;
    return { photo, dob, ...rest };
  };

  const onUpdate = (data: any) => {
    tracker.track('Rider Profile Created');

    addCustodianRider({ variables: { rider: riderToPersist(data) } });
  };

  return (
    <MainLayout>
      <RiderProfileForm
        updateError={createError}
        onUpdate={onUpdate}
        submitting={loading}
      />
    </MainLayout>
  );
}

export default CreateRider;
