import { Snackbar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { IconButton } from '@material-ui/core';
import Update from '@material-ui/icons/Update';
import CloseIcon from '@material-ui/icons/Close';

import {
  RidesCollectionScope,
  useRidesLastModifiedAtQueryLazyQuery,
} from '../../generated/graphql';

const mapRidesCollectionScopeToMessage = {
  [RidesCollectionScope.Upcoming]: 'Upcoming Rides Has Been Updated',
  [RidesCollectionScope.History]: 'Ride History Has Been Updated',
};

export const SnackBarWrapper: React.FC<{
  update?: () => void;
  scope?: RidesCollectionScope;
}> = ({ update, scope = RidesCollectionScope.Upcoming }) => {
  const [
    getLastModifiedRides,
    { data: lastModified },
  ] = useRidesLastModifiedAtQueryLazyQuery({
    fetchPolicy: 'no-cache',
    pollInterval: 10000,
  });

  const message = mapRidesCollectionScopeToMessage[scope];

  useEffect(() => {
    getLastModifiedRides({
      variables: {
        scope,
      },
    });
  }, [getLastModifiedRides, scope]);

  const [showSnackbar, toggleSnackbar] = useState(false);
  const lastModifiedValue = React.useRef<number | undefined>(undefined);

  useEffect(() => {
    if (lastModified) {
      if (lastModifiedValue.current !== lastModified.ridesLastModifiedAt) {
        if (lastModifiedValue.current === undefined) {
          lastModifiedValue.current = lastModified.ridesLastModifiedAt;
        } else {
          lastModifiedValue.current = lastModified.ridesLastModifiedAt;
          toggleSnackbar(true);
        }
      }
    }
  }, [lastModified]);
  return (
    <Snackbar open={showSnackbar} autoHideDuration={null}>
      <MuiAlert
        action={
          <>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => {
                update?.();
                toggleSnackbar(false);
              }}
            >
              <Update />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => toggleSnackbar(false)}
            >
              <CloseIcon />
            </IconButton>
          </>
        }
        style={{ alignItems: 'center' }}
        icon={false}
        elevation={6}
        variant="filled"
        severity="success"
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
};
