import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { TokenResponse, useEhrContext } from '../../contexts/ehr-context';

const useStyles = makeStyles(theme => ({
  debugInfo: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
}));

export default function DebugInformation({
  tokenResponse,
}: {
  tokenResponse: TokenResponse | null;
}) {
  const classes = useStyles();
  const { ehrSession } = useEhrContext();
  const debug = ehrSession?.testSession || false;

  return (
    <>
      {debug && (
        <>
          <div className={classes.debugInfo}>
            <Divider />
          </div>

          <div>
            {tokenResponse ? (
              <>
                <p>
                  <b>Token Response:</b>
                </p>

                <pre>
                  {tokenResponse && JSON.stringify(tokenResponse, null, 2)}
                </pre>
              </>
            ) : (
              <p>There is no token response</p>
            )}
          </div>
        </>
      )}
    </>
  );
}
