import onwardColors from 'lib/onwardColors';

import React from 'react';

const Hatchback = ({ color = onwardColors.onwardBlue }) => (
  <svg width={83} height={28}>
    <defs>
      <path id="prefix__a" d="M0 .422h164.562v3.177H0z" />
    </defs>
    <g transform="scale(.5)" fill="none" fillRule="evenodd">
      <g transform="translate(0 52.004)">
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <path
          d="M82.281.422c45.443 0 82.28.713 82.28 1.59 0 .877-36.837 1.587-82.28 1.587S0 2.89 0 2.012 36.838.422 82.28.422"
          fill="#B3B3B3"
          mask="url(#prefix__b)"
        />
      </g>
      <path
        d="M32.314 28.687c6.885 0 12.466 5.581 12.466 12.467 0 6.884-5.58 12.466-12.466 12.466-6.886 0-12.467-5.582-12.467-12.466 0-6.886 5.581-12.467 12.467-12.467m102.776 0c6.884 0 12.467 5.581 12.467 12.467 0 6.884-5.583 12.466-12.467 12.466-6.886 0-12.468-5.582-12.468-12.466 0-6.886 5.582-12.467 12.468-12.467M68.878 19.75c.081-.321.508-1.537.585-2.816.13-2.162-4.617-2.323-7.069-2.165 4.756-3.535 12.604-7.298 18.92-9.486C86.52 3.538 93.531 3.498 99.112 3.38c.97-.011.936.397.813 1.202-.39 2.565-1.339 8.106-1.914 11.829-.278 1.795-.703 2.22-2.539 2.235-7.249.061-26.684 1.453-26.595 1.105m33.644-2.876c.998-8.174 1.12-10.54 1.889-12.528.123-.323.845-.992 1.387-.967 5.885.28 12.433.181 18.13 1.502 2.026.567 6.169 5.281 7.206 6.701.4.547 3.376 4.004 2.898 4.717-.334.498-.755.811-1.554.838-8.86.296-18.753 1.231-27.602 1.338-2.481.03-2.628.636-2.354-1.601m51.902 29.656c1.767-.303 3.168-.7 3.743-1.235 1.833-1.702 1.28-7.008 1.248-9.729-.006-.66-1.685-.666-1.724-1.354-.243-4.193-2.051-11.898-2.508-16.206-.166-1.56-.8-3.628-3.567-5.338-3.23-1.997-12.482-7.442-15.992-8.915-2.317-.972-1.753-1.207-4.242-1.565-12.361-1.773-39.389-3.868-51.5-.02-9.96 3.164-18.632 7.753-27.626 13.345-15.91 2.743-28.892 5.074-36.62 7.151-3.726 1.002-5.322 2.778-6.473 5.005a61.636 61.636 0 00-2.424 5.229c-1.376.114-1.709 1.383-1.683 3.1.038 2.693-.213 4.614.537 7.283.553 1.967 2.314 3.106 4.413 3.174l3.872.127c1.88-.357 2.458-.254 2.297-2.77a41.812 41.812 0 01-.069-2.658c0-8.951 7.257-16.208 16.208-16.208 8.95 0 16.207 7.257 16.207 16.208 0 1.251-.003 2.47-.084 3.64-.28 3.99-1.522 3.575 2.311 3.773l65.13-.072c2.785-.34 3.532-.005 3.145-3.786-.116-1.145-.141-2.334-.141-3.555 0-8.951 7.256-16.208 16.208-16.208 8.95 0 16.207 7.257 16.207 16.208 0 .805-.001 1.59-.027 2.354-.105 3.076-.782 3.696 3.154 3.022"
        fill={color}
      />
    </g>
  </svg>
);

export default Hatchback;
