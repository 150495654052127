import {
  FiltersInput,
  RidesCollectionScope,
  RidesPaginationQueryQuery,
} from '../generated/graphql';
import { differenceInMinutes } from 'date-fns';
import { useAuth } from '../contexts/auth-context';
import ENV from '../constants/Env';

const { apiUrl } = ENV;

export const durationString = (minutes: any) => {
  if (!minutes) return null;
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;

  return hours > 0 ? `${hours} hr ${mins} min` : `${mins} min`;
};

export const duration = (
  ride: RidesPaginationQueryQuery['ridesCollection']['collection'][number],
) => {
  if (ride.actualStartTime && ride.actualEndTime) {
    const dur = Math.floor(
      differenceInMinutes(
        new Date(ride.actualEndTime),
        new Date(ride.actualStartTime),
      ),
    );
    return dur;
  }
  return null;
};

export const useDownloadCsv = (
  scope: RidesCollectionScope = RidesCollectionScope.Upcoming,
  filters?: Array<FiltersInput>,
  search?: string,
) => {
  const auth = useAuth();
  const headers = auth.getAuthRequestHeaders();

  return () =>
    fetch(`${apiUrl}/rides/export/${scope.toLowerCase()}`, {
      method: 'post',
      headers,
      body: JSON.stringify({
        filters: filters,
        search: search,
      }),
    })
      .then(r => r.text())
      .then(d => {
        const blob = new Blob([d], { type: 'text/csv' });
        const a = document.createElement('a');
        a.download = scope;
        a.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        });
        a.dispatchEvent(clickEvt);
        a.remove();
      })
      .catch(e => console.log('eee', e));
};
