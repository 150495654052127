export type Environment = 'localhost' | 'dev' | 'staging' | 'production';

export type EnvironmentConfig = {
  apiUrl: string;
  webAppHost: string;
  stripeApiKey: string;
  mixpanelKey: string;
  sentryDsn: string;
  reportErrors: boolean;
  portalUrl: string;
  pusherAppKey: string;
  pusherCluster: string;
  app: string;
  mapsApiKey: string;
};

type Config = {
  [key in Environment]: EnvironmentConfig;
};

/**
 * Retrieves the name of the environment.
 *
 * @returns Returns the environment name.
 */
function getEnvironment(): Environment {
  // This is sort of a hack to figure out which environment we're in. It would
  // be good to find a way to read this from an environment variable, but since
  // promoting an app to a new environment in Heroku doesn't do a build, it will
  // use the same environment variables as the previous environment, which would
  // always be Dev.
  const url = window.location.host;

  if (
    url.startsWith('onward-dashboard-production.') ||
    // We must also check the domain here because the old Staging subdomain was
    // also just `dashboard`.
    url.startsWith('dashboard.onwardrides')
  ) {
    return 'production';
  }

  if (
    url.startsWith('onward-dashboard-staging.') ||
    url.startsWith('staging-dashboard.') ||
    url.startsWith('dashboard.joinonward') // This is the old subdomain
  ) {
    return 'staging';
  }

  if (
    url.startsWith('onward-dashboard-dev.') ||
    url.startsWith('dev-dashboard.')
  ) {
    return 'dev';
  }

  if (url.startsWith('localhost') || url.includes('192')) {
    return 'localhost';
  }

  throw Error('Unable to determine the environment');
}

const ENV: Config = {
  localhost: {
    apiUrl: `http://localhost:3000`,
    webAppHost: `http://localhost:3002`,
    stripeApiKey:
      'pk_test_51J7kBbHbdcFIFRu5bTclfmy0Re9jU9gkmLJeoM5bnYbK6YBbU71rWhoZCBhDCf7a0jfAgTR0WLdnljmwsGlMl8P300SwZxQe0Q', //test key
    mixpanelKey: 'acee2fe5255f1f0face3ae0986798a63', // dev
    sentryDsn:
      'https://0f82be70cf324e9baea240ba07233a95@o225853.ingest.sentry.io/5619161',
    reportErrors: false,
    portalUrl: 'http://localhost:4200',
    pusherAppKey: 'a5b8fec4f037e85edbb0',
    pusherCluster: 'us3',
    app: 'dashboard',
    mapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY ?? '',
  },
  dev: {
    apiUrl: 'https://dev-api.joinonward.net',
    webAppHost: 'https://dev-dashboard.joinonward.net',
    stripeApiKey:
      'pk_test_51J7kBbHbdcFIFRu5bTclfmy0Re9jU9gkmLJeoM5bnYbK6YBbU71rWhoZCBhDCf7a0jfAgTR0WLdnljmwsGlMl8P300SwZxQe0Q', //test key
    mixpanelKey: '795726c9476f06235672379f4134e80c', //staging
    sentryDsn:
      'https://0f82be70cf324e9baea240ba07233a95@o225853.ingest.sentry.io/5619161',
    reportErrors: true,
    portalUrl: 'https://dev-portal.joinonward.net',
    pusherAppKey: '401d2b2caf36ba1ff195',
    pusherCluster: 'us3',
    app: 'dashboard',
    mapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY ?? '',
  },
  staging: {
    apiUrl: 'https://staging-api.joinonward.net',
    webAppHost: 'https://staging-dashboard.joinonward.net',
    stripeApiKey:
      'pk_test_51J7kBbHbdcFIFRu5bTclfmy0Re9jU9gkmLJeoM5bnYbK6YBbU71rWhoZCBhDCf7a0jfAgTR0WLdnljmwsGlMl8P300SwZxQe0Q', //test key
    mixpanelKey: '795726c9476f06235672379f4134e80c', //staging
    sentryDsn:
      'https://0f82be70cf324e9baea240ba07233a95@o225853.ingest.sentry.io/5619161',
    reportErrors: true,
    portalUrl: 'https://staging-portal.joinonward.net',
    pusherAppKey: '401d2b2caf36ba1ff195',
    pusherCluster: 'us3',
    app: 'dashboard',
    mapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY ?? '',
  },
  production: {
    apiUrl: 'https://api.onwardrides.com',
    webAppHost: 'https://dashboard.onwardrides.com',
    stripeApiKey:
      'pk_live_51J7kBbHbdcFIFRu5pc5IE8BbJf6Z37txJ3xV6EltLM44rwel1zuPiiECt3icq8zgqez1KaGBqHPWHRrZ5iWxL2AS00qXnzzL9o', // live key
    mixpanelKey: 'fd71ac3b84921dcc356b219749e73ca1',
    sentryDsn:
      'https://0f82be70cf324e9baea240ba07233a95@o225853.ingest.sentry.io/5619161',
    reportErrors: true,
    portalUrl: 'https://portal.onwardrides.com',
    pusherAppKey: '314bb0ca1c2f55d06e51',
    pusherCluster: 'us3',
    app: 'dashboard',
    mapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY ?? '',
  },
};

/**
 * Get the config for the given environment.
 * @param env The environment to get the config for.
 * @returns The config for the given environment.
 */
function getEnvVars(env: string) {
  if (env && env.indexOf('staging') !== -1) return ENV.staging;
  if (env && env.indexOf('production') !== -1) return ENV.production;
  if (env && env.indexOf('dev') !== -1) return ENV.dev;
  return ENV.localhost;
}

/**
 * Set the localhost IP address for the API server.
 * @param localIpURL The local IP address of the machine running the API server.
 */
function setLocalhostIp(localIpURL: string) {
  ENV.localhost.apiUrl = `http://${localIpURL}:3000`;
}

export default getEnvVars(getEnvironment());
export { getEnvironment };
export { setLocalhostIp };
