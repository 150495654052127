import { DataToStore, PersistDataStorage } from '../lib/PersistDataStorage';

export const ridersStorage = new PersistDataStorage<DataToStore>(
  'rideProfileTable',
);

export const upcomingRidesStorage = new PersistDataStorage<DataToStore>(
  'upcomingRidesTable',
);

export const historyStorage = new PersistDataStorage<DataToStore>(
  'rideHistoryTable',
);

export const accountCustodianStorage = new PersistDataStorage<DataToStore>(
  'accountCustodianTable',
);

/**
 * Clears all the storages used for storing grid state.
 */
export function clearAllStorages() {
  ridersStorage.clearStorage();
  upcomingRidesStorage.clearStorage();
  historyStorage.clearStorage();
  accountCustodianStorage.clearStorage();
}
