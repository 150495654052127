export interface CookieOptions {
  /**
   * Determines if we are writing the cookie for rider app local debug.
   */
  domainIp?: string;
  /**
   * The expiration date.
   */
  expires?: Date;
}

/**
 * Gets the domain to use for the cookie.
 * @returns {string} The domain to use for the cookie.
 */
function getDomain() {
  const host = window.location.hostname;
  return host.includes('localhost')
    ? 'localhost'
    : // Strip off the subdomain. This assumes that there is always a subdomain,
      // which is true for all our apps.
      host.substring(host.indexOf('.'));
}

/**
 * Determines if the domain is localhost.
 * @returns {boolean} True if the domain is localhost, false otherwise.
 */
function isLocalhost() {
  return getDomain() === 'localhost';
}

/**
 * Generates the options for the cookie.
 * @param options {CookieOptions} - The options to use for the cookie.
 * @returns Returns the cookie options.
 */
function generateCookieOptions(options: CookieOptions = {}) {
  const domain = options.domainIp || getDomain();
  const secure = options.domainIp || isLocalhost() ? '' : 'secure;';
  // This should be using UTC time, but it seems like it's still using the
  // pacific time zone that's set globally.
  const expirationDate = options.expires ? `expires=${options.expires}` : '';
  const cookieOptions = `path=/; domain=${domain}; ${secure} samesite=strict; ${expirationDate};`;

  return cookieOptions;
}

/**
 * Writes the cookie to the browser.
 * @param name {string} The name of the cookie.
 * @param value {string} The value to write to the cookie.
 * @param options {CookieOptions} - The options to use for the cookie.
 */
export function writeCookie(
  name: string,
  value: string,
  options: CookieOptions = {},
) {
  const cookieOptions = generateCookieOptions(options);
  document.cookie = `${name}=${value}; ${cookieOptions}`;
}

/**
 * Gets the Onward token from the cookie.
 * @returns {OnwardToken | null} The token if it exists, null otherwise.
 */
export function readCookie(name: string) {
  const value = document.cookie
    .split('; ')
    .find(row => row.startsWith(`${name}=`))
    ?.split('=')[1];

  return value ?? null;
}

/**
 * Deletes the cookie.
 * @param name {string} - The name of the cookie.
 * @param options {CookieOptions} - The options to use for the cookie.
 */
export function deleteCookie(name: string, options: CookieOptions = {}) {
  const deleteOptions = { ...options, expires: new Date(0) };
  writeCookie(name, '', deleteOptions);
}
