import { Box, Button, Grid, makeStyles, TextField } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles(theme => ({
  section: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  actionButton: {
    marginRight: theme.spacing(2),
  },
  tabContents: {
    marginTop: theme.spacing(2),
  },
  tabIndicator: {
    height: 4,
  },
  submit: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    // margin: theme.spacing(3, 1, 3, 1),
    // width: '95%',
  },
}));

export function InformationTab({ account, onUpdateAccount }) {
  const classes = useStyles();

  const { handleSubmit, errors, register } = useForm();

  const submit = data => {
    onUpdateAccount(data);
  };

  return (
    <Box className={classes.tabContents}>
      <form
        noValidate
        onSubmit={handleSubmit(submit)}
        className={classes.section}
      >
        <Grid container spacing={2} className={classes.section}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="name"
              name="name"
              label="Account Name"
              defaultValue={account.name}
              error={!!errors.name}
              helperText={errors.name && 'Account Name is required'}
              inputRef={register({
                required: true,
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="billingEmail"
              name="billingEmail"
              label="Billing Email"
              defaultValue={account.billingEmail}
              helperText={
                'The billing email is where receipts will be sent. Leave it blank to not receive receipts.'
              }
              inputRef={register({})}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              key="updateButton"
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              fullWidth
            >
              Update Account
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
