import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  left: {
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    width: '95%',
  },
  container: {
    height: '100vh',
  },
  submit: {
    margin: theme.spacing(3, 1, 3, 1),
    width: '100%',
  },
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  content: {
    display: 'flex',
  },
  block: {
    display: 'block',
  },
  group: {
    columns: '2 auto',
    [theme.breakpoints.down('sm')]: {
      columns: '1 auto',
    },
  },
}));
