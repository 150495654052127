import React, { useContext, useState } from 'react';
import Snackbar, { SnackbarProps } from '@material-ui/core/Snackbar';
import Button, { ButtonTypeMap } from '@material-ui/core/Button';

export type DefaultToastType = {
  ButtonProps: ButtonTypeMap;
  message: string;
  action: any;
  ToastProps: SnackbarProps;
  customParameters: any;
};

export type ToastContextType = {
  showMessage: (
    message: string,
    action?: any,
    handleAction?: Function,
    customParameters?: any,
  ) => void;
};

const DefaultToast: React.FC<DefaultToastType> = ({
  message,
  action,
  ButtonProps,
  ToastProps,
}) => {
  return (
    <Snackbar
      {...ToastProps}
      message={message || ''}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      action={
        action !== null && (
          <Button color="secondary" size="small" {...ButtonProps}>
            {action}
          </Button>
        )
      }
    />
  );
};

const ToastContext = React.createContext<ToastContextType | null>(null);

export function useToast() {
  return useContext(ToastContext);
}

export const ToastProvider: React.FC<{
  ButtonProps?: ButtonTypeMap;
  ToastProps?: SnackbarProps;
  ToastComponent?: any;
}> = props => {
  const [state, setState] = useState<{
    open: boolean;
    message: string;
    action: any | null;
    handleAction: Function | null | undefined;
    customParameters: any | null;
  }>({
    open: false, // Don't show the Toast message by default.
    message: '',
    action: null,
    handleAction: null,
    customParameters: null,
  });

  /**
   * Display a message with this snackbar.
   * @param {string} message message to display
   * @param {string} [action] label for the action button
   * @param {function} [handleAction] click handler for the action button
   * @param {any} [customParameters] custom parameters that will be passed to the snackbar renderer
   * @public
   */
  const showMessage: ToastContextType['showMessage'] = (
    message,
    action,
    handleAction,
    customParameters,
  ) => {
    setState({
      open: true,
      message,
      action,
      handleAction,
      customParameters,
    });
  };

  const handleActionClick = () => {
    handleClose();
    state?.handleAction?.();
  };

  const handleClose = () => {
    setState(prevState => ({ ...prevState, open: false, handleAction: null }));
  };

  const { action, message, open, customParameters } = state;

  const {
    ButtonProps = {},
    children,
    ToastProps = { autoHideDuration: 4000 },
    ToastComponent = DefaultToast,
  } = props;

  return (
    <>
      <ToastContext.Provider value={{ showMessage }}>
        {children}
      </ToastContext.Provider>
      <ToastComponent
        message={message}
        action={action}
        ButtonProps={{ ...ButtonProps, onClick: handleActionClick }}
        ToastProps={{ ...ToastProps, open, onClose: handleClose }}
        customParameters={customParameters}
      />
    </>
  );
};
