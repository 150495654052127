import React from 'react'
import { useQuery, QueryHookOptions } from '@apollo/client';
import { useLoadingEffect } from 'components/layout/Loading';
import MainLayout from './MainLayout';
import { Alert, AlertTitle, Skeleton } from '@material-ui/lab';
import {
  Box,
  Button,
  Container,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { DocumentNode } from 'graphql';
import { ApolloQueryResult, OperationVariables } from '@apollo/client/core/types';

const useStyles = makeStyles(theme => ({
  skeletonTitle: {
    marginBottom: theme.spacing(4),
  },
}));

type LayoutWithQueryProps<TData, TVariables extends OperationVariables> = {
  query: DocumentNode,
  variables?: TVariables,
  withRefetch?: boolean
  renderView: (...args: [TData | undefined, ((variables?: Partial<TVariables>) => Promise<ApolloQueryResult<TData>>) | undefined]) => React.ReactNode,
  usePadding?: 'yes' | 'no' | 'maybe'
  skeletonVariant?: 'cards' | 'table' | null,
  WrapperComponent?: React.JSXElementConstructor<any>
  queryHookOptions?: QueryHookOptions
}

// usePadding supports 3 options:
// yes: always use, eg dashboard
// no: never use, eg ride booking
// maybe: dynamic based on screen size, eg most forms
function LayoutWithQuery<TData, TVariables extends OperationVariables = any>(props: LayoutWithQueryProps<TData, TVariables>): React.ReactElement {
 const {
    query,
    variables,
    renderView,
    usePadding = 'maybe',
    skeletonVariant = null,
    withRefetch = false,
    WrapperComponent = MainLayout,
    queryHookOptions
  } = props

  const { loading, error, data, refetch } = useQuery<TData>(query, { variables, ...queryHookOptions });
  useLoadingEffect(loading);
  const classes = useStyles();

  const skeleton = () => {
    if (skeletonVariant === 'cards') {
      return (
        <Container>
          <Typography variant="h4" className={classes.skeletonTitle}>
            <Skeleton width="40%" />
          </Typography>

          <Skeleton variant="rect" width={300} height={200} />
        </Container>
      );
    }
    if (skeletonVariant === 'table') {
      return (
        <Container>
          <Typography variant="h4" className={classes.skeletonTitle}>
            <Skeleton width="40%" />
          </Typography>

          <Skeleton width="80%" height={40} />
          <Skeleton width="80%" height={40} />
          <Skeleton width="80%" height={40} />
        </Container>
      );
    }
  };

  const content = () => {
    if (loading) return skeleton();
    if (error) {
      return (
        <Box>
          <Alert
            severity="error"
            action={
              <Button color="inherit" size="small" onClick={() => refetch()}>
                TRY AGAIN
              </Button>
            }
          >
            <AlertTitle>Error</AlertTitle>
            Looks like we were unable to reach our servers. Please double check
            your internet connection and then try again.
          </Alert>
        </Box>
      );
    }

    const renderArgs: Parameters<typeof renderView> = [data, withRefetch ? refetch : undefined];

    return renderView(...renderArgs);
  };

  return (
    <WrapperComponent usePadding={usePadding}>{content()}</WrapperComponent>
  );
}

export default LayoutWithQuery
