import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useTracking } from 'lib/analytics/Tracker';
import React from 'react';
import CancelConfirmationForm from './CancelConfirmationForm';
import {
  Ride,
  useAccountCancellationReasonQueryLazyQuery,
  useProfileQueryQuery,
} from '../../generated/graphql';

const CANCEL_MUTATION = loader(
  '../../data/mutations/CancelRideMutation.graphql',
);

const CancelConfirmation: React.FC<{
  ride: Ride;
  handleClose: () => void;
  handleCancelRide: () => void;
  showDialog: boolean;
  cancelDisabled: boolean;
  setCancelDisabled: (value: boolean) => void;
  isBatch?: boolean;
  batchRemainingRides?: Array<Ride>;
  isRebook: boolean;
}> = ({
  ride,
  handleClose,
  handleCancelRide,
  showDialog,
  cancelDisabled,
  setCancelDisabled,
  isBatch,
  batchRemainingRides,
  isRebook,
}) => {
  const cancelSuccess = () => {
    tracker.track('Ride Canceled');
    handleCancelRide();
  };

  const cancelFail = () => {
    setCancelDisabled(false);
  };

  const { data: profileData } = useProfileQueryQuery();

  const [
    getCancellationReasonsData,
    {
      data: cancellationReasonsData,
      loading: cancellationReasonsLoading,
      error: cancellationReasonError,
    },
  ] = useAccountCancellationReasonQueryLazyQuery();

  React.useEffect(() => {
    if (showDialog && profileData?.profile?.promptForCancellationReason) {
      getCancellationReasonsData();
    }
  }, [getCancellationReasonsData, showDialog, profileData]);

  const [cancelRide] = useMutation(CANCEL_MUTATION, {
    onCompleted: cancelSuccess,
    onError: cancelFail,
  });
  const tracker = useTracking();

  const onCancel = (
    cancellationReasonId?: string | null,
    cancelMode: 'single' | 'batch' = 'single',
  ) => {
    setCancelDisabled(true);
    cancelRide({
      variables: {
        rideId: parseInt(ride.id),
        cancellationReasonId:
          cancellationReasonId && parseInt(cancellationReasonId),
        batchCancel: cancelMode === 'batch',
      },
      update: cache => {
        cache.modify({
          fields: {
            upcomingRides(existingRides = [], { readField }) {
              if (cancelMode === 'batch') {
                const batchRidesIds = batchRemainingRides?.map(
                  (batch: any) => batch.id,
                );
                return existingRides.filter(
                  (postRef: Ride) =>
                    !batchRidesIds?.includes(readField('id', postRef)),
                );
              }
              return existingRides.filter(
                (postRef: Ride) => ride.id !== readField('id', postRef),
              );
            },
          },
        });
      },
    });
  };

  return (
    <CancelConfirmationForm
      ride={ride}
      isBatch={isBatch}
      handleClose={handleClose}
      onCancel={onCancel}
      showDialog={showDialog}
      cancelDisabled={cancelDisabled}
      cancellationReasonsData={cancellationReasonsData}
      cancellationReasonsLoading={cancellationReasonsLoading}
      cancellationReasonError={cancellationReasonError}
      promptForCancellationReason={
        profileData?.profile?.promptForCancellationReason
      }
      batchRemainingRides={batchRemainingRides?.length}
      isRebook={isRebook}
    />
  );
};

export default CancelConfirmation;
