import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Alert } from '@material-ui/lab';
import CardRow from 'components/accounts/CardRow';
import ZeroStateText from 'components/ZeroStateText';
import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import {
  RemoveRiderPaymentMethodMutationVariables,
  RiderProfileQueryQuery,
  StripeSetupIntentQueryQuery,
  StripeSetupIntentQueryQueryVariables,
  UpdateRiderPaymentMethodMutationMutationVariables,
} from '../../../generated/graphql';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { StripeElementsOptions } from '@stripe/stripe-js/types/stripe-js/elements-group';
import { Stripe } from '@stripe/stripe-js/types/stripe-js';
import { StripeForm } from '../../layout/StripeForm';
import { RemoveCardDialog } from '../../common/RemoveCardDialog';

const STRIPE_SETUP_INTENT_QUERY = loader(
  '../../../data/queries/StripeSetupIntentQuery.graphql',
);

const useStyles = makeStyles(theme => ({
  section: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  favoriteButton: {
    marginTop: theme.spacing(1),
  },
  helperText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  errorMessage: {
    marginBottom: theme.spacing(2),
  },
  cardStyle: {
    width: '50%',
  },
  rowSpacing: {
    marginBottom: theme.spacing(1),
  },
  actionButton: {
    marginRight: theme.spacing(2),
  },
}));

export type PaymentMethodsTabProps<TData, QueryRiderArgs> = {
  rider?: RiderProfileQueryQuery['rider'] | null;
  onRemoveCard: ({
    paymentMethodId,
    riderId,
  }: RemoveRiderPaymentMethodMutationVariables) => void;
  onUpdatePaymentMethod: ({
    paymentMethodId,
    riderId,
    paymentMethod,
  }: UpdateRiderPaymentMethodMutationMutationVariables) => void;
  getRiderProfile?:
    | ((
        variables?: Partial<QueryRiderArgs>,
      ) => Promise<ApolloQueryResult<TData>>)
    | undefined;
  errorMessage: string | null;
};

type SelectedElement = {
  riderId: string;
  paymentMethodId: string;
};

export function PaymentMethodsTab<
  TData extends RiderProfileQueryQuery,
  QueryRiderArgs
>(props: PaymentMethodsTabProps<TData, QueryRiderArgs>): React.ReactElement {
  const {
    rider,
    onRemoveCard,
    onUpdatePaymentMethod,
    errorMessage,
    getRiderProfile,
  } = props;

  const classes = useStyles();
  const [getSetupIntent, { data }] = useLazyQuery<
    StripeSetupIntentQueryQuery,
    StripeSetupIntentQueryQueryVariables
  >(STRIPE_SETUP_INTENT_QUERY, { fetchPolicy: 'no-cache' });

  const [
    stripeData,
    setStripeData,
  ] = useState<StripeSetupIntentQueryQuery | null>(null);
  const [
    stripePromise,
    setStripePromise,
  ] = useState<Promise<Stripe | null> | null>(null);
  const [
    stripeOptions,
    setStripeOptions,
  ] = useState<StripeElementsOptions | null>(null);
  const [isPrompt, setPrompt] = useState(false);
  const [selectedCard, setSelectedCard] = useState<SelectedElement | null>(
    null,
  );

  useEffect(() => {
    if (data) {
      setStripeData(data);
      data?.stripeSetupIntent?.publishableKey &&
        setStripePromise(loadStripe(data?.stripeSetupIntent?.publishableKey));
      setStripeOptions({
        locale: 'en',
        clientSecret: data?.stripeSetupIntent?.setupIntent,
        appearance: {
          theme: 'flat',
          variables: {
            borderRadius: '0px',
          },
        },
      });
    }
  }, [data]);

  const handleClick = () => {
    if (rider) {
      getSetupIntent({ variables: { ownerId: rider.id, ownerType: 'Rider' } });
    }
  };

  const renderCards = () => {
    if (rider?.paymentMethods?.length) {
      return rider.paymentMethods.map(pm => (
        <CardRow
          key={pm.id}
          paymentMethod={pm}
          onRemoveCard={(paymentMethodId: string) => {
            setPrompt(true);
            setSelectedCard({
              riderId: rider.id,
              paymentMethodId: paymentMethodId,
            });
          }}
          onUpdatePaymentMethod={(
            args: UpdateRiderPaymentMethodMutationMutationVariables,
          ) => onUpdatePaymentMethod({ ...args, riderId: rider.id })}
        />
      ));
    } else {
      return (
        <Box className={classes.rowSpacing}>
          <ZeroStateText>
            {`${rider?.firstName} has no saved payment methods.`}
          </ZeroStateText>
        </Box>
      );
    }
  };

  return (
    <Box display="flex" flexDirection="column" className={classes.section}>
      <Typography variant="body1" className={classes.helperText}>
        {`Payment methods associated with ${rider?.firstName} can only be used on ${rider?.firstName}'s trips.`}
      </Typography>
      {errorMessage && (
        <Alert className={classes.errorMessage} severity="error">
          {errorMessage}
        </Alert>
      )}

      {renderCards()}

      <span>
        <Button
          onClick={handleClick}
          className={classes.actionButton}
          color="primary"
          startIcon={<AddIcon />}
        >
          Add Payment Method
        </Button>
      </span>

      {stripePromise && stripeOptions && (
        <div className={classes.cardStyle}>
          <Elements stripe={stripePromise} options={stripeOptions}>
            <StripeForm<RiderProfileQueryQuery, QueryRiderArgs>
              shouldShowAddButton={true}
              rider={rider}
              stripeData={stripeData}
              getSetupIntent={getSetupIntent}
              getAccount={getRiderProfile}
              ownerType="Rider"
            />
          </Elements>
        </div>
      )}

      <RemoveCardDialog
        handleClose={setPrompt}
        selectedCard={selectedCard}
        onRemoveCard={onRemoveCard}
        open={isPrompt}
      />
    </Box>
  );
}
