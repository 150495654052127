import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import { ApolloError } from '@apollo/client';

import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  FormHelperText,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import {
  AccountCancellationReasonQueryQuery,
  Ride,
} from '../../generated/graphql';

const Transition = React.forwardRef(function Transition(props, ref) {
  //@ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  dialog: {
    padding: theme.spacing(2),
  },
  cancelButton: {
    color: theme.palette.error.main,
  },
  formControl: {
    minWidth: 200,
  },
}));

const CancelConfirmationForm: React.FC<{
  onCancel: (
    cancellationReasonId: string | null,
    cancelMode: 'single' | 'batch',
  ) => void;
  cancellationReasonsData: AccountCancellationReasonQueryQuery | undefined;
  cancellationReasonsLoading: boolean;
  cancellationReasonError: ApolloError | undefined;
  ride: Ride;
  handleClose: () => void;
  showDialog: boolean;
  cancelDisabled: boolean;
  promptForCancellationReason?: boolean;
  isBatch?: boolean;
  batchRemainingRides?: number;
  isRebook?: boolean;
}> = ({
  ride,
  handleClose,
  showDialog,
  onCancel,
  cancelDisabled,
  cancellationReasonsData,
  cancellationReasonError,
  promptForCancellationReason,
  isBatch,
  batchRemainingRides,
  isRebook,
}) => {
  const classes = useStyles();

  const rideOrDelivery = () => {
    return ride.rideType === 'delivery' ? 'delivery' : 'ride';
  };

  const capitalizedRideOrDelivery = () => {
    return ride.rideType === 'delivery' ? 'Delivery' : 'Ride';
  };

  const question = () => {
    let questionText = `Are you sure you want to cancel this ${rideOrDelivery()}? Trips cancelled last minute my incur cancellation fees.`;

    return questionText;
  };

  const [selectedReason, setSelectedReason] = React.useState<null | string>(
    null,
  );
  const [reasonError, setReasonError] = React.useState<string | null>(null);
  const [cancelMode, setCancelMode] = React.useState<'single' | 'batch'>(
    'single',
  );

  const handleCancel = React.useCallback(() => {
    if (!selectedReason && promptForCancellationReason) {
      setReasonError('Select a reason');
    } else {
      if (selectedReason && promptForCancellationReason) {
        onCancel(selectedReason, cancelMode);
      } else if (!promptForCancellationReason) {
        onCancel(null, cancelMode);
      }
    }
  }, [onCancel, selectedReason, promptForCancellationReason, cancelMode]);

  const batchOptionSelect = () => {
    return (
      <>
        <Typography>
          This ride was booked as part of a series. Do you want to cancel:
        </Typography>
        <RadioGroup
          aria-label="cancel-option"
          name="cancel-option"
          onChange={e => setCancelMode(e.target.value as 'single' | 'batch')}
          value={cancelMode}
        >
          <FormControlLabel
            value="single"
            control={<Radio />}
            label="This ride"
          />
          <FormControlLabel
            value="batch"
            control={<Radio />}
            label={`This ride and following rides in this series (${batchRemainingRides})`}
          />
        </RadioGroup>
      </>
    );
  };

  return (
    <Dialog
      className={classes.dialog}
      open={showDialog}
      fullWidth={true}
      maxWidth="sm"
      //@ts-ignore
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {isBatch && !isRebook ? batchOptionSelect() : question()}
        </DialogContentText>
        {cancellationReasonsData && (
          <FormControl error={!!reasonError} className={classes.formControl}>
            <InputLabel id="select-cancellation-reasons-type-label">
              Reason
            </InputLabel>
            <Select
              style={{ animation: '' }}
              autoWidth
              labelId="select-cancellation-reasons-label"
              id="cancellationReasonsId"
              value={selectedReason}
              onChange={event => {
                setSelectedReason(event.target.value as string);
              }}
            >
              {cancellationReasonsData.cancellationReasons?.map(
                cancellationReason => (
                  <MenuItem
                    key={cancellationReason.cancellationReason}
                    value={cancellationReason.id}
                  >
                    {cancellationReason.cancellationReason}
                  </MenuItem>
                ),
              )}
            </Select>
            <FormHelperText>{reasonError}</FormHelperText>
          </FormControl>
        )}
        {cancellationReasonError && (
          <Typography variant="body2">
            An error occurred - please try again later
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {`No, Keep My ${capitalizedRideOrDelivery()}`}
        </Button>
        <Button
          className={classes.cancelButton}
          onClick={handleCancel}
          disabled={cancelDisabled || !!cancellationReasonError}
        >
          {`Yes, Cancel My ${capitalizedRideOrDelivery()}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelConfirmationForm;
