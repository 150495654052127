import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';

type Props = {
  selectedCard: any;
  onRemoveCard: (card: any) => void;
  handleClose: (value: boolean) => void;
  open: boolean;
};

export const RemoveCardDialog: React.FC<Props> = ({
  selectedCard,
  onRemoveCard,
  handleClose,
  open,
}) => {
  return (
    <Dialog aria-labelledby="confirmation-remove-card-dialog-title" open={open}>
      <DialogTitle id="confirmation-remove-card-dialog-title">
        Are you sure?
      </DialogTitle>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary">
          No
        </Button>
        <Button
          onClick={() => {
            if (selectedCard) {
              onRemoveCard(selectedCard);
              handleClose(false);
            }
          }}
          color="primary"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
