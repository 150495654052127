import React from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Controller, Control, UnpackNestedValue } from 'react-hook-form';
import { HeightError, WeightError } from '../ErrorComponents';
import onwardColors from '../../../lib/onwardColors';
import { makeStyles } from '@material-ui/core/styles';
import { maxHeight, maxWeight } from './constants';
import { Maybe, Scalars } from '../../../generated/graphql';
import { getCmFromInches, getKgFromLBS } from './helpers';
import { MetricSwitch } from '../../common/MetricSwitch';

const useStyles = makeStyles(theme => ({
  fareSection: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    width: '95%',
    borderLeftColor: onwardColors.onwardBlue,
    borderLeftWidth: 3,
    borderLeftStyle: 'solid',
    paddingLeft: '15px',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '95%',
  },
  heightContainer: {
    marginTop: theme.spacing(2),
  },
}));

type Props<T> = {
  actionType: 'update' | 'create';
  control: Control;
  errorWeight: any;
  errorHeightInches: any;
  transportType: string;
  watchRide: UnpackNestedValue<T>;
  useMetric: boolean;
  setUseMetric: (value: boolean) => void;
  stairsCount?: Maybe<Scalars['Int']>;
};

export const TransportTypeOptions = <
  T extends {
    weight: number;
    riderWillUseProvidersWheelchair: number;
    heightInches: number;
    heightFeet: number;
    heightInch: number;
    heightCm: number;
  }
>({
  actionType,
  control,
  errorWeight,
  errorHeightInches,
  transportType,
  watchRide,
  useMetric,
  setUseMetric,
  stairsCount,
}: Props<T>) => {
  const classes = useStyles();

  if (transportType === 'rideshare' || transportType === 'companion') {
    return null;
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setUseMetric(checked);
  };

  return (
    <Box className={classes.fareSection}>
      <FormControl component="fieldset" className={classes.formControl}>
        <MetricSwitch checked={useMetric} handleChange={handleChange} />
        <Grid container>
          <Grid item xs={12}>
            <Controller
              disabled={actionType === 'update'}
              name="ride.weight"
              control={control}
              rules={{
                min: 1,
                max: useMetric ? getKgFromLBS(maxWeight) : maxWeight,
                required: true,
              }}
              render={props => {
                return (
                  <>
                    <TextField
                      fullWidth
                      id="riderWeight"
                      defaultValue={
                        useMetric
                          ? getKgFromLBS(watchRide.weight)
                          : watchRide.weight
                      }
                      label={<span>Weight ({useMetric ? 'kg' : 'lbs'})</span>}
                      value={props.value || 0}
                      error={!!errorWeight}
                      helperText={
                        errorWeight ? (
                          <WeightError
                            fieldError={errorWeight}
                            weight={
                              useMetric ? getKgFromLBS(maxWeight) : maxWeight
                            }
                            metric={useMetric ? 'kg' : 'lbs'}
                          />
                        ) : null
                      }
                      onChange={e => {
                        if (e.target.value === '') {
                          props.onChange(e.target.value);
                          return;
                        }

                        if (!Number.isInteger(+e.target.value)) {
                          return;
                        }

                        if (+e.target.value < 1) {
                          return;
                        } else {
                          props.onChange(+e.target.value);
                        }
                      }}
                      // type="number"
                    />
                    {Boolean(watchRide.weight) &&
                      +watchRide.weight >
                        (useMetric ? getKgFromLBS(1000) : 1000) && (
                        <FormHelperText error>
                          Please call 1-800-700-4797
                        </FormHelperText>
                      )}
                  </>
                );
              }}
            />
          </Grid>
        </Grid>
        <Box className={classes.heightContainer}>
          <span>Height</span>
          <Controller
            name="ride.heightInches"
            control={control}
            rules={{ min: 1, max: maxHeight }}
            render={() => null as any}
          />
          <Grid container spacing={1}>
            <Grid
              style={{
                visibility: useMetric ? 'hidden' : 'visible',
                width: useMetric ? 0 : 'auto',
                padding: useMetric ? 0 : 4,
              }}
              item
              xs={useMetric ? false : 6}
            >
              <Controller
                disabled={actionType === 'update'}
                name="ride.heightFeet"
                control={control}
                render={props => {
                  return (
                    <TextField
                      error={
                        !!errorHeightInches &&
                        !watchRide.heightInch &&
                        !watchRide.heightFeet
                      }
                      fullWidth
                      id="riderHeightFeet"
                      label={<span>ft</span>}
                      value={props.value}
                      onChange={e => {
                        if (e.target.value === '') {
                          props.onChange(e.target.value);
                          return;
                        }

                        if (!Number.isInteger(+e.target.value)) {
                          return;
                        }

                        if (+e.target.value < 0) {
                          return;
                        } else {
                          props.onChange(+e.target.value);
                        }
                      }}
                    />
                  );
                }}
              />
            </Grid>

            <Grid
              style={{
                visibility: useMetric ? 'hidden' : 'visible',
                width: useMetric ? 0 : 'auto',
                padding: useMetric ? 0 : 4,
              }}
              item
              xs={useMetric ? false : 6}
            >
              <Controller
                disabled={actionType === 'update'}
                name="ride.heightInch"
                control={control}
                render={props => {
                  return (
                    <TextField
                      error={
                        !!errorHeightInches &&
                        !watchRide.heightInch &&
                        !watchRide.heightFeet
                      }
                      fullWidth
                      id="riderheightInch"
                      label={<span>in</span>}
                      value={props.value}
                      onChange={e => {
                        if (e.target.value === '') {
                          props.onChange(e.target.value);
                          return;
                        }

                        if (!Number.isInteger(+e.target.value)) {
                          return;
                        }
                        if (+e.target.value < 0) {
                          return;
                        } else {
                          props.onChange(+e.target.value);
                        }
                      }}
                    />
                  );
                }}
              />
            </Grid>

            <Grid
              style={{
                visibility: !useMetric ? 'hidden' : 'visible',
                width: !useMetric ? 0 : 'auto',
                padding: !useMetric ? 0 : 4,
              }}
              item
              xs={!useMetric ? false : 6}
            >
              <Controller
                disabled={actionType === 'update'}
                name="ride.heightCm"
                control={control}
                render={props => {
                  return (
                    <TextField
                      error={!!errorHeightInches && !watchRide.heightCm}
                      fullWidth
                      id="riderHeightCm"
                      defaultValue={getCmFromInches(watchRide.heightInches)}
                      label={<span>cm</span>}
                      value={props.value}
                      onChange={e => {
                        if (e.target.value === '') {
                          props.onChange(e.target.value);
                          return;
                        }

                        if (!Number.isInteger(+e.target.value)) {
                          return;
                        }

                        if (+e.target.value < 0) {
                          return;
                        } else {
                          props.onChange(+e.target.value);
                        }
                      }}
                    />
                  );
                }}
              />
            </Grid>

            <Grid style={{ paddingTop: 0 }} item xs={12}>
              {errorHeightInches &&
              !watchRide.heightCm &&
              !watchRide.heightFeet &&
              !watchRide.heightInch ? (
                <HeightError
                  fieldError={errorHeightInches}
                  height={useMetric ? getCmFromInches(maxHeight) : maxHeight}
                  metric={useMetric ? 'cm' : 'in'}
                />
              ) : null}
            </Grid>
          </Grid>
        </Box>
      </FormControl>

      <FormControl component="fieldset" className={classes.formControl}>
        <Controller
          name="ride.stairsCount"
          control={control}
          render={props => {
            return (
              <TextField
                fullWidth
                id="stairsCount"
                value={props.value || 0}
                label={'Stairs'}
                onChange={e => {
                  const value =
                    Number(e.target.value.replace(/[^0-9]/g, '')) || 0;
                  return props.onChange(value);
                }}
              />
            );
          }}
        />
      </FormControl>

      {transportType === 'wheelchair' && (
        <FormControl
          className={classes.formControl}
          // error={!!errors.ride?.rideType}
        >
          <InputLabel id="select-wheelchair-additional">Wheelchair</InputLabel>
          <Controller
            control={control}
            disabled={actionType === 'update'}
            name="ride.riderWillUseProvidersWheelchair"
            rules={{ required: true }}
            as={
              <Select
                labelId="select-wheelchair-additional-label"
                id="rideWheelchairAdditionalSelect"
              >
                <MenuItem
                  selected={watchRide.riderWillUseProvidersWheelchair === 1}
                  value={1}
                >
                  Rider will use Transport Provider’s
                </MenuItem>
                <MenuItem
                  selected={watchRide.riderWillUseProvidersWheelchair === 0}
                  value={0}
                >
                  Rider has their own Wheelchair
                </MenuItem>
              </Select>
            }
          />
        </FormControl>
      )}
    </Box>
  );
};
