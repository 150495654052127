import {
  AppBar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  makeStyles,
  Paper,
  Tab,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FavoriteDriversTab } from './profileTabs/FavoriteDriversTab';
import { PaymentMethodsTab } from './profileTabs/PaymentMethodsTab';
import { ProfileTab } from './profileTabs/ProfileTab';
import AddIcon from '@material-ui/icons/Add';
import {
  RiderProfileQueryQuery,
  RemoveRiderPaymentMethodMutationVariables,
  UpdateRiderPaymentMethodMutationMutationVariables,
  Rider,
} from 'generated/graphql';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { RidesTable } from './profileTabs/RidesTable';
import { useEhrContext } from '../../contexts/ehr-context';
import { useRiderProfileNavigation } from '../../hooks/useRiderProfileNavigation';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  section: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  tabPanel: {
    padding: 0,
  },
  tabIndicator: {
    height: 4,
  },
  actionBar: {
    marginBottom: theme.spacing(2),
  },
}));

export type RiderTab = 'profile' | 'rides' | 'drivers' | 'payments';

export type RiderProfileProps<TData, QueryRiderArgs> = {
  rider?: RiderProfileQueryQuery['rider'] | null;
  defaultTab?: RiderTab | null;
  favoriteDrivers?: RiderProfileQueryQuery['favoriteDrivers'] | null;
  updateError: string | null;
  paymentError?: string | null;
  onUpdate: (data: Partial<Rider>) => void;
  submitting: boolean;
  onRemoveCard?: ({
    paymentMethodId,
    riderId,
  }: RemoveRiderPaymentMethodMutationVariables) => void;
  onUpdatePaymentMethod?: ({
    paymentMethodId,
    riderId,
    paymentMethod,
  }: UpdateRiderPaymentMethodMutationMutationVariables) => void;
  getRiderProfile?:
    | ((
        variables?: Partial<QueryRiderArgs>,
      ) => Promise<ApolloQueryResult<TData>>)
    | undefined;
};

export function RiderProfileForm<
  TData extends RiderProfileQueryQuery,
  QueryRiderArgs
>(props: RiderProfileProps<TData, QueryRiderArgs>): React.ReactElement {
  const {
    rider,
    defaultTab,
    favoriteDrivers,
    updateError,
    paymentError = null,
    onUpdate,
    submitting = false,
    onRemoveCard = () => {},
    onUpdatePaymentMethod = () => {},
    getRiderProfile,
  } = props;
  const {
    generateFromParams,
    generateRedirectUrl,
  } = useRiderProfileNavigation();

  let { riderId } = useParams<{ riderId?: string }>();
  const isNewRider = !riderId;

  const [activeTab, setActiveTab] = useState<RiderTab>(defaultTab ?? 'profile');

  const classes = useStyles();
  const theme = useTheme();

  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { isEhrPatientContext } = useEhrContext();
  const history = useHistory();
  const currentLocationState = history.location.state as
    | { from?: string }
    | undefined;

  // We don't want to display the back button if this is the initial launch from
  // the EHR in a patient context.
  const showBackButton =
    isEhrPatientContext === false ||
    (isEhrPatientContext && currentLocationState?.from !== 'ehr');

  const fromParams = generateFromParams();

  const handleBack = () => {
    const url = generateRedirectUrl(riderId);

    if (url) {
      history.push(url);
    } else {
      const doesAnyHistoryEntryExist = history.location.key !== undefined;

      if (doesAnyHistoryEntryExist) {
        history.goBack();
      } else {
        // Default to going back to the Rider Profiles grid.
        history.push('/riders');
      }
    }
  };

  return (
    <TabContext value={activeTab}>
      <Container disableGutters={smallScreen}>
        <Backdrop className={classes.backdrop} open={submitting}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Box
          display="flex"
          justifyContent={showBackButton ? 'space-between' : 'flex-end'}
          className={classes.actionBar}
        >
          {showBackButton && (
            <Button
              color="primary"
              startIcon={<ArrowBackIcon />}
              onClick={handleBack}
            >
              Back
            </Button>
          )}

          {rider && (
            <Button
              data-testid="new-ride-button"
              color="secondary"
              variant="contained"
              component={Link}
              startIcon={<AddIcon />}
              to={`/rides/new?riderID=${rider.id}`}
            >
              New Ride
            </Button>
          )}
        </Box>
        <Paper className={classes.root}>
          <AppBar position="static">
            {rider && (
              <TabList
                onChange={(e, tab) => {
                  setActiveTab(tab);
                  const urlParams = new URLSearchParams(fromParams);

                  if (tab !== 'profile') {
                    urlParams.set('tab', tab);
                  }

                  history.replace(`/riders/${riderId}?${urlParams}`);
                }}
                variant="fullWidth"
                classes={{ indicator: classes.tabIndicator }}
              >
                <Tab label="Profile" value="profile" />
                <Tab label="Rides" value="rides" />
                <Tab label="Favorite Drivers" value="drivers" />
                <Tab label="Payment Methods" value="payments" />
              </TabList>
            )}
          </AppBar>

          <TabPanel value="drivers" className={classes.tabPanel}>
            {rider && (
              <Container maxWidth="md" disableGutters={smallScreen}>
                <FavoriteDriversTab
                  rider={rider}
                  favoriteDrivers={favoriteDrivers}
                />
              </Container>
            )}
          </TabPanel>

          <TabPanel value="profile" className={classes.tabPanel}>
            <Container maxWidth="md" disableGutters={smallScreen}>
              <ProfileTab
                rider={rider}
                updateError={updateError}
                onUpdate={onUpdate}
                newProfile={isNewRider}
                getRiderProfile={getRiderProfile}
              />
            </Container>
          </TabPanel>

          <TabPanel value="payments" className={classes.tabPanel}>
            <Container maxWidth="md" disableGutters={smallScreen}>
              <PaymentMethodsTab<RiderProfileQueryQuery, QueryRiderArgs>
                onRemoveCard={onRemoveCard}
                onUpdatePaymentMethod={onUpdatePaymentMethod}
                rider={rider}
                errorMessage={paymentError}
                getRiderProfile={getRiderProfile}
              />
            </Container>
          </TabPanel>

          <TabPanel value="rides" className={classes.tabPanel}>
            <RidesTable rider={rider} />
          </TabPanel>
        </Paper>
      </Container>
    </TabContext>
  );
}
