import { MUIDataTableColumnOptions } from 'mui-datatables';
import { useMemo } from 'react';
import { AccountCustodiansPaginationQueryQuery } from '../generated/graphql';

export type AccountCollectionItem = AccountCustodiansPaginationQueryQuery['accountCustodiansCollection']['collection'][number];

type AccountKeys = keyof AccountCollectionItem | 'actions';
export type MUIDataTableColumn = {
  label?: string | undefined;
  name: AccountKeys;
  options?: MUIDataTableColumnOptions | undefined;
};

export const useCSVCustodianData = (
  data: AccountCustodiansPaginationQueryQuery | undefined,
  columns: MUIDataTableColumn[],
  excludedFields?: AccountKeys[],
) => {
  return useMemo(() => {
    const headers: { label: string; key: AccountKeys }[] = [];
    const result: Record<string, any>[] = [];

    if (data) {
      columns.forEach((column, ind) => {
        if (
          column.options?.display !== false &&
          !excludedFields?.includes(column.name)
        ) {
          headers.push({
            label: column.label || column.name,
            key: column.name as AccountKeys,
          });
        }
      });

      data.accountCustodiansCollection.collection.forEach(custodian => {
        let custodianResult: Record<string, any> = {};
        headers.forEach(header => {
          if (header.key === 'id') {
            custodianResult[header.key] = custodian.id;
          }

          if (header.key === 'firstName') {
            custodianResult[header.key] = custodian.firstName;
          }

          if (header.key === 'lastName') {
            custodianResult[header.key] = custodian.lastName;
          }

          if (header.key === 'email') {
            custodianResult[header.key] = custodian.email;
          }

          if (header.key === 'phone') {
            custodianResult[header.key] = custodian.phone;
          }

          if (header.key === 'notifPrefs') {
            const result = custodian.notifPrefs.reduce((acc, current) => {
              return `${acc} ${current}`;
            }, '');
            custodianResult[header.key] = result;
          }

          if (header.key === 'admin') {
            custodianResult[header.key] = custodian.admin ? 'Yes' : 'No';
          }

          if (header.key === 'promptForCancellationReason') {
            custodianResult[header.key] = custodian.promptForCancellationReason
              ? 'Yes'
              : 'No';
          }
        });
        result.push(custodianResult);
      });
    }

    return { result, headers };
  }, [data, columns, excludedFields]);
};
