import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from '@material-ui/core';

export type Props = {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
};

export const ConfirmNotifyDialog: React.FC<Props> = ({
  open,
  handleConfirm,
  handleClose,
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1">
            Your requested start time is within 1 hour. Trips requested with
            short notice may be declined or unfulfilled.
          </Typography>
          <Typography
            style={{
              marginTop: '1rem',
            }}
            variant="body1"
          >
            Would you like to proceed?
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          No
        </Button>
        <Button onClick={handleConfirm} type="submit" color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
