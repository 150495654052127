import {
  AccountQueryQuery,
  Exact,
  RiderProfileQueryQuery,
  Scalars,
  StripeSetupIntentQueryQuery,
} from '../../generated/graphql';
import { QueryLazyOptions } from '@apollo/client';
import { ApolloQueryResult } from '@apollo/client/core/types';
import React, { useRef, useState, useEffect } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button } from '@material-ui/core';

export type StripeFormProps<TData, QueryRiderArgs> = {
  stripeData: StripeSetupIntentQueryQuery | null;
  getSetupIntent: (
    options?:
      | QueryLazyOptions<
          Exact<{ ownerId: Scalars['String']; ownerType: Scalars['String'] }>
        >
      | undefined,
  ) => void;
  rider?: RiderProfileQueryQuery['rider'] | null;
  account?: AccountQueryQuery['account'] | null;
  getAccount?:
    | ((
        variables?: Partial<QueryRiderArgs>,
      ) => Promise<ApolloQueryResult<TData>>)
    | undefined;
  ownerType: 'Rider' | 'Account';
  setButtonRef?: (element: HTMLButtonElement) => void;
  shouldShowAddButton?: boolean;
  setFormReadyCb?: (ready: boolean) => void;
  shouldGetSetupIntent?: boolean;
};

export function StripeForm<TData, QueryRiderArgs = any>(
  props: StripeFormProps<TData, QueryRiderArgs>,
) {
  const {
    stripeData,
    getAccount,
    getSetupIntent,
    rider,
    ownerType,
    account,
    setButtonRef,
    shouldShowAddButton,
    setFormReadyCb,
    shouldGetSetupIntent = true,
  } = props;

  const [errorMessage, setErrorMessage] = useState<string | null | undefined>(
    null,
  );
  const [formReady, setFormReady] = useState(false);
  const [creating, setCreating] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const card = elements?.getElement('card');
    if (
      !stripe ||
      !elements ||
      !card ||
      !stripeData?.stripeSetupIntent?.setupIntent
    ) {
      return;
    }
    setCreating(true);

    const { error } = await stripe.confirmCardSetup(
      stripeData?.stripeSetupIntent?.setupIntent,
      {
        payment_method: {
          card,
        },
      },
    );
    if (error) {
      setErrorMessage(error.message);
    } else {
      setTimeout(() => {
        getAccount?.();
      }, 1000);
    }
    setCreating(false);

    let options = undefined;
    if (rider) {
      options = { variables: { ownerId: rider.id, ownerType } };
    } else if (account) {
      options = { variables: { ownerId: account.id, ownerType } };
    }
    if (shouldGetSetupIntent) {
      getSetupIntent(options);
    }
  };

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (buttonRef.current) {
      setButtonRef?.(buttonRef.current);
    }
  }, [formReady, setButtonRef]);

  return (
    <form>
      <CardElement
        options={{
          hidePostalCode: true,
          style: {
            base: {
              color: '#32325d',
              fontFamily: 'Arial, sans-serif',
              fontSmoothing: 'antialiased',
              fontSize: '16px',
              '::placeholder': {
                color: '#32325d',
              },
            },
            invalid: {
              fontFamily: 'Arial, sans-serif',
              color: '#fa755a',
              iconColor: '#fa755a',
            },
          },
        }}
        onChange={event => {
          if (event.complete) {
            setFormReady(true);
            setFormReadyCb?.(true);
          }
        }}
      />
      <Button
        style={{
          visibility: !!shouldShowAddButton && formReady ? 'visible' : 'hidden',
          pointerEvents: !!shouldShowAddButton && formReady ? 'auto' : 'none',
        }}
        // @ts-ignore
        ref={buttonRef}
        onClick={handleSubmit}
        color="primary"
        title="Add Payment Method"
        disabled={!stripe || creating}
      >
        Add Payment Method
      </Button>
      {errorMessage && (
        <div
          style={{ color: '#D26262', fontSize: '1.1rem', fontWeight: 'bold' }}
        >
          {errorMessage}
        </div>
      )}
    </form>
  );
}
