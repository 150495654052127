import React from 'react';
import { useStyles } from './styles';
import { RideTimeErrorProps } from './props';
import { Alert } from '@material-ui/lab';

const _RideTimeError: React.FC<RideTimeErrorProps> = ({
  error,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Alert severity="error">{error}</Alert>
    </div>
  );
};

export const RideTimeError = React.memo(
  _RideTimeError,
);
