import { RideHistoryTable } from 'components/rideHistory/RideHistoryTable';
import { useTracking } from 'lib/analytics/Tracker';
import { useEffect, useState } from 'react';
import MainLayout from 'components/layout/MainLayout';
import { RidesCollectionScope } from '../generated/graphql';
import { SnackBarWrapper } from '../components/snackBarWrapper/SnackBarWrapper';
import { useSessionContext } from '../contexts/session-context';

function RideHistoryContainer() {
  const { session } = useSessionContext();
  const tracker = useTracking();

  useEffect(() => {
    tracker.track('Ride History Viewed');
  }, [tracker]);

  const [updateFn, setUpdateFn] = useState<(() => void) | undefined>(undefined);

  return (
    <MainLayout>
      {session ? (
        <>
          <SnackBarWrapper
            scope={RidesCollectionScope.History}
            update={updateFn}
          />
          <RideHistoryTable
            updateRidesHistory={setUpdateFn}
            session={session}
          />
        </>
      ) : null}
    </MainLayout>
  );
}

export default RideHistoryContainer;
