export const DashboardFilterLables = {
  start_time: 'Start Time',
  rider: 'Rider',
  status: 'Status',
  custodian: 'Custodian',
  type: 'Type',
  transport_type: 'Transport Type',
  ride_type: 'Ride Type',
  cost: 'Cost',
  distance: 'Distance',
  duration: 'Duration',
  ride_details: 'Ride Details',
  driver: 'Driver',
  start_location: 'Start Location',
  end_location: 'End Location',
  start_date: 'Start Date',
  end_date: 'End Date',
  min_cost: 'Min Cost',
  max_cost: 'Max Cost',
};