import { useContext, createContext, useState, ReactNode } from 'react';
import { ApolloClient } from '@apollo/client';
import {
  deleteIsFromReactNative,
  deleteMagicCustodianId,
  readIsFromReactNative,
  readMagicCustodianId,
  writeIsFromReactNative,
  writeMagicCustodianId,
} from '@onwardcare/core';
import {
  OnwardToken,
  deleteToken,
  getToken,
  isTokenExpired,
  setToken,
} from '../auth/onward-token';
import { clearAllStorages } from '../storages/storage';
import { useVetridesContext } from './vetrides-context';

const AuthContext = createContext<
  | {
      logout: (
        apolloClient: ApolloClient<object>,
        onLogout?: () => void,
      ) => void;
      storeBecomeCustodian: (
        apolloClient: ApolloClient<object>,
        custodian: { id: number },
      ) => void;
      removeBecomeCustodian: (apolloClient: ApolloClient<object>) => void;
      storeHeadersFromQuery: () => void;
      getAuthRequestHeaders: () => HeadersInit;
      isUsingMagic: boolean;
      isLoggedIn: boolean;
      storeAuthHeaders: (headers: OnwardToken, localIp?: string) => void;
      setFromRN: (fromRn: boolean) => void;
      getFromRN: () => boolean;
    }
  | undefined
>(undefined);

export default function AuthProvider({ children }: { children: ReactNode }) {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthContext');
  }

  return context;
};

function useAuthProvider() {
  const hasValidToken = (token: OnwardToken | null) => {
    const authAccessToken = token?.accessToken;

    return authAccessToken &&
      authAccessToken.length > 0 &&
      isTokenExpired(token?.expiry) === false
      ? true
      : false;
  };

  const loggedIn = () => {
    const token = getToken();
    return hasValidToken(token);
  };

  const calculateIsUsingMagic = () => {
    const authBecomeCustodianId = readMagicCustodianId();
    return !!authBecomeCustodianId;
  };

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(loggedIn());
  const [isUsingMagic, setIsUsingMagic] = useState(calculateIsUsingMagic());
  const { vetrideRequestId } = useVetridesContext();

  const clearCache = async (apolloClient: ApolloClient<object>) => {
    await apolloClient.cache.reset();
  };

  const logout = (
    apolloClient: ApolloClient<object>,
    onLogout?: () => void,
  ) => {
    deleteToken();
    deleteMagicCustodianId();
    deleteIsFromReactNative();

    setIsLoggedIn(false);
    setIsUsingMagic(false);
    clearAllStorages();

    if (apolloClient) {
      clearCache(apolloClient);
    }

    onLogout?.();
  };

  const getAuthRequestHeaders = () => {
    const token = getToken();
    const fromRN = readIsFromReactNative();

    const headers: HeadersInit = {
      'access-token': token?.accessToken || '',
      client: token?.client || '',
      uid: token?.uid || '',
      'token-type': 'Bearer',
    };

    const authBecomeCustodianId = readMagicCustodianId();

    if (token) {
      if (vetrideRequestId) {
        headers['vetride-request-id'] = vetrideRequestId.toString();
      } else if (authBecomeCustodianId) {
        headers['become-custodian'] = authBecomeCustodianId;
      }
    }

    if (fromRN) {
      headers['REQUEST_ORIGIN'] = 'RIDER_APP';
    }

    return headers;
  };

  const storeHeadersFromQuery = () => {
    const params = new URLSearchParams(window.location.search);
    const expiry = params.get('expiry') || '';
    const client = params.get('client') || '';
    const uid = params.get('uid') || '';
    const userId = Number(params.get('userId') || 0);
    const accessToken = params.get('access-token') || '';

    storeAuthHeaders({ expiry, client, uid, accessToken, id: userId });
  };

  const storeAuthHeaders = (headers: OnwardToken, localIp?: string) => {
    setToken(headers, localIp);
    setIsLoggedIn(true);
  };

  const setFromRN = (fromRn: boolean) => {
    writeIsFromReactNative(fromRn);
  };

  const getFromRN = () => {
    return readIsFromReactNative() as boolean;
  };

  const storeBecomeCustodian = (
    apolloClient: ApolloClient<object>,
    custodian: { id: number },
  ) => {
    clearCache(apolloClient);
    writeMagicCustodianId(custodian.id);
    clearAllStorages();
    setIsUsingMagic(calculateIsUsingMagic());
  };

  const removeBecomeCustodian = (apolloClient: ApolloClient<object>) => {
    clearCache(apolloClient);
    deleteMagicCustodianId();
    clearAllStorages();
    setIsUsingMagic(calculateIsUsingMagic());
  };

  return {
    logout,
    storeBecomeCustodian,
    removeBecomeCustodian,
    storeHeadersFromQuery,
    getAuthRequestHeaders,
    isUsingMagic,
    isLoggedIn,
    storeAuthHeaders,
    setFromRN,
    getFromRN,
  };
}
