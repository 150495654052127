import { MouseEvent, ReactNode, useState } from 'react';
import { Tooltip, Button } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  title: ReactNode;
  onHover?: boolean;
  icon?: ReactNode;
};

const useStyles = makeStyles(theme => ({
  button: {
    minWidth: 0,
    padding: 0,
  },
  tooltip: {
    backgroundColor: theme.palette.grey[800],
    fontSize: '0.75rem',
  },
}));

export const TooltipButton = ({
  title,
  onHover = false,
  icon,
  ...rest
}: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpen(true);
  };

  const Icon = icon ?? <InfoIcon fontSize="small" color="primary" />;

  return (
    <>
      {title && (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            onClose={handleTooltipClose}
            open={open}
            {...rest}
            title={title}
            classes={{
              tooltip: classes.tooltip,
            }}
          >
            <Button
              className={classes.button}
              onMouseEnter={onHover ? handleTooltipOpen : () => {}}
              onClick={onHover ? () => {} : handleTooltipOpen}
            >
              {Icon}
            </Button>
          </Tooltip>
        </ClickAwayListener>
      )}
    </>
  );
};
