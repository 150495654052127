/**
 * Writes a string to session storage.
 *
 * @param key The session storage key.
 * @param value The string value to write to session storage.
 */
export function writeSession(key: string, value: string) {
  window.sessionStorage.setItem(key, value);
}

/**
 * Writes a JSON object to session storage.
 *
 * @param key The session storage key.
 * @param value The JSON object to write to session storage.
 */
export function writeSessionObject(key: string, value: object) {
  writeSession(key, JSON.stringify(value));
}

/**
 * Reads a string from session storage.
 *
 * @param key The session storage key.
 * @returns Returns the string value from session storage.
 */
export function readSession(key: string): string | null {
  return window.sessionStorage.getItem(key);
}

/**
 * Reads a JSON object from session storage.
 *
 * @param key The session storage key.
 * @returns Returns the JSON object from session storage.
 */
export function readSessionObject(key: string): object | null {
  const value = readSession(key);
  return value ? JSON.parse(value) : null;
}

/**
 * Deletes a value from session storage.
 *
 * @param key The session storage key.
 */
export function deleteSession(key: string) {
  window.sessionStorage.removeItem(key);
}
