import React from 'react';
import Chip from '@material-ui/core/Chip';
import onwardColors from 'lib/onwardColors.js';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    color: onwardColors.onwardWhite,
    height: '20px',
  },
  oneway: { backgroundColor: onwardColors.onwardGreen },
  delivery: { backgroundColor: onwardColors.onwardMediumBlue },
  roundtrip: { backgroundColor: onwardColors.onwardBlue },
  recurring: { backgroundColor: onwardColors.onwardMedPurple },
});

const RideTypeBadge = ({ rideType }) => {
  const classes = useStyles();

  const rideTypeColor = () => {
    switch (rideType) {
      case 'oneway':
        return classes.oneway;
      case 'delivery':
        return classes.delivery;
      case 'roundtrip':
        return classes.roundtrip;
      case 'recurring':
        return classes.delivery;
      case 'wav':
        return null;
      default:
        return null;
    }
  };

  const rideTypeText = () => {
    switch (rideType) {
      case 'oneway':
        return 'ONE WAY';
      case 'delivery':
        return 'DELIVERY';
      case 'roundtrip':
        return 'WAIT AND RETURN';
      case 'wav':
        return 'WAV';
      case 'recurring':
        return 'RECURRING';
      default:
        return 'ONE WAY';
    }
  };

  return (
    <Chip
      size="small"
      className={[classes.root, rideTypeColor()].join(' ')}
      label={rideTypeText()}
    />
  );
};

export default RideTypeBadge;
