import React, { createContext, useContext, useState, useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  progressContainer: {
    flex: 1,
  },
}));

const LoadingContext = createContext({
  loading: false,
  setLoading: () => {},
});

export function useLoading() {
  const { loading, setLoading } = useContext(LoadingContext);
  return [loading, setLoading];
}

export function useLoadingEffect(loading) {
  const [, setLoading] = useLoading();
  useEffect(() => {
    setLoading(loading);
  });
}

export function LoadingProvider({ children }) {
  const [loading, setLoading] = useState(false);
  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      {children}
    </LoadingContext.Provider>
  );
}

export function Loading() {
  const { loading } = useContext(LoadingContext);

  const classes = useStyles();
  if (!loading) {
    return null
  }
  return (
    (
      <div className={classes.progressContainer}>
        <LinearProgress color="secondary" />
      </div>
    )
  );
}
