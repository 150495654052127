import { makeStyles } from '@material-ui/core/styles';
import onwardColors from '../../../../lib/onwardColors';

const useStyles = makeStyles(theme => ({
  fareSection: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    width: '95%',
    borderLeftColor: onwardColors.onwardBlue,
    borderLeftWidth: 3,
    borderLeftStyle: 'solid',
    paddingLeft: '15px',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '95%',
  },
  heightContainer: {
    marginTop: theme.spacing(2),
  },
}));

export default useStyles;