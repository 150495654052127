import React, { useEffect, useRef, useState } from 'react';
import {
  Rider,
  useAccountCustodiansQueryLazyQuery,
} from '../../../generated/graphql';
import { Box, Button, makeStyles } from '@material-ui/core';
import onwardColors from '../../../lib/onwardColors';
import AvatarUpload from '../../AvatarUpload';
import { UpcomingRidesTable } from '../../dashboard/UpcomingRidesTable';
import { RideHistoryTable } from '../../rideHistory/RideHistoryTable';
import { useSessionContext } from '../../../contexts/session-context';

type Props = {
  rider: Rider | undefined | null;
};

const useStyles = makeStyles(theme => ({
  avatarUpload: {
    marginTop: theme.spacing(2),
  },
  rider: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  riderInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '1em',
  },
  rideType: {
    display: 'flex',
    justifyContent: 'end',
    margin: '10px 0px',
  },
  rideTypeSelected: {
    color: onwardColors.onwardDarkPurple,
  },
  rideTypeNotSelected: {
    color: onwardColors.onwardDarkGray,
  },
}));

export const RidesTable: React.FC<Props> = ({ rider }) => {
  const { session } = useSessionContext();
  const classes = useStyles();

  const [
    getCustodians,
    { data: dataCustodians },
  ] = useAccountCustodiansQueryLazyQuery();

  const updateUpcomingRides = useRef<(() => void) | undefined>(undefined);
  const updateHistoryRides = useRef<(() => void) | undefined>(undefined);

  useEffect(() => {
    getCustodians();
  }, [getCustodians]);

  const [selectedTab, setSelectedTab] = useState<'history' | 'upcoming'>(
    'upcoming',
  );

  return (
    <div>
      <div className={classes.rider}>
        <Box className={classes.avatarUpload}>
          {/*@ts-ignore */}
          <AvatarUpload person={rider} showButton={false} />
        </Box>
        <div className={classes.riderInfo}>
          <h2>
            {rider?.firstName} {rider?.lastName}
          </h2>
          {rider?.dob && <p>DOB: {rider.dob}</p>}
        </div>
      </div>
      <div className={classes.rideType}>
        <Button
          variant="contained"
          style={{
            color: 'white',
            margin: '0px 10px',
            backgroundColor:
              selectedTab === 'upcoming'
                ? onwardColors.onwardMedPurple
                : onwardColors.onwardLightGray,
          }}
          onClick={() => setSelectedTab('upcoming')}
        >
          Upcoming Rides
        </Button>
        <Button
          variant="contained"
          style={{
            color: 'white',
            margin: '0px 10px',
            backgroundColor:
              selectedTab === 'history'
                ? onwardColors.onwardMedPurple
                : onwardColors.onwardLightGray,
          }}
          onClick={() => setSelectedTab('history')}
        >
          Ride History
        </Button>
      </div>

      {selectedTab === 'upcoming' ? (
        <UpcomingRidesTable
          dataCustodians={dataCustodians}
          riderId={rider?.id}
          showSingleRider={true}
          updateRides={(updateFn: () => void) => {
            updateUpcomingRides.current = updateFn;
          }}
        />
      ) : (
        session && (
          <RideHistoryTable
            riderId={rider?.id}
            session={session}
            showSingleRider={true}
            updateRidesHistory={(updateFn: () => void) => {
              updateHistoryRides.current = updateFn;
            }}
          />
        )
      )}
    </div>
  );
};
