import React from 'react';
import Chip from '@material-ui/core/Chip';
import onwardColors from 'lib/onwardColors.js';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  transport_type_companion: {
    backgroundColor: onwardColors.onwardMedPurple,
  },
  transport_type_rideshare: {
    backgroundColor: onwardColors.onwardLightGray,
  },
  transport_type_gurney: {
    backgroundColor: onwardColors.onwardDarkPurple,
  },
  transport_type_wheelchair: {
    backgroundColor: onwardColors.onwardLightBlue,
  },
  transport_type_bls_ambulance: {
    backgroundColor: onwardColors.onwardLightPurple,
  },
  transport_type_cct_ambulance: {
    backgroundColor: onwardColors.onwardMedPurple,
  },
  transport_type_als_ambulance: {
    backgroundColor: onwardColors.onwardVelvetCosmos,
  },
  root: {
    color: onwardColors.onwardWhite,
    height: '20px',
  },
});

const TransportTypeBadge = ({ transportType }) => {
  const classes = useStyles();

  const transportTypeClass = classes[`transport_type_${transportType?.name}`];
  return (
    <Chip
      size="small"
      className={[classes.root, transportTypeClass].join(' ')}
      label={transportType?.fullName?.toUpperCase()}
    />
  );
};

export default TransportTypeBadge;
