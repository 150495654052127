import { Grid, TextField } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { Control, Controller } from 'react-hook-form';
import { DATE_PICKER_FORMAT, updateDateWithTimeString } from '@onwardcare/core';
import { differenceInMinutes } from 'date-fns';
import { useSessionContext } from '../../contexts/session-context';
import TimeZoneTimeText from '../TimeZoneTimeText';
import { RideTimeHelper } from './rideFormComponents/RideTimeHelper/RideTimeHelper';

const DROPOFF_START_TIME_DIFF = 30;

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '95%',
  },
  formTimeControl: {
    margin: theme.spacing(1),
    width: '95%',
    alignItems: 'flex-start',
  },
  startDay: {
    marginRight: theme.spacing(2),
  },
  rideTimeHelper: {
    textAlign: 'right',
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(1),
    },
  },
}));

export const RideTime = ({
  control,
  defaultValues,
  firstAvailable,
  flexibleRide,
  rideType,
  requestedStartTime,
  startTime,
  endTime,
  startLocation,
  endLocation,
  bufferTime,
  errors,
  pickupTimeUpdated,
}: {
  control: Control<any>;
  // TODO: Get type
  defaultValues: any;
  firstAvailable: boolean;
  flexibleRide: boolean;
  rideType: string;
  requestedStartTime: Date;
  startTime: string;
  endTime: string;
  // TODO: Get type
  startLocation: any;
  // TODO: Get type
  endLocation: any;
  bufferTime?: number;
  // TODO: Get type
  errors: any;
  pickupTimeUpdated: (
    arrivalBufferMinutes: number,
    time: string,
    date: Date | null,
  ) => void;
}) => {
  const classes = useStyles();
  const { session } = useSessionContext();
  const timeZone = session?.account?.tzName ?? undefined;

  if (firstAvailable) {
    return null;
  }

  return (
    <>
      <Grid container className={classes.formTimeControl}>
        <Grid item xs={7} sm={5}>
          <Controller
            className={classes.startDay}
            control={control}
            name="ride.requestedStartTime"
            rules={{ required: true }}
            render={({ ref, ...rest }) => (
              <DatePicker
                id="requestedStartTime"
                autoOk
                label="Pickup Day"
                disablePast
                format={DATE_PICKER_FORMAT}
                error={!!errors.ride?.requestedStartTime}
                helperText={
                  errors.ride?.requestedStartTime && 'Pickup day is required'
                }
                {...rest}
              />
            )}
          />
        </Grid>

        {!flexibleRide && (
          <Grid item xs={5} sm={4}>
            <Controller
              control={control}
              name="startTime"
              rules={{ required: true }}
              render={({ ref, ...rest }) => (
                <TextField
                  id="startTime"
                  label="Pickup Time"
                  type="time"
                  style={{ width: '100%' }}
                  error={!!errors.startTime}
                  helperText={errors.startTime && 'Pickup time is required'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  {...rest}
                />
              )}
            />
            <TimeZoneTimeText
              date={requestedStartTime || defaultValues.ride.requestedStartTime}
              time={startTime}
              timeZone={timeZone}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={3} className={classes.rideTimeHelper}>
          <RideTimeHelper
            defaultDate={requestedStartTime}
            defaultTime={startTime}
            disabled={!startLocation || !endLocation || !requestedStartTime}
            ride={control.getValues().ride}
            handleSubmit={(buffer, time, date) =>
              pickupTimeUpdated(buffer, time, date)
            }
            bufferMinutes={bufferTime}
            isFlexibleRide={flexibleRide}
          />
        </Grid>
      </Grid>

      {rideType === 'roundtrip' && (
        <Grid container className={classes.formControl}>
          <Grid item xs={7} sm={5}></Grid>
          <Grid item xs={5} sm={4}>
            <Controller
              control={control}
              name="endTime"
              rules={{
                required: true,
                validate: e => {
                  const endTime = updateDateWithTimeString(new Date(), e);
                  const startDateTime = updateDateWithTimeString(
                    new Date(),
                    startTime,
                  );
                  const duration = differenceInMinutes(endTime, startDateTime);

                  return duration > DROPOFF_START_TIME_DIFF;
                },
              }}
              render={({ ref, ...rest }) => (
                <TextField
                  id="endTime"
                  label="Dropoff Time"
                  type="time"
                  style={{ width: '100%' }}
                  error={!!errors.endTime}
                  helperText={
                    errors.endTime &&
                    ((errors.endTime.type === 'required' &&
                      'Dropoff time is required') ||
                      (errors.endTime.type === 'validate' &&
                        `Dropoff time must be after pickup time at least ${DROPOFF_START_TIME_DIFF} minutes`))
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  {...rest}
                />
              )}
            />
            <TimeZoneTimeText
              date={requestedStartTime || defaultValues.ride.requestedStartTime}
              time={endTime}
              timeZone={timeZone}
            />
          </Grid>
          <Grid item xs={12} sm={3}></Grid>
        </Grid>
      )}
    </>
  );
};
