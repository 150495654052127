import React from 'react';
import { Control, Controller } from 'react-hook-form';
import onwardColors from '../../../../lib/onwardColors';
import {
  Box,
  FormControl,
  makeStyles,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { LabelWithTooltip } from 'components/LabelWithTooltip';
import { Ride } from '../../../../generated/graphql';

type Options = {
  contactPrecautionsRequired: Ride['contactPrecautionsRequired'];
  oxygenRequired: Ride['oxygenRequired'];
};

type Props = {
  control: Control;
  disabled: boolean;
  options: Options;
};

type ConvertedNames = {
  name: string;
  value: 'contactPrecautionsRequired' | 'oxygenRequired';
  tooltipText?: string;
};

const useStyles = makeStyles(theme => ({
  fareSection: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    width: '95%',
    borderLeftColor: onwardColors.onwardBlue,
    borderLeftWidth: 3,
    borderLeftStyle: 'solid',
    paddingLeft: '15px',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '95%',
  },
  heightContainer: {
    marginTop: theme.spacing(2),
  },
}));

const convertName = (option: keyof Options) => {
  const name = (option.charAt(0).toUpperCase() + option.slice(1))
    .split(/(?=[A-Z])/)
    .join(' ');

  switch (name) {
    case 'Oxygen Required':
      return 'Transport to Provide Oxygen';
    case 'Contact Precautions Required':
      return 'Droplet Precautions Required';
    default:
      return name;
  }
};

const getOptionNames = (options: Options): ConvertedNames[] => {
  return Object.keys(options)
    .map(k => {
      const key = (k as unknown) as keyof Options;
      const option = options[key];

      if (typeof option === 'boolean' && option) {
        const data: ConvertedNames = { name: convertName(key), value: key };

        // This might be something we want to return from the API rather than creating it in the UI.
        if (key === 'oxygenRequired') {
          data.tooltipText =
            'Check this box to indicate that the provider must be able to supply and administer oxygen to the rider. Do not check this box if the rider has their own self-administered oxygen and equipment.';
        }

        return data;
      }

      return false;
    })
    .filter(d => typeof d === 'object') as ConvertedNames[];
};

const _TransportTypeFeeOptionsComponent: React.FC<Props> = ({
  control,
  options,
  disabled,
}) => {
  const classes = useStyles();

  if (
    options['contactPrecautionsRequired'] === false &&
    options['oxygenRequired'] === false
  ) {
    return null;
  }

  return (
    <Box className={classes.fareSection}>
      <FormControl component="fieldset" className={classes.formControl}>
        {getOptionNames(options).map(element => {
          const optionValue = options ? !!options[element.value] : true;
          if (!optionValue) {
            return null;
          }

          return (
            <FormControlLabel
              key={element.name}
              control={
                <Controller
                  name={`ride.${element.value}`}
                  control={control}
                  render={props => {
                    return (
                      <Checkbox
                        disabled={disabled}
                        onChange={e => {
                          props.onChange(e.target.checked);
                        }}
                        checked={!!props.value}
                      />
                    );
                  }}
                />
              }
              label={
                element.tooltipText ? (
                  <LabelWithTooltip
                    labelText={element.name}
                    tooltipText={element.tooltipText}
                  />
                ) : (
                  <span style={{ paddingTop: 3 }}>{element.name}</span>
                )
              }
            />
          );
        })}
      </FormControl>
    </Box>
  );
};

export const TransportTypeFeeOptionsComponent = React.memo(
  _TransportTypeFeeOptionsComponent,
);
