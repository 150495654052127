import React from 'react';
import { FormHelperText } from '@material-ui/core';
import { FieldError } from 'react-hook-form';

interface WeightErrorProps {
  fieldError: FieldError | undefined;
  weight?: number;
  metric?: 'lbs' | 'kg';
}

export const WeightError: React.FC<WeightErrorProps> = ({
  fieldError,
  weight = 1000,
  metric,
}) => {
  if (!fieldError) {
    return null;
  }

  if (fieldError.type === 'max') {
    return (
      <FormHelperText error>
        Weight should be less than {weight} {metric}
      </FormHelperText>
    );
  }

  return <FormHelperText error>Weight should be set</FormHelperText>;
};

interface HeightErrorProps {
  fieldError: FieldError | undefined;
  height?: number;
  metric?: 'in' | 'cm';
}

export const HeightError: React.FC<HeightErrorProps> = ({
  fieldError,
  height = 108,
  metric = 'in',
}) => {
  if (!fieldError) {
    return null;
  }

  if (fieldError.type === 'max') {
    return (
      <FormHelperText error>
        Height should be less than {height} {metric}
      </FormHelperText>
    );
  }

  return <FormHelperText error>Height should be set</FormHelperText>;
};
