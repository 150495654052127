import { useEffect, useState } from 'react';
import PageLoadingSpinner from '../components/ehr/PageLoadingSpinner';
import { useHistory } from 'react-router-dom';
import ENV from '../constants/Env';
import { captureMessage } from '../lib/error-handler';
import { OnwardToken, getToken } from '../auth/onward-token';
import { useEhrContext } from '../contexts/ehr-context';
import { ensureErrorIsJson } from '../lib/json-error';

const { apiUrl } = ENV;

type PageState = 'loading';

/**
 * Once we have a custodian for the EHR user, we land on this page. From here,
 * we will call off to different FHIR endpoints and store that information in
 * the database so that we can use it to help populate fields on the Book
 * Transportation page.
 */
export default function LaunchFhir() {
  const { ehrSession, ehrToken, fhirClient } = useEhrContext();
  const history = useHistory();
  const [authToken, setAuthToken] = useState<OnwardToken | null>(null);
  const state: PageState = 'loading';

  useEffect(() => {
    const token = getToken();

    if (!token) {
      captureMessage(`[Epic EHR] - Launch FHIR - No Onward auth token found`);
    }

    setAuthToken(token);
  }, []);

  useEffect(() => {
    if (!authToken || !fhirClient || !ehrSession || !ehrToken) {
      return;
    }

    const saveFhirResponse = async (
      serviceName: string,
      resourceId: string | null,
      searchQuery: string | null,
      responseBody: object | string | null,
      isErrorResponse?: boolean,
    ) => {
      const bodyData = {
        serviceName,
        resourceId,
        searchQuery,
        responseBody,
        isErrorResponse: isErrorResponse === true,
      };
      const response = await fetch(
        `${apiUrl}/api/ehr/sessions/${ehrSession.ehrSessionId}/fhir_request`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Token': authToken.accessToken,
            Client: authToken.client,
            Uid: authToken.uid,
          },
          body: JSON.stringify(bodyData),
        },
      );

      const data = await response.json();

      if (!response.ok) {
        captureMessage(
          `[Epic EHR] - Launch FHIR - Error saving ${serviceName} FHIR response for session id '${
            ehrSession.ehrSessionId
          }': ${JSON.stringify(data)}`,
        );
      }
    };

    const patientFhirRequest = async () => {
      try {
        const patientResponse = await fhirClient.patient.read();
        return saveFhirResponse(
          'Patient.Read',
          fhirClient.patient.id,
          null,
          patientResponse,
        );
      } catch (error) {
        captureMessage(`[Epic EHR] - Launch FHIR - Error with Patient.Read`);
        return saveFhirResponse(
          'Patient.Read',
          fhirClient.patient.id,
          null,
          ensureErrorIsJson(error),
          true,
        );
      }
    };

    const encounterFhirRequest = async () => {
      if (fhirClient.encounter.id === null) {
        // No need to call off to the Encounter endpoint if we don't have an
        // encounter ID.
        return;
      }

      try {
        const encounterResponse = await fhirClient.encounter.read();
        return saveFhirResponse(
          'Encounter.Read',
          fhirClient.encounter.id,
          null,
          encounterResponse,
        );
      } catch (error) {
        captureMessage(`[Epic EHR] - Launch FHIR - Error with Encounter.Read`);
        return saveFhirResponse(
          'Encounter.Read',
          fhirClient.encounter.id,
          null,
          ensureErrorIsJson(error),
          true,
        );
      }
    };

    const conditionFhirRequest = async () => {
      const conditionQuery = new URLSearchParams();
      conditionQuery.set('_count', '10'); // Number of results per page
      conditionQuery.set('category', 'encounter-diagnosis');
      if (fhirClient.encounter.id) {
        conditionQuery.set('encounter', fhirClient.encounter.id);
      }

      try {
        const conditionResponse = await fhirClient.patient.request(
          'Condition?' + conditionQuery,
          {
            flat: true, // return flat array of resources
          },
        );
        return saveFhirResponse(
          'Condition.Search',
          fhirClient.patient.id,
          conditionQuery.toString(),
          conditionResponse,
        );
      } catch (error) {
        captureMessage(
          `[Epic EHR] - Launch FHIR - Error with Condition.Search`,
        );
        return saveFhirResponse(
          'Condition.Search',
          fhirClient.patient.id,
          conditionQuery.toString(),
          ensureErrorIsJson(error),
          true,
        );
      }
    };

    const coverageFhirRequest = async () => {
      try {
        const coverageResponse = await fhirClient.patient.request('Coverage', {
          flat: true, // return flat array of resources
        });
        return saveFhirResponse(
          'Coverage.Search',
          fhirClient.patient.id,
          null,
          coverageResponse,
        );
      } catch (error) {
        captureMessage(`[Epic EHR] - Launch FHIR - Error with Coverage.Search`);
        return saveFhirResponse(
          'Coverage.Search',
          fhirClient.patient.id,
          null,
          ensureErrorIsJson(error),
          true,
        );
      }
    };

    const flagFhirRequest = async () => {
      const flagQuery = new URLSearchParams();
      flagQuery.set('_count', '10'); // Number of results per page
      flagQuery.set('category', 'isolation');

      try {
        const flagResponse = await fhirClient.patient.request(
          'Flag?' + flagQuery,
          {
            flat: true, // return flat array of resources
          },
        );
        return saveFhirResponse(
          'Flag.Search',
          fhirClient.patient.id,
          flagQuery.toString(),
          flagResponse,
        );
      } catch (error) {
        captureMessage(`[Epic EHR] - Launch FHIR - Error with Flag.Search`);
        return saveFhirResponse(
          'Flag.Search',
          fhirClient.patient.id,
          flagQuery.toString(),
          ensureErrorIsJson(error),
          true,
        );
      }
    };

    const observationHeightFhirRequest = async () => {
      const observationQuery = new URLSearchParams();
      observationQuery.set('_count', '10'); // Number of results per page
      // Doesn't seem to work with Epic sandbox, but we'll try it out with
      // Honor Health and see if it works there.
      observationQuery.set('_sort', '-date');
      // Doesn't seem to work with Epic sandbox, but we'll try it out with
      // Honor Health and see if it works there.
      observationQuery.set('status', 'final');
      observationQuery.set(
        'code',
        [
          'http://loinc.org|8302-2', // Body height
          // 'http://loinc.org|8306-3', // Body height --lying
        ].join(','),
      );

      try {
        const observationResponse = await fhirClient.patient.request(
          'Observation?' + observationQuery,
          {
            flat: true, // return flat array of resources
          },
        );
        return saveFhirResponse(
          'Observation.Search',
          fhirClient.patient.id,
          observationQuery.toString(),
          observationResponse,
        );
      } catch (error) {
        captureMessage(
          `[Epic EHR] - Launch FHIR - Error with Observation.Search (Height)`,
        );
        return saveFhirResponse(
          'Observation.Search',
          fhirClient.patient.id,
          observationQuery.toString(),
          ensureErrorIsJson(error),
          true,
        );
      }
    };

    const observationWeightFhirRequest = async () => {
      const observationQuery = new URLSearchParams();
      observationQuery.set('_count', '10'); // Number of results per page
      // Doesn't seem to work with Epic sandbox, but we'll try it out with
      // Honor Health and see if it works there.
      observationQuery.set('_sort', '-date');
      // Doesn't seem to work with Epic sandbox, but we'll try it out with
      // Honor Health and see if it works there.
      observationQuery.set('status', 'final');
      observationQuery.set(
        'code',
        [
          'http://loinc.org|29463-7', // Body weight
          // 'http://loinc.org|3141-9', // Body weight measured
        ].join(','),
      );

      try {
        const observationResponse = await fhirClient.patient.request(
          'Observation?' + observationQuery,
          {
            flat: true, // return flat array of resources
          },
        );
        return saveFhirResponse(
          'Observation.Search',
          fhirClient.patient.id,
          observationQuery.toString(),
          observationResponse,
        );
      } catch (error) {
        captureMessage(
          `[Epic EHR] - Launch FHIR - Error with Observation.Search (Weight)`,
        );
        return saveFhirResponse(
          'Observation.Search',
          fhirClient.patient.id,
          observationQuery.toString(),
          ensureErrorIsJson(error),
          true,
        );
      }
    };

    // We need to use `allSettled` instead of `all` because if any of the FHIR
    // promises fail above, we don't want to stop the rest of the promises from
    // running. The reason being, we are not dependent on all the calls to be
    // successful to continue to the next step.
    Promise.allSettled([
      patientFhirRequest(),
      encounterFhirRequest(),
      conditionFhirRequest(),
      coverageFhirRequest(),
      flagFhirRequest(),
      observationHeightFhirRequest(),
      observationWeightFhirRequest(),
    ]).then(() => {
      // For now, we want to continue on to the next step even if one of the
      // FHIR requests fails. All the additional data is optional and just
      // a convenience to populate for users.
      history.push('/launch/rider');
    });
  }, [authToken, fhirClient, ehrSession, history, ehrToken]);

  return <>{state === 'loading' && <PageLoadingSpinner />}</>;
}
