import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import onwardColors from 'lib/onwardColors';

const useStyles = makeStyles(theme => ({
  icon: {
    color: onwardColors.onwardBrightGreen,
  },
  text: {
    color: onwardColors.onwardBrightGreen,
    marginLeft: theme.spacing(0.5),
  },
}));

const InProgressIndicator = () => {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="center">
      <CircularProgress className={classes.icon} size={16} />
      <Typography className={classes.text} variant="subtitle2">
        In Progress
      </Typography>
    </Box>
  );
};

export default InProgressIndicator;
