/* global google */
import { Box, makeStyles, Typography } from '@material-ui/core';
import PinIcon from '@material-ui/icons/Room';
import { timeOnly } from '@onwardcare/core';
import GoogleMapReact from 'google-map-react';
import onwardColors from 'lib/onwardColors';
import { memo, useCallback, useRef, useState, useEffect } from 'react';
import ENV from '../constants/Env';

const useStyles = makeStyles(theme => ({
  marker: {
    position: 'absolute',
    transform: 'translate(-50%, -100%)',
  },
  pinDescription: {
    backgroundColor: '#fff',
    width: '90px',
    padding: theme.spacing(0.5),
    textAlign: 'center',
  },
}));

const Marker = ({ text, time, tzName }) => {
  const classes = useStyles();
  let convertedDate = null;

  if (time) {
    convertedDate = typeof time === 'string' ? new Date(time) : time;
  }

  return (
    <Box
      className={classes.marker}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box
        className={classes.pinDescription}
        display="flex"
        flexDirection="column"
      >
        <Typography variant="button">{text}</Typography>
        {convertedDate && tzName && (
          <Typography variant="caption">
            {timeOnly(convertedDate, { timeZone: tzName })}
          </Typography>
        )}
      </Box>
      <PinIcon fontSize="large" color="secondary" />
    </Box>
  );
};

const Map = memo(
  ({
    startLocation,
    endLocation,
    startTime,
    endTime,
    tzName,
    center = { latitude: 37.84073, longitude: -122.251691 },
    height = '100%',
  }) => {
    const [map, setMap] = useState(null);
    const [maps, setMaps] = useState(null);
    const polylineRef = useRef(null);

    const updateMap = useCallback(() => {
      if (!maps || !map) return;

      if (startLocation && endLocation) {
        const directionsService = new maps.DirectionsService();
        const directionsDisplay = new maps.DirectionsRenderer();
        directionsService.route(
          {
            origin: new maps.LatLng(
              startLocation.latitude,
              startLocation.longitude,
            ),
            destination: new maps.LatLng(
              endLocation.latitude,
              endLocation.longitude,
            ),
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (directions, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              if (polylineRef.current) {
                polylineRef.current.setMap(null);
              }
              directionsDisplay.setOptions(directions);
              const path = directions.routes[0].overview_path;
              const routePolyline = new maps.Polyline({
                path,
                strokeColor: onwardColors.onwardBlue,
              });
              routePolyline.setMap(map);
              polylineRef.current = routePolyline;

              fitPathBounds(map, maps, path);
            } else {
              console.error(`error fetching directions ${directions}`);
            }
          },
        );
      } else if (startLocation || endLocation) {
        if (polylineRef.current) {
          polylineRef.current.setMap(null);
        }
        fitLocationBounds(map, maps, startLocation || endLocation);
      }
    }, [endLocation, map, maps, startLocation]);

    useEffect(() => {
      if (map && maps) {
        updateMap();
      }
    }, [map, maps, startLocation, endLocation, updateMap]);

    const apiHasLoaded = (map, maps) => {
      setMap(map);
      setMaps(maps);
      updateMap();
    };

    const fitPathBounds = (map, maps, path) => {
      const bounds = new maps.LatLngBounds();
      path.forEach(position => bounds.extend(position));
      map.fitBounds(bounds, { top: 80 });
    };

    const fitLocationBounds = (map, maps, location) => {
      const bounds = new maps.LatLngBounds();
      bounds.extend(new maps.LatLng(location.latitude, location.longitude));
      map.fitBounds(bounds);
    };

    return (
      <div style={{ height, width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: ENV.mapsApiKey }}
          yesIWantToUseGoogleMapApiInternals
          center={{
            lat: (startLocation || endLocation || center).latitude,
            lng: (startLocation || endLocation || center).longitude,
          }}
          radius={160000}
          defaultZoom={14}
          onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
        >
          {startLocation && (
            <Marker
              lat={startLocation.latitude}
              lng={startLocation.longitude}
              time={startTime}
              tzName={tzName}
              text="Pickup"
            />
          )}
          {endLocation && (
            <Marker
              lat={endLocation.latitude}
              lng={endLocation.longitude}
              time={endTime}
              tzName={tzName}
              text="Dropoff"
            />
          )}
        </GoogleMapReact>
      </div>
    );
  },
);

export default Map;
