import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 10,
    position: 'relative',
  },
  content: {
    maxWidth: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  formTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  formBody: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(3),
    width: '100%',
  },

  formRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
  },
  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginTop: theme.spacing(2),
    position: 'relative',
  },
  label: {
    marginRight: theme.spacing(1),
    whiteSpace: 'nowrap',
  },

  input: {
    width: '100%',
  },
  formButton: {
    alignSelf: 'flex-end',
  },
  iconContainer: {
    marginLeft: theme.spacing(1),
    position: 'absolute',
    right: -28,
    top: 18,
  },
  formError: {
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 500,
    maxWidth: '100%',
  },

  bottomBar: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  bottomBarContent: {
    display: 'flex',
    alignItems: 'center',
  },
  bottomBarTextContainer: {
    marginLeft: theme.spacing(1),
    color: 'gray',
  },
  bodyText: {
    textAlign: 'center',
  },
  card: {
    paddingTop: 20,
    paddingLeft: 35,
    paddingRight: 40,
    paddingBottom: 20,
    minHeight: 500,
    width: '100%',
  },
  phoneCall: {
    color: 'gray',
  },
}));
