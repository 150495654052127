import {
  AppBar,
  Box,
  Button,
  Container,
  makeStyles,
  Paper,
  Tab,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Alert, TabContext, TabList, TabPanel } from '@material-ui/lab';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { InformationTab } from './settingsTabs/InformationTab';
import { PaymentsTab } from './settingsTabs/PaymentsTab';
import {
  AccountQueryQuery,
  RemoveAccountPaymentMethodMutationVariables,
  UpdateAccountMutationMutationVariables,
  UpdateAccountPaymentMethodMutationMutationVariables,
} from '../../generated/graphql';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { CoordinatorTabTable } from './settingsTabs/CoordinatorTabTable';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  tabPanel: {
    padding: 0,
  },
  tabIndicator: {
    height: 4,
  },
  alert: {
    marginTop: theme.spacing(2),
  },
}));

export type AccountSettingsFormProps<TData> = {
  account: AccountQueryQuery['account'];
  profile: AccountQueryQuery['profile'];
  errorMessage: string | null;
  onRemoveCard: (
    paymentMethodId: RemoveAccountPaymentMethodMutationVariables['paymentMethodId'],
  ) => void;
  onUpdateAccount: (
    data: UpdateAccountMutationMutationVariables['account'],
  ) => void;
  onUpdatePaymentMethod: ({
    paymentMethodId,
    paymentMethod,
  }: UpdateAccountPaymentMethodMutationMutationVariables) => void;
  getAccount: (() => Promise<ApolloQueryResult<TData>>) | undefined;
};

export function AccountSettingsForm<TData>(
  props: AccountSettingsFormProps<TData>,
): React.ReactElement {
  const {
    account,
    errorMessage,
    onRemoveCard,
    onUpdateAccount,
    onUpdatePaymentMethod,
    getAccount,
    profile,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState('account');

  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();

  return (
    <TabContext value={activeTab}>
      <Container maxWidth="md" disableGutters={smallScreen}>
        <Button
          color="primary"
          startIcon={<ArrowBackIcon />}
          onClick={() => history.goBack()}
        >
          Back
        </Button>
        <Paper className={classes.root}>
          <AppBar position="static">
            <TabList
              onChange={(e, tab) => setActiveTab(tab)}
              variant="fullWidth"
              classes={{ indicator: classes.tabIndicator }}
            >
              <Tab label="Account Information" value="account" />
              <Tab label="Payment Methods" value="payments" />
              {account?.channel === 'b2b' && profile?.admin ? (
                <Tab label="Manage Coordinators" value="coordinators" />
              ) : (
                <></>
              )}
            </TabList>
          </AppBar>
          {errorMessage && (
            <Box className={classes.alert}>
              <Alert severity="error">{errorMessage}</Alert>
            </Box>
          )}

          <TabPanel value="account" className={classes.tabPanel}>
            <InformationTab
              account={account}
              onUpdateAccount={onUpdateAccount}
            />
          </TabPanel>

          <TabPanel value="payments" className={classes.tabPanel}>
            <PaymentsTab
              account={account}
              onRemoveCard={onRemoveCard}
              onUpdatePaymentMethod={onUpdatePaymentMethod}
              getAccount={getAccount}
            />
          </TabPanel>
          {account?.channel === 'b2b' && profile?.admin && (
            <TabPanel value="coordinators" className={classes.tabPanel}>
              <CoordinatorTabTable account={account} />
            </TabPanel>
          )}
        </Paper>
      </Container>
    </TabContext>
  );
}
