import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { camelCase, cloneDeep } from 'lodash';
import {
  convertLocalToISOInTimeZone,
  normalizePhone,
  timeOnly,
  updateDateWithTimeString,
} from '@onwardcare/core';

import LayoutWithQuery from 'components/layout/LayoutWithQuery';
import { RideForm } from 'components/rides/RideForm';
import { useTracking } from 'lib/analytics/Tracker';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  CreateRideMutationDocument,
  CreateRideMutationMutation,
  CreateRideMutationMutationVariables,
  DashboardQueryDocument,
  FieldError,
  Maybe,
  MncsPromptField,
  RecentLocationsQueryDocument,
  ScheduleQueryDocument,
  ScheduleQueryQueryVariables,
  ScheduleQueryWithRideDocument,
  ScheduleQueryWithRideQuery,
  ScheduleQueryWithRideQueryVariables,
  useVetrideQueryLazyQuery,
} from 'generated/graphql';
import { ApolloError } from '@apollo/client/errors';
import { RideFormType, RideExtended } from 'pages/types';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useVetridesContext } from '../contexts/vetrides-context';
import { getLBSFromKg } from '../components/rides/rideFormComponents/helpers';
import { readIsMetric } from '../storages/is-metric-storage';
import { addHours, isFuture, roundToNearestMinutes } from 'date-fns';
import { useSessionContext } from '../contexts/session-context';

export function CreateRide() {
  const [createError, setCreateError] = useState<string>('');
  const [submitting, setSubmitting] = useState(false);
  const [showSnackbar, toggleSnackbar] = useState(false);
  const [rideErrorsState, setRideErrors] = useState<Maybe<Array<FieldError>>>();
  const [defaultRide, setDefaultRide] = useState<RideFormType | null>(null);
  const [isReturnTrip, setIsReturnTrip] = useState(false);
  const tracker = useTracking();
  const isDefaultRide = useRef<RideFormType | null>(null);
  const { isVetride, vetrideRequestId } = useVetridesContext();
  const { session } = useSessionContext();
  const timeZone = session?.account?.tzName ?? undefined;

  const [getVetrides, { data: vetrides, loading }] = useVetrideQueryLazyQuery();

  useEffect(() => {
    if (isVetride && vetrideRequestId) {
      getVetrides({ variables: { vetrideRequestId: vetrideRequestId } });
    }
  }, [isVetride, getVetrides, vetrideRequestId]);

  const history = useHistory();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  // Determines whether we should make a return trip based on the ride that
  // comes back from the API call.
  const makeReturnTripQueryParam = urlParams.get('makeReturnTrip') === 'true';
  const riderIdQueryParam = urlParams.get('riderID') ?? undefined;
  const { rideId, riderId: riderIdRouteParam } = useParams<{
    rideId?: string;
    riderId?: string;
  }>();
  // The rider id can come from the query param or the route param, so take one.
  const riderId = useRef<string | undefined>(undefined);

  if (riderIdQueryParam || riderIdRouteParam) {
    riderId.current = riderIdQueryParam || riderIdRouteParam;
  }

  if (urlParams.has('riderID')) {
    // We need to remove the `riderID` query param so that they are able to
    // select another rider from the dropdown. Otherwise, it will keep
    // selecting the rider that matches the `riderID` query param.
    urlParams.delete('riderID');
    history.replace({
      search: urlParams.toString(),
    });
  }

  useEffect(() => {
    tracker.track('Create Ride Viewed');
  }, [tracker]);

  const dashBoardAndLocation = useMemo(() => {
    const dashBoardQuery = getOperationName(DashboardQueryDocument);
    const resentLocationQuery = getOperationName(RecentLocationsQueryDocument);
    return {
      dashBoardQuery,
      resentLocationQuery,
    };
  }, []);

  const onRideCreated = (data: CreateRideMutationMutation) => {
    const error = data.createRide?.error;

    const rideErrors = data.createRide?.rideErrors;

    if (error) {
      onRideCreateError(error);
      const camelRideErrors = rideErrors?.map(rError => ({
        ...rError,
        field: camelCase(rError.field),
      }));
      setRideErrors(camelRideErrors);
    } else {
      localStorage.removeItem('transportationForm');
      if (isDefaultRide.current) {
        makeReturnTrip();
        isDefaultRide.current = null;
        setSubmitting(false);
      } else {
        history.replace({ pathname: '/rides/' + data?.createRide?.ride?.id });
      }
    }
  };

  const onRideCreateError = (error?: ApolloError | string) => {
    if (error) {
      setSubmitting(false);
      const errorMessage =
        typeof error === 'string' &&
        (error.includes('Requested start time') ||
          error.includes('Requested end time') ||
          error.includes('Zip code') ||
          error.includes('Phone'))
          ? error
          : 'There was an error booking your ride, see details below. If you still require booking assistance, contact Onward at 1-800-700-4797.';
      setCreateError(errorMessage);
    }
  };

  const rideToPersist = (data: Omit<RideFormType, 'isReturnTrip'>) => {
    const { ride, startTime, endTime, appointmentTimeTransportReason } = data;
    const isMetric = readIsMetric();

    const {
      notes,
      passengerCount,
      rideType,
      deliverySubstitution,
      startLocation,
      endLocation,
      riderId,
      draft,
      paymentMethodId,
      requestedStartTime: startDay,
      transportType,
      stairsCount,
      heightInches,
      patient,
      payerType,
      weight: weightLbsResult,
      startLocationInstructions,
      startLocationPhoto,
      endLocationInstructions,
      endLocationPhoto,
      riderWillUseProvidersWheelchair,
      dropoffRoomNumber,
      pickupRoomNumber,
      costCenter,
      contactPrecautionsRequired,
      oxygenRequired,
      firstAvailable,
      flexibleRide,
      transportReasonComment,
      transportReason,
      arrivalBufferMinutes,
    } = ride as RideExtended;

    const weightLbs = isMetric
      ? weightLbsResult && getLBSFromKg(weightLbsResult)
      : weightLbsResult;

    const updatedStartLocation = {
      ...startLocation,
      instructions: startLocationInstructions,
    };
    // We pass the photo on the root of the ride.
    delete updatedStartLocation.locationPhoto;

    const updatedEndLocation = {
      ...endLocation!,
      instructions: endLocationInstructions,
    };
    // We pass the photo on the root of the ride.
    delete updatedEndLocation.locationPhoto;

    // Flexible ride hides the time component, removing its value,
    // while the day component remains unchanged, so we use the startDay value
    const requestedStartTime = flexibleRide
      ? convertLocalToISOInTimeZone(startDay, timeZone)
      : startTime &&
        convertLocalToISOInTimeZone(
          updateDateWithTimeString(startDay, startTime),
          timeZone,
        );

    const requestedEndTime =
      endTime &&
      convertLocalToISOInTimeZone(
        updateDateWithTimeString(startDay, endTime),
        timeZone,
      );

    // TODO: This appointment time code isn't actually in use. We either need to
    // delete this code or find a way to make it show up to test.
    const appointmentTime = appointmentTimeTransportReason?.startAppointmentTime
      ? convertLocalToISOInTimeZone(
          updateDateWithTimeString(
            appointmentTimeTransportReason.startAppointmentDay,
            appointmentTimeTransportReason.startAppointmentTime,
          ),
          timeZone,
        )
      : null;

    return {
      requestedStartTime,
      requestedEndTime,
      notes,
      passengerCount: passengerCount || 1,
      rideType,
      startLocation: updatedStartLocation,
      startLocationPhoto,
      endLocation: updatedEndLocation,
      endLocationPhoto,
      riderId,
      deliverySubstitution,
      draft,
      paymentMethodId,
      transportType,
      stairsCount,
      heightInches,
      weightLbs,
      patient: {
        ...patient,
        firstName: patient?.firstName || '',
        lastName: patient?.lastName || '',
        phone: normalizePhone(patient?.phone) || '',
      },
      payerType,
      costCenter,
      // @ts-ignore
      riderWillUseProvidersWheelchair: riderWillUseProvidersWheelchair === 1,
      dropoffRoomNumber,
      pickupRoomNumber,
      contactPrecautionsRequired,
      oxygenRequired,
      firstAvailable,
      flexibleRide,
      vetrideRequestId,
      transportReasonComment,
      transportReasonId: transportReason?.id,
      appointmentTime,
      arrivalBufferMinutes,
    };
  };

  const [createRide] = useMutation<
    CreateRideMutationMutation,
    CreateRideMutationMutationVariables
  >(CreateRideMutationDocument, {
    // we're using an array of strings instead of objects as this method reuses the variables,
    // which is good for recent locations
    refetchQueries:
      dashBoardAndLocation.resentLocationQuery &&
      dashBoardAndLocation.dashBoardQuery
        ? [
            dashBoardAndLocation.resentLocationQuery,
            dashBoardAndLocation.dashBoardQuery,
          ]
        : undefined,
    onCompleted: onRideCreated,
    onError: onRideCreateError,
  });

  const onCreate = async (data: RideFormType) => {
    const { isReturnTrip, mncsOptions, bedsideNurse, ...rest } = data;
    if (isReturnTrip) {
      isDefaultRide.current = data;
    } else {
      isDefaultRide.current = null;
    }

    tracker.track('Ride Booked', { rideType: data.ride.rideType });
    setSubmitting(true);

    await createRide({
      variables: {
        ride: rideToPersist(rest),
        isReturnTrip: !!isReturnTrip,
        recurringOptions: data.recurringOptions,
        mncsOptions,
        bedsideNurse: {
          fullName: bedsideNurse?.fullName || '',
          // sending only numbers to a BE
          phone: normalizePhone(bedsideNurse?.phone) || '',
        },
      },
    });
  };

  /**
   * This is for making a return trip on a ride that was just created. We need
   * to be sure to copy some of the form fields that are stored outside of the
   * `ride` property.
   */
  const makeReturnTrip = () => {
    if (isDefaultRide.current) {
      const ride = cloneDeep(isDefaultRide.current.ride);
      const { pickupRoomNumber, dropoffRoomNumber } = ride;

      const startLocation = cloneDeep(ride.startLocation);
      const endLocation = cloneDeep(ride.endLocation);
      // @ts-ignore
      const endLocationInstructions = ride.endLocationInstructions;
      const endLocationPhoto = ride.endLocationPhoto;
      // @ts-ignore
      const startLocationInstructions = ride.startLocationInstructions;
      const startLocationPhoto = ride.startLocationPhoto;

      if (startLocation && endLocation) {
        isDefaultRide.current.ride.startLocation = {
          ...endLocation,
          instructions: endLocationInstructions,
        };
        isDefaultRide.current.ride.endLocation = {
          ...startLocation,
          instructions: startLocationInstructions,
        };
        // @ts-ignore
        isDefaultRide.current.ride.startLocationInstructions =
          endLocationInstructions;
        isDefaultRide.current.ride.startLocationPhoto = endLocationPhoto;
        // @ts-ignore
        isDefaultRide.current.ride.endLocationInstructions =
          startLocationInstructions;
        isDefaultRide.current.ride.endLocationPhoto = startLocationPhoto;

        isDefaultRide.current.ride.dropoffRoomNumber = pickupRoomNumber ?? null;
        isDefaultRide.current.ride.pickupRoomNumber = dropoffRoomNumber ?? null;
      }
    }

    if (isDefaultRide.current?.startTime) {
      isDefaultRide.current.startTime = timeOnly(
        addHours(
          updateDateWithTimeString(new Date(), isDefaultRide.current.startTime),
          1,
        ),
        { timeZone, timeFormat: '24' },
      );
    }

    if (isDefaultRide.current?.endTime) {
      isDefaultRide.current.endTime = timeOnly(
        addHours(
          updateDateWithTimeString(new Date(), isDefaultRide.current.endTime),
          1,
        ),
        { timeZone, timeFormat: '24' },
      );
    }

    setIsReturnTrip(true);
    setDefaultRide(isDefaultRide.current);
    toggleSnackbar(true);
  };

  /**
   * This is for making a return trip on a ride that has already been booked and
   * you come here from the ride details page.
   */
  const makeReturnTripFromRide = (
    rideToReturn: ScheduleQueryWithRideQuery['ride'],
  ) => {
    if (!rideToReturn) {
      return rideToReturn;
    }

    const clonedRide = cloneDeep(rideToReturn);
    const startLocation = cloneDeep(clonedRide.startLocation);
    const endLocation = cloneDeep(clonedRide.endLocation);
    const {
      startLocationPhoto,
      endLocationPhoto,
      pickupRoomNumber,
      dropoffRoomNumber,
    } = clonedRide;

    if (startLocation && endLocation) {
      clonedRide.startLocation = {
        ...endLocation,
      };
      clonedRide.endLocation = {
        ...startLocation,
      };
      clonedRide.startLocationPhoto = endLocationPhoto;
      clonedRide.endLocationPhoto = startLocationPhoto;
      clonedRide.dropoffRoomNumber = pickupRoomNumber ?? null;
      clonedRide.pickupRoomNumber = dropoffRoomNumber ?? null;
    }

    const roundingOptions = { nearestTo: 15, roundingMethod: 'ceil' };

    if (clonedRide.requestedStartTime) {
      const startTime = new Date(clonedRide.requestedStartTime);

      // If the start time is in the future, add one hour to the start time. If
      // it's in the past, use one hour from now. We also want to round up to
      // the nerest 15 minutes.
      if (isFuture(startTime)) {
        clonedRide.requestedStartTime = roundToNearestMinutes(
          addHours(startTime, 1),
          roundingOptions,
        ).toISOString();
      } else {
        clonedRide.requestedStartTime = roundToNearestMinutes(
          addHours(new Date(), 1),
          roundingOptions,
        ).toISOString();
      }
    }

    if (clonedRide.requestedEndTime) {
      const endTime = new Date(clonedRide.requestedEndTime);

      // If the end time is in the future, add one hour to the end time. If
      // it's in the past, use two hours from now. We also want to round up to
      // the nerest 15 minutes.
      if (isFuture(endTime)) {
        clonedRide.requestedEndTime = roundToNearestMinutes(
          addHours(endTime, 1),
          roundingOptions,
        ).toISOString();
      } else {
        clonedRide.requestedEndTime = roundToNearestMinutes(
          addHours(new Date(), 2),
          roundingOptions,
        ).toISOString();
      }
    }

    return clonedRide;
  };

  const handleCloseSnackbar = (_: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }

    toggleSnackbar(false);
  };

  const variables = rideId
    ? {
        riderId: riderId.current ? parseInt(riderId.current) : undefined,
        rideId: parseInt(rideId),
      }
    : riderId.current
    ? { riderId: parseInt(riderId.current) }
    : undefined;

  if (loading) {
    return null;
  }

  return (
    <LayoutWithQuery<
      ScheduleQueryWithRideQuery,
      ScheduleQueryWithRideQueryVariables | ScheduleQueryQueryVariables
    >
      usePadding="no"
      query={rideId ? ScheduleQueryWithRideDocument : ScheduleQueryDocument}
      variables={variables}
      withRefetch
      renderView={(data, refetch) => {
        const vetride = vetrides?.vetrideRequest?.newRide;
        const vetrider = vetrides?.vetrideRequest?.rider;

        let defaultRideProp = undefined;
        let mncsDefaultAnswers = {};

        if (vetride) {
          defaultRideProp = { ...vetride };
          // Only populate the from the loaded ride if they are not doing a
          // return trip. If they are doing a return trip, it would load the
          // data for the ride they are copying from rather than the logic above
          // that swaps the locations when doing a return trip.
        } else if (data?.ride && isReturnTrip === false) {
          const ride = makeReturnTripQueryParam
            ? makeReturnTripFromRide(data.ride)
            : data.ride;
          defaultRideProp = { ...ride };

          if (data?.ride.medicalNecessityCertificationStatement?.mncsAnswers) {
            mncsDefaultAnswers =
              data?.ride.medicalNecessityCertificationStatement.mncsAnswers?.reduce(
                (acc, curr) => {
                  if (curr) {
                    // @ts-ignore
                    acc[curr.slug] =
                      curr.fieldType === MncsPromptField.Boolean
                        ? curr.value === 'true'
                        : curr.value;
                  }

                  return acc;
                },
                {},
              );
          }
        } else if (defaultRide?.ride) {
          defaultRideProp = {
            ...(defaultRideProp || {}),
            ...defaultRide?.ride,
          };
        }

        return (
          <>
            <RideForm
              rideErrorsState={rideErrorsState}
              riders={data?.riders}
              createError={createError}
              onSubmit={onCreate}
              isSubmitting={submitting}
              // @ts-ignore
              defaultRide={defaultRideProp}
              recurringOptions={defaultRide?.recurringOptions}
              defaultRiderId={vetrider?.id || riderId.current}
              actionType="create"
              useDefaultRideRequestedStartTime={
                makeReturnTripQueryParam || !!vetride
              }
              getAccountData={refetch}
              isReturnTrip={
                !!defaultRide?.isReturnTrip || makeReturnTripQueryParam
              }
              mncsDefaultAnswers={mncsDefaultAnswers}
            />
            <Snackbar
              open={showSnackbar}
              autoHideDuration={10000}
              onClose={handleCloseSnackbar}
            >
              <>
                <MuiAlert
                  style={{ alignItems: 'flex-start' }}
                  onClose={() => handleCloseSnackbar('', '')}
                  icon={false}
                  elevation={6}
                  variant="filled"
                  severity="success"
                >
                  Transportation Booked - Your From and To destinations have
                  been pre-populated. Please adjust the time as needed then save
                  your return trip
                </MuiAlert>
              </>
            </Snackbar>
          </>
        );
      }}
    />
  );
}
