import { useEffect, useState } from 'react';
import PageLoadingSpinner from '../components/ehr/PageLoadingSpinner';
import { useHistory } from 'react-router-dom';
import ENV from '../constants/Env';
import EhrErrorMessage from 'components/ehr/EhrErrorMessage';
import DebugInformation from 'components/ehr/DebugInfo';
import { captureMessage } from '../lib/error-handler';
import { useAuth } from '../contexts/auth-context';
import { getToken } from '../auth/onward-token';
import {
  readEhrSession,
  saveEhrSession,
  TokenResponse,
  useEhrContext,
} from '../contexts/ehr-context';

const { apiUrl } = ENV;

type PageState =
  | 'loading'
  | 'auth'
  | 'invalidApp'
  | 'missingContextFields'
  | 'missingEmail'
  | 'success';

/**
 * From the `Launcher` page, the EHR will redirect to this page once we are
 * authorized with the EHR session. At that point, we will make a request to get
 * the custodian for the EHR user.
 */
export default function LaunchSession() {
  const { ehrSession, setEhrSession, ehrToken } = useEhrContext();
  const history = useHistory();
  const auth = useAuth();
  const [state, setState] = useState<PageState>('loading');
  const [missingFields, setMissingFields] = useState<string[]>([]);

  const missingRequiredFields = (tokenResponse: TokenResponse) => {
    if (!tokenResponse.userEmail) {
      setState('missingEmail');
      captureMessage('[Epic EHR] - Launch Session - Missing email');
      return true;
    }

    if (tokenResponse.patient === undefined || tokenResponse.patient === '') {
      // Not in a patient context, so we don't need to check for patient fields.
      return false;
    }

    const requiredFields = [
      'patientFirstName',
      'patientLastName',
      'patientDOB',
    ];
    const missing: string[] = [];

    requiredFields.forEach(field => {
      if (!Object.keys(tokenResponse || {}).includes(field)) {
        missing.push(field);
      }
    });

    if (missing.length > 0) {
      setState('missingContextFields');
      setMissingFields(missing);
      captureMessage(
        `[Epic EHR] - Launch Session - Missing patient context fields: ${missing.join(
          ', ',
        )}`,
      );
      return true;
    }

    return false;
  };

  useEffect(() => {
    // We need to read this from session storage and then set it in the context
    // now that the EHR has finished the redirect.
    const session = readEhrSession();

    if (!session) {
      setState('auth');
      captureMessage(
        '[Epic EHR] - Launch Session - Missing EHR Session in session storage',
      );
      return;
    }

    setEhrSession(session);
  }, [setEhrSession]);

  useEffect(() => {
    if (!ehrToken || !ehrSession) {
      return;
    }

    ehrSession.ehrAccessToken = ehrToken;
    // TODO: Should we be spreading over the session here to cause a re-render?
    //       Example: setEhrSession({ ...ehrSession });
    saveEhrSession(ehrSession);
    setEhrSession(ehrSession);

    if (missingRequiredFields(ehrToken)) {
      return;
    }

    const bodyData = { ...ehrSession };
    const headers: HeadersInit = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    let authToken = getToken();
    const { accessToken, client, uid } = authToken || {};

    if (auth.isLoggedIn && accessToken && client && uid) {
      captureMessage(
        '[Epic EHR] - Launch Session - The user already has an active Onward session',
      );
      headers['Access-Token'] = accessToken;
      headers.Client = client;
      headers.Uid = uid;
    }

    const createSession = async () => {
      const response = await fetch(`${apiUrl}/api/ehr/sessions`, {
        method: 'POST',
        headers,
        body: JSON.stringify(bodyData),
      });

      if (response.ok) {
        setState('success');
        captureMessage(
          '[Epic EHR] - Launch Session - Successfully authenticated with Onward',
        );
        const accessToken = response.headers.get('access-token');
        const client = response.headers.get('client');
        const expiry = response.headers.get('expiry');
        const uid = response.headers.get('uid');
        const { data } = await response.json();
        const { custodian_id, id } = data;

        if (id) {
          ehrSession.ehrSessionId = id;
          // TODO: Should we be spreading over the session here to cause a re-render?
          //       Example: setEhrSession({ ...ehrSession });
          saveEhrSession(ehrSession);
          setEhrSession(ehrSession);
        }

        if (accessToken && client && expiry && custodian_id && uid) {
          const token = { accessToken, client, expiry, id: custodian_id, uid };
          auth.storeAuthHeaders(token);
        }

        if (ehrToken.patient === undefined || ehrToken.patient === '') {
          history.push('/');
        } else {
          history.push('/launch/fhir');
        }
      } else {
        if (response.status === 422) {
          captureMessage(
            `[Epic EHR] - Launch Session - Error with FHIR App and Client ID combo (App ID: '${ehrSession.fhirAppId}', Client ID: '${ehrSession.fhirAppClientId}')`,
          );
          setState('invalidApp');
        } else {
          captureMessage(
            `[Epic EHR] - Launch Session - Error authenticating user '${ehrToken?.userEmail}' with Onward`,
          );
          setState('auth');
        }
      }
    };

    createSession();
    // We don't want to include `auth` in the dependency check here because after
    // we call the API to create the token, we call the `auth.storeAuthHeaders`
    // function, which actually causes `auth` to change and trigger this `useEffect`
    // again.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ehrSession, ehrToken, history, setEhrSession]);

  return (
    <>
      {(state === 'loading' || state === 'success') && <PageLoadingSpinner />}

      {state === 'missingEmail' && (
        <EhrErrorMessage>
          <EhrErrorMessage.Title>Email address missing</EhrErrorMessage.Title>
          <EhrErrorMessage.Body>
            <p>
              Onward requires an email address for the logged in user and it is
              missing from the launch context.
            </p>

            <DebugInformation tokenResponse={ehrToken} />
          </EhrErrorMessage.Body>
        </EhrErrorMessage>
      )}

      {state === 'missingContextFields' && (
        <EhrErrorMessage>
          <EhrErrorMessage.Title>
            Launch context is missing fields
          </EhrErrorMessage.Title>
          <EhrErrorMessage.Body>
            <p>
              You are launching Onward in the context of a patient, but there
              are missing fields in the launch context.
            </p>

            {ehrSession?.testSession && (
              <>
                <p>The following fields are missing from the launch context:</p>

                <ul>
                  {missingFields.map(field => (
                    <li key={field}>{field}</li>
                  ))}
                </ul>
              </>
            )}

            <DebugInformation tokenResponse={ehrToken} />
          </EhrErrorMessage.Body>
        </EhrErrorMessage>
      )}

      {state === 'auth' && (
        <EhrErrorMessage>
          <EhrErrorMessage.Title>Not authorized</EhrErrorMessage.Title>
          <EhrErrorMessage.Body>
            <p>We were unable to authorize you with Onward.</p>

            <DebugInformation tokenResponse={ehrToken} />
          </EhrErrorMessage.Body>
        </EhrErrorMessage>
      )}

      {state === 'invalidApp' && (
        <EhrErrorMessage>
          <EhrErrorMessage.Title>Not authorized</EhrErrorMessage.Title>
          <EhrErrorMessage.Body>
            <p>
              We were unable to authorize you with Onward. The FHIR App ID and
              Client ID combination is not valid.
            </p>

            <DebugInformation tokenResponse={ehrToken} />
          </EhrErrorMessage.Body>
        </EhrErrorMessage>
      )}
    </>
  );
}
