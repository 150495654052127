export type AccountPageType = {
  title: string;
  body: string;
};

export type AccountPageResponse =
  | {
      success: false;
      error: string;
    }
  | {
      success: true;
      error: false;
      page: AccountPageType;
    };

export type AccountPageData =
  | {
      error: string;
    }
  | {
      error: undefined;
      page: AccountPageType;
    };

export type SuccessEmailResponse = {
  success: true;
  error: false;
  parentAccountName: string;
  emailDomain: string;
};

export type FailEmailResponse = {
  success: false;
  error: string;
  showSignInLink: boolean;
};

export type EmailResponse = SuccessEmailResponse | FailEmailResponse;

export function isSuccessEmailResponse(
  response:
    | SuccessEmailResponse
    | FailEmailResponse
    | {
        error: string;
        success?: boolean | undefined;
        showSignInLink?: boolean | undefined;
      },
): response is SuccessEmailResponse {
  return (response as SuccessEmailResponse).success;
}

export function isFailEmailResponse(
  response:
    | SuccessEmailResponse
    | FailEmailResponse
    | {
        error: string;
        success?: boolean | undefined;
        showSignInLink?: boolean | undefined;
      },
): response is FailEmailResponse {
  return !(response as FailEmailResponse).success;
}

export type EmailData =
  | {
      error: string;
      showSignInLink?: boolean;
    }
  | {
      showSignInLink?: false;
      error?: undefined;
      parentAccountName: string;
      emailDomain: string;
    };

export type AvailableAccount = {
  name: string;
  id: number;
};

export type CredsResponse =
  | {
      success: false;
      error: string;
    }
  | {
      success: true;
      error: false;
      accounts: AvailableAccount[];
    };

export type CredsData =
  | {
      error: string;
    }
  | {
      error: false;
      accounts: AvailableAccount[];
    };

export type FacilityResponse =
  | {
      success: true;
      error: false;
    }
  | {
      success: false;
      error: string;
    };

export type FacilityData = {
  error: string;
};

export enum AccountFormSteps {
  'EMAIL' = 1,
  'CREDS' = 2,
  'UNIT_SELECTION' = 4,
}
