import React from 'react';
import { useData } from './data';
import { MncsAnswer, MncsPromptField } from '../../../../generated/graphql';
import { LabelWithTooltip } from '../../../LabelWithTooltip';
import { SplitNotes } from './SplitNotes';
import {
  Button,
  Checkbox,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { useStyles } from './styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

type Props = {
  mncsAnswers: MncsAnswer[];
  onGoBack: () => void;
};

export const AddMedicalNecessityStatementView: React.FC<Props> = ({
  mncsAnswers,
  onGoBack,
}) => {
  const classes = useStyles();

  const medicalNecessities = useData();
  return (
    <Grid container wrap="nowrap" className={classes.container}>
      <Grid item xs={12}>
        <Paper className={classes.left}>
          <Button
            color="primary"
            startIcon={<ArrowBackIcon />}
            onClick={onGoBack}
          >
            Back
          </Button>
          <Typography component="h1" variant="h4">
            Medical Necessity Certification Statement
          </Typography>

          <Grid container lg={12} md={12} xs={12}>
            {medicalNecessities &&
              medicalNecessities.map(element => {
                return (
                  <>
                    {element.group ? (
                      <Typography
                        style={{ marginTop: '2rem' }}
                        component="h4"
                        variant="h5"
                      >
                        {element.group}
                      </Typography>
                    ) : null}
                    <Grid
                      className={!!element.group ? classes.group : ''}
                      item
                      lg={!!element.group ? 12 : 6}
                      md={!!element.group ? 12 : 6}
                      xs={12}
                    >
                      {element.subGroup.map(subElement => (
                        <Grid
                          style={{
                            paddingTop: '1rem',
                          }}
                          item
                          xs={12}
                        >
                          <Typography component="h4" variant="h6">
                            {subElement.name}
                          </Typography>
                          {subElement.promts.map(p => {
                            if (p.type === MncsPromptField.Boolean) {
                              return (
                                <div className={classes.content}>
                                  <Checkbox
                                    id={`mncsOptions.${p.value}`}
                                    checked={
                                      !!mncsAnswers.find(
                                        answer => answer.slug === p.value,
                                      )
                                    }
                                  />
                                  <LabelWithTooltip
                                    labelText={
                                      p.name || (
                                        <SplitNotes notes={p.notes} />
                                      ) ||
                                      null
                                    }
                                    tooltipText={p.name ? p.notes : ''}
                                  />
                                </div>
                              );
                            } else if (p.type === MncsPromptField.String) {
                              return (
                                <TextField
                                  id={`mncsOptions.${p.value}`}
                                  multiline
                                  rows={3}
                                  fullWidth
                                  label={<span>{p.name}</span>}
                                  value={
                                    mncsAnswers.find(
                                      answer => answer.slug === p.value,
                                    )?.value || ''
                                  }
                                />
                              );
                            } else if (p.type === MncsPromptField.Date) {
                              return (
                                <DatePicker
                                  id={`mncsOptions.${p.value}`}
                                  autoOk
                                  disableFuture
                                  format="l"
                                  label={p.name}
                                  value={
                                    mncsAnswers.find(
                                      answer => answer.slug === p.value,
                                    )?.value
                                  }
                                  onChange={() => {}}
                                />
                              );
                            }

                            return null;
                          })}
                        </Grid>
                      ))}
                    </Grid>
                  </>
                );
              })}
          </Grid>

          <Grid style={{ marginTop: '1rem' }}>
            <Button
              key="okButton"
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={onGoBack}
            >
              Go Back
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
