import React from 'react';
import { Box, Typography } from '@material-ui/core';

const ZeroStateText = ({ children }) => {
  return (
    <Box fontStyle="italic">
      <Typography color="textSecondary" variant="body2">
        {children}
      </Typography>
    </Box>
  );
};

export default ZeroStateText;
