import { useMutation } from '@apollo/client';
import { AccountSettingsForm } from 'components/accounts/AccountSettingsForm';
import LayoutWithQuery from 'components/layout/LayoutWithQuery';
import { useToast } from 'components/layout/Toast';
import { loader } from 'graphql.macro';
import { useTracking } from 'lib/analytics/Tracker';
import React, { useEffect, useState } from 'react';

import {
  UpdateAccountMutationMutationVariables,
  UpdateAccountMutationMutation,
  RemoveAccountPaymentMethodMutationVariables,
  RemoveAccountPaymentMethodMutation,
  UpdateAccountPaymentMethodMutationMutationVariables,
  UpdateAccountPaymentMethodMutationMutation,
  AccountQueryQuery,
} from 'generated/graphql';
import { ApolloError } from '@apollo/client/errors';
import { useHistory } from 'react-router-dom';

const ACCOUNT_QUERY = loader('../data/queries/AccountQuery.graphql');

const REMOVE_PAYMENT_METHOD = loader(
  '../data/mutations/RemoveAccountPaymentMethod.graphql',
);
const UPDATE_ACCOUNT_MUTATION = loader(
  '../data/mutations/UpdateAccountMutation.graphql',
);
const UPDATE_PAYMENT_METHOD_MUTATION = loader(
  '../data/mutations/UpdateAccountPaymentMethodMutation.graphql',
);

export function AccountSettings() {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const tracker = useTracking();
  const toast = useToast();
  const history = useHistory();

  useEffect(() => {
    tracker.track('Account Settings Viewed');
  }, [tracker]);

  const onUpdateAccountCompleted = (data: UpdateAccountMutationMutation) => {
    if (data.updateAccount?.error) {
      onUpdateAccountError(data.updateAccount.error);
    } else {
      toast?.showMessage('Account updated successfully.');
    }
  };

  const onUpdateAccountError = (error: ApolloError | string) => {
    if (error) {
      setErrorMessage(
        'Error updating your account.  If this error continues, please call 1-800-700-4797',
      );
    }
  };

  const [updateAccount] = useMutation<
    UpdateAccountMutationMutation,
    UpdateAccountMutationMutationVariables
  >(UPDATE_ACCOUNT_MUTATION, {
    onCompleted: onUpdateAccountCompleted,
    onError: onUpdateAccountError,
  });

  const onUpdateAccount = (
    data: UpdateAccountMutationMutationVariables['account'],
  ) => {
    tracker.track('Account Name Updated');
    updateAccount({
      variables: {
        account: { name: data.name, billingEmail: data.billingEmail },
      },
    });
  };

  const onCardRemoved = (data: RemoveAccountPaymentMethodMutation) => {
    if (data.removeAccountPaymentMethod?.error) {
      onCardRemovedError(data.removeAccountPaymentMethod.error);
    } else {
      toast?.showMessage('Payment method removed successfully.');
    }
  };

  const onCardRemovedError = (error?: ApolloError | string) => {
    if (!error) return;

    setErrorMessage(
      'This payment method can not be deleted. Please add a new valid card before deleting this one.',
    );
  };

  const [removeAccountPaymentMethod] = useMutation<
    RemoveAccountPaymentMethodMutation,
    RemoveAccountPaymentMethodMutationVariables
  >(REMOVE_PAYMENT_METHOD, {
    onCompleted: onCardRemoved,
    onError: onCardRemovedError,
  });

  const onRemoveCard = (
    paymentMethodId: RemoveAccountPaymentMethodMutationVariables['paymentMethodId'],
  ) => {
    tracker.track('Payment Method Removed');
    removeAccountPaymentMethod({ variables: { paymentMethodId } });
  };

  const onUpdatePaymentMethodCompleted = (
    data: UpdateAccountPaymentMethodMutationMutation,
  ) => {
    if (data.updateAccountPaymentMethod?.error) {
      onUpdatePaymentMethodError(data.updateAccountPaymentMethod.error);
    } else {
      toast?.showMessage('Payment method updated successfully.');
    }
  };

  const onUpdatePaymentMethodError = (error: ApolloError | string) => {
    if (error) {
      setErrorMessage(
        'Error updating your payment method.  If this error continues, please call 1-800-700-4797',
      );
    }
  };

  const [updatePaymentMethod] = useMutation<
    UpdateAccountPaymentMethodMutationMutation,
    UpdateAccountPaymentMethodMutationMutationVariables
  >(UPDATE_PAYMENT_METHOD_MUTATION, {
    onCompleted: onUpdatePaymentMethodCompleted,
    onError: onUpdatePaymentMethodError,
  });

  const onUpdatePaymentMethod = ({
    paymentMethodId,
    paymentMethod,
  }: UpdateAccountPaymentMethodMutationMutationVariables) => {
    tracker.track('Payment Method Name Updated');
    updatePaymentMethod({
      variables: { paymentMethodId, paymentMethod },
    });
  };

  return (
    <LayoutWithQuery<AccountQueryQuery>
      query={ACCOUNT_QUERY}
      withRefetch
      renderView={(data, refetch) => {
        if (!data) {
          return null;
        }

        if (!data.profile?.admin && data.account?.channel === 'b2b') {
          history.replace('/');
        }

        return (
          <AccountSettingsForm<AccountQueryQuery>
            profile={data.profile}
            account={data.account}
            errorMessage={errorMessage}
            onRemoveCard={onRemoveCard}
            onUpdateAccount={onUpdateAccount}
            onUpdatePaymentMethod={onUpdatePaymentMethod}
            getAccount={refetch}
          />
        );
      }}
    />
  );
}
