import mixpanel from 'mixpanel-browser';

export default class MixpanelAnalytics {
  constructor(mixpanelKey) {
    mixpanel.init(mixpanelKey, { debug: false });
  }

  track(name, props) {
    const newProps = Object.assign({}, props, { platform: 'web' });
    mixpanel.track(name, newProps);
  }

  identify(userId) {
    mixpanel.identify(userId);
  }

  reset() {
    mixpanel.reset();
  }

  alias(userId) {
    mixpanel.alias(userId);
  }

  people_set(props) {
    mixpanel.people.set(props);
  }
}
