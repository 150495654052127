import { Redirect } from 'react-router-dom';
import { useAuth } from '../contexts/auth-context';

export function PublicOnly({ children }: { children: JSX.Element }) {
  const auth = useAuth();

  return auth.isLoggedIn ? (
    <Redirect
      to={{
        pathname: '/',
      }}
    />
  ) : (
    children
  );
}
