import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React from 'react';

const useStyles = makeStyles(theme => ({
  notifSelector: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  toggleSelected: {
    // need important to be more specific than the MUI css
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: '#fff !important',
  },
  toggleItem: {
    width: 140,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

export const NotifPrefSelector = ({
  value,
  onChange,
  pushAvailable,
  onPushPress,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <ToggleButtonGroup
      className={classes.notifSelector}
      color="secondary"
      size="medium"
      value={value}
      onChange={(e, values) => {
        if (
          onPushPress &&
          e.nativeEvent.target.innerText.toLowerCase().trim() === 'push'
        ) {
          onPushPress(value);
          return;
        }
        onChange(values);
      }}
      orientation={isSmall ? 'vertical' : 'horizontal'}
    >
      <ToggleButton
        classes={{
          selected: classes.toggleSelected,
        }}
        className={classes.toggleItem}
        value="sms"
      >
        Text Message
      </ToggleButton>
      <ToggleButton
        classes={{
          selected: classes.toggleSelected,
        }}
        className={classes.toggleItem}
        value="email"
      >
        Email
      </ToggleButton>
      <ToggleButton
        classes={{
          selected: classes.toggleSelected,
        }}
        className={classes.toggleItem}
        value="voice"
      >
        Phone
      </ToggleButton>
      {pushAvailable && (
        <ToggleButton
          classes={{
            selected: classes.toggleSelected,
          }}
          className={classes.toggleItem}
          value="push"
        >
          Push
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  );
};
