import {
  Button,
  Container,
  Grid,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import RiderCard from 'components/riders/RiderCard';
import ZeroState from 'components/ZeroState';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Rider, useRidersQueryLazyQuery } from '../../generated/graphql';
import debounce from 'lodash/debounce';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  root: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  search: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 300,
    marginBottom: theme.spacing(2),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

const CardSkeleton = () => (
  <Grid container spacing={3} alignItems="stretch">
    <Grid item xs={12} sm={6} lg={4}>
      <Skeleton variant="text" />
      <Skeleton
        style={{ marginTop: 5 }}
        variant="circle"
        width={40}
        height={40}
      />
      <Skeleton
        style={{ marginTop: 5 }}
        variant="rect"
        width={'100%'}
        height={178}
      />
    </Grid>
    <Grid item xs={12} sm={6} lg={4}>
      <Skeleton variant="text" />
      <Skeleton
        style={{ marginTop: 5 }}
        variant="circle"
        width={40}
        height={40}
      />
      <Skeleton
        style={{ marginTop: 5 }}
        variant="rect"
        width={'100%'}
        height={178}
      />
    </Grid>
  </Grid>
);

export const RidersCardList: React.FC = () => {
  const classes = useStyles();
  const [filteredRiders, setFilteredRiders] = useState<Rider[] | null>(null);
  const [getRiders, { data: ridersData, loading }] = useRidersQueryLazyQuery();

  const debouncedGetRiders = useMemo(
    () => debounce(getRiders, 300),
    [getRiders],
  );

  const filterRiders = useCallback(
    value => {
      if (typeof value === 'string') {
        debouncedGetRiders({ variables: { query: value } });
      }
    },
    [debouncedGetRiders],
  );

  useEffect(() => {
    getRiders();
  }, [getRiders]);

  useEffect(() => {
    if (ridersData && ridersData.riders) {
      setFilteredRiders(ridersData.riders);
    }
  }, [ridersData]);

  const createRiderPath = '/riders/select';

  const renderRidersOrZeroState = () => {
    if (filteredRiders && filteredRiders.length > 0) {
      return filteredRiders?.map(rider => (
        <Grid item xs={12} sm={6} lg={4} key={rider.id}>
          <RiderCard key={rider.id} rider={rider} />
        </Grid>
      ));
    }
    return (
      <Container>
        <ZeroState
          imageSrc="/traveler.png"
          text="Before you can book a ride, you'll need to add a rider."
          buttonLabel="Add Rider"
          buttonPath={createRiderPath}
        />
      </Container>
    );
  };

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <Typography variant="h4" gutterBottom>
          Rider Profiles
        </Typography>

        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to={createRiderPath}
        >
          Add Rider
        </Button>
      </header>
      <Paper component="form" className={classes.search}>
        <InputBase
          className={classes.input}
          placeholder="Search Riders"
          onChange={e => filterRiders(e.target.value)} //setSearch(e.target.value)}
          inputProps={{ 'aria-label': 'search riders' }}
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
      </Paper>

      {loading ? (
        <CardSkeleton />
      ) : (
        <Grid container spacing={3} alignItems="stretch">
          {renderRidersOrZeroState()}
        </Grid>
      )}
    </div>
  );
};
