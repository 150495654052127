import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  date: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },

  skeletonTitle: {
    marginBottom: theme.spacing(4),
  },

  riderFilter: {
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
      marginTop: theme.spacing(1),
    },
  },
  filterContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}));

export { styles };
