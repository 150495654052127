import { makeStyles } from '@material-ui/core';
import onwardColors from 'lib/onwardColors';

export const useStyles = makeStyles(theme => ({
  buttonIcon: {
    padding: 0,
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    color: 'white',
    fontSize: '1.3em',
  },
  label: {
    fontWeight: 'bold',
    fontSize: '0.6em',
    color: 'white',
  },
  pastDateLabel: {
    fontWeight: 'bold',
    fontSize: '1em',
    textAlign: 'center',
    color: onwardColors.onwardRed,
  },
}));
