import { useEffect, useRef } from 'react';
import UpcomingRidesCard from 'components/dashboard/UpcomingRides/UpcomingRidesCard';
import { useTracking } from 'lib/analytics/Tracker';
import { useAccountCustodiansQueryLazyQuery } from '../generated/graphql';
import { UpcomingRidesTable } from '../components/dashboard/UpcomingRidesTable';
import { SnackBarWrapper } from '../components/snackBarWrapper/SnackBarWrapper';
import MainLayout from '../components/layout/MainLayout';
import { useSessionContext } from '../contexts/session-context';

function DashboardContainer() {
  const { session } = useSessionContext();
  const [
    getCustodians,
    { data: dataCustodians },
  ] = useAccountCustodiansQueryLazyQuery();
  const tracker = useTracking();

  const gridViewEnabled = session?.account?.gridViewEnabled;

  const updateUpcomingRidesHistory = useRef<(() => void) | undefined>(
    undefined,
  );

  useEffect(() => {
    const profile = session?.profile;

    if (profile) {
      tracker.people_set({
        $name: `${profile.firstName} ${profile.lastName}`,
        email: profile.email,
        firstName: profile.firstName,
        lastName: profile.lastName,
        phone: profile.phone,
      });
      tracker.track('Dashboard Viewed');
    }
  }, [tracker, session?.profile]);

  useEffect(() => {
    getCustodians();
  }, [getCustodians]);

  return (
    <MainLayout usePadding="yes">
      <SnackBarWrapper update={updateUpcomingRidesHistory.current} />

      {gridViewEnabled ? (
        <UpcomingRidesTable
          dataCustodians={dataCustodians}
          updateRides={(updateFn: () => void) => {
            updateUpcomingRidesHistory.current = updateFn;
          }}
        />
      ) : (
        <UpcomingRidesCard
          custodians={dataCustodians}
          updateRides={(updateFn: () => void) => {
            updateUpcomingRidesHistory.current = updateFn;
          }}
        />
      )}
    </MainLayout>
  );
}

export default DashboardContainer;
