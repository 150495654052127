import React from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import { writeIsMetric } from '../../storages/is-metric-storage';

export type Props = {
  checked: boolean;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
};

export const MetricSwitch: React.FC<Props> = ({ checked, handleChange }) => {
  const _handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    writeIsMetric(checked);
    handleChange(event, checked);
  };

  return (
    <FormControlLabel
      control={
        <Switch checked={checked} onChange={_handleChange} name="metric" />
      }
      label="Metric"
    />
  );
};
