import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AccountForm from '../components/accountPageComponents/AccountForm';
import onwardPin from '../images/logo192.png';
import Avatar from '@material-ui/core/Avatar/Avatar';

export const AccountPage = () => {
  const styles = useStyles();

  return (
    <section className={styles.fullContainer}>
      <Avatar alt="Onward Pin" className={styles.avatar} src={onwardPin} />
      <AccountForm />
    </section>
  );
};

const useStyles = makeStyles(theme => ({
  fullContainer: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
