import { ReactNode, createContext, useContext } from 'react';
import { AccountQueryQuery, useAccountQueryQuery } from 'generated/graphql';
import { ApolloError } from '@apollo/client';
import { useAuth } from './auth-context';

export const SessionContext = createContext<
  | {
      error: ApolloError | undefined;
      loading: boolean;
      session: AccountQueryQuery | null;
    }
  | undefined
>(undefined);

export default function SessionProvider({ children }: { children: ReactNode }) {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn === false) {
    return <>{children}</>;
  }

  // NOTE: We need to disabled this linting rule. Yes, this is not a good
  // practice, but we have to do this so that we don't call off to the API when
  // the user isn't logged in to generate a 401 error to redirect the user back
  // to the login page.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, error, loading } = useAccountQueryQuery();

  return (
    <SessionContext.Provider value={{ error, loading, session: data ?? null }}>
      {children}
    </SessionContext.Provider>
  );
}

export function useSessionContext() {
  const context = useContext(SessionContext);

  if (context === undefined) {
    throw new Error('useSessionContext must be used within a SessionContext');
  }

  const { error, loading, session } = context;

  return {
    error,
    isAdmin: session?.profile?.admin === true,
    loading,
    session,
  };
}
