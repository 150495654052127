import { useLocation, useParams } from 'react-router-dom';
import { useVetridesContext } from '../contexts/vetrides-context';

// There is no item for the "Edit" page since we don't allow changing the rider
// when editing a ride.
enum NavigationParams {
  BookNew = 'bookNew',
  BookCopy = 'bookCopy',
}

export function useRiderProfileNavigation() {
  const { generateVetrideParams } = useVetridesContext();
  const { rideId: rideIdRouteParam } = useParams<{ rideId?: string }>();
  const { pathname, search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const from = searchParams.get('from');
  const rideIdQueryParam = searchParams.get('rideID');
  const rideId = rideIdRouteParam || rideIdQueryParam;
  const makeReturnTripQueryParam = searchParams.get('makeReturnTrip');

  /**
   * Generates query params that tells the Rider Profile page where the user is
   * coming from so that it can redirect back to the correct page.
   *
   * @returns A query string to be used in the URL.
   */
  const generateFromParams = () => {
    // We need to add the Vetride params to the URL so that the app knows to
    // mimic the custodian associated with the Vetride request, not the logged
    // in user.
    const vetrideParams = generateVetrideParams();
    const query = new URLSearchParams(vetrideParams ?? '');

    // There is no condtion for the "Edit" page since we don't allow changing
    // the rider when editing a ride.

    if (from) {
      // Use the existing `from` query param if it exists.
      query.set('from', from);
    } else if (pathname.endsWith('copy')) {
      query.set('from', NavigationParams.BookCopy);
    } else if (pathname.endsWith('rides/new')) {
      query.set('from', NavigationParams.BookNew);
    }

    if (rideId) {
      query.set('rideID', rideId);
    }

    if (makeReturnTripQueryParam) {
      query.set('makeReturnTrip', makeReturnTripQueryParam);
    }

    return query.toString();
  };

  /**
   * Generates a URL to redirect to based on the query params.
   *
   * @param riderId (optional) The rider ID to be passed to the redirect URL.
   * @returns Returns the URL to redirect to.
   */
  const generateRedirectUrl = (riderId?: string) => {
    // We need to add the Vetride params to the URL so that the app knows to
    // mimic the custodian associated with the Vetride request, not the logged
    // in user.
    const vetrideParams = generateVetrideParams();
    const query = new URLSearchParams(vetrideParams ?? '');

    if (riderId) {
      query.set('riderID', riderId);
    }

    if (makeReturnTripQueryParam) {
      query.set('makeReturnTrip', makeReturnTripQueryParam);
    }

    // There is no condtion for the "Edit" page since we don't allow changing
    // the rider when editing a ride.
    if (from === NavigationParams.BookCopy) {
      return `/rides/${rideIdQueryParam}/copy?${query}`;
    } else if (from === NavigationParams.BookNew) {
      return `/rides/new?${query}`;
    }

    return null;
  };

  return {
    generateRedirectUrl,
    generateFromParams,
  };
}
