import onwardColors from 'lib/onwardColors';

import React from 'react';

const Convertible = ({ color = onwardColors.onwardBlue }) => (
  <svg width={83} height={26}>
    <defs>
      <path id="prefix__a" d="M0 .394h165.318v49.744H0z" />
    </defs>
    <g transform="scale(.5)" fill="none" fillRule="evenodd">
      <path
        d="M82.659 49.429c45.443 0 82.28.71 82.28 1.589 0 .877-36.837 1.588-82.28 1.588S.379 51.895.379 51.018c0-.879 36.837-1.59 82.28-1.59"
        fill="#B3B3B3"
      />
      <g transform="translate(0 -.193)">
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <path
          d="M27.043 25.205c6.884 0 12.467 5.58 12.467 12.468 0 6.884-5.583 12.465-12.467 12.465-6.886 0-12.467-5.58-12.467-12.465 0-6.887 5.581-12.468 12.467-12.468m106.402 0c6.885 0 12.468 5.58 12.468 12.468 0 6.884-5.583 12.465-12.468 12.465s-12.466-5.58-12.466-12.465c0-6.887 5.581-12.468 12.466-12.468m18.914 17.904c2.515-.214 5.357-.4 7.602-.74 1.772-.27 3.167-.7 3.743-1.236 1.832-1.702 1.622-7.008 1.591-9.728-.007-.664-1.686-.666-1.726-1.355-.243-4.194-.357-5.65-.814-9.957-.19-1.782-1.67-3.243-4.008-3.971-6.76-2.109-22.944-4.073-30.603-4.39l-2.429-.092-2.382-.523c-1.585.247-1.443-.045-1.792 1.628-.305 1.46-1.062 3.264-2.97 3.353-6.639.307-13.277.38-19.636.6-.67.023-1.51-.376-1.496-1.143l.05-2.89a.59.59 0 00-.562-.624l-2.209-.198c-.34-.032-.597.285-.657.622l-.459 2.523c-.31 1.711-.601 2.2-2.318 2.273l-27.497 1.168c-.332.014.509-1.538.586-2.817.13-2.163-5.978-2.55-8.43-2.39 3.433-2.75 11.222-7.004 18.054-10.331.794-.387 1.266-.405.747-1.22L74.23.858c-.38-.598-.862-.547-1.48-.258-9.966 4.678-16.86 7.966-26.44 13.591-11.432 1.213-24.126 3.537-35.707 6.407-3.747.929-5.559.505-6.71 2.731-1.253 2.43-1.849 4.708-2.423 6.087-.917.074-.95.351-1.242.888-.27.499-.232 2.49-.214 3.256.028 1.15.11 2.874.21 3.992.208 2.337.6 3.646 1.787 4.532.679.506 1.767.82 2.724.85l3.63.12c2.375-.335 2.8-.087 2.553-3.2a27.482 27.482 0 01-.081-2.182c0-8.952 7.256-16.209 16.207-16.209 8.95 0 16.207 7.257 16.207 16.209 0 1.262-.067 2.492-.242 3.672-.641 4.305.003 3.582 3.357 3.582h68.379c2.243-.461 3.04-.453 2.672-3.579-.139-1.18-.179-2.412-.179-3.675 0-8.952 7.257-16.209 16.208-16.209 8.952 0 16.208 7.257 16.208 16.209 0 .607.012 1.208.017 1.8.024 3.081.171 3.85 2.689 3.636"
          fill={color}
          mask="url(#prefix__b)"
        />
      </g>
    </g>
  </svg>
);

export default Convertible;
