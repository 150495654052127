import { makeStyles } from '@material-ui/core';
import onwardColors from 'lib/onwardColors';

export const useStyles = makeStyles(theme => ({
  root: {},
  section: {
    marginTop: '1em',
    justifyItems: 'center',
  },
  title: {
    display: 'flex',
    flex: 1,
    marginTop: '1em',
    justifyContent: 'center',
    fontWeight: 'bold',
  },
  label: {
    alignSelf: 'center',
    color: onwardColors.onwardLightGray,
  },
  result: {
    fontWeight: 'bold',
    fontSize: '1.5em',
    textAlign: 'right',
  },
  empty:{},
}));
