import { Box } from '@material-ui/core';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import HearingIcon from '@material-ui/icons/Hearing';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { impairmentSummary, mobilitySummary } from '@onwardcare/core';
import { TooltipButton } from '../TooltipButton';
import { ElderlyIcon } from '../icons/icons';
import { Rider } from '../../generated/graphql';

export const RiderImpairments = ({
  rider,
  style = {},
}: {
  rider: Rider;
  style?: any;
}) => {
  return (
    <Box style={style}>
      {rider.mobilityAssistance !== 'none' && (
        <TooltipButton
          title={mobilitySummary(rider.mobilityAssistance)}
          icon={<ElderlyIcon />}
          onHover
        />
      )}
      {rider.visionImpairment !== 'none' && (
        <TooltipButton
          title={
            impairmentSummary(rider.visionImpairment) + ' vision impairment'
          }
          icon={<VisibilityIcon />}
          onHover
        />
      )}
      {rider.hearingImpairment !== 'none' && (
        <TooltipButton
          title={
            impairmentSummary(rider.hearingImpairment) + ' hearing impairment'
          }
          icon={<HearingIcon />}
          onHover
        />
      )}
      {rider.cognitiveImpairment !== 'none' && (
        <TooltipButton
          title={
            impairmentSummary(rider.cognitiveImpairment) +
            ' cognitive impairment'
          }
          icon={<EmojiObjectsIcon />}
          onHover
        />
      )}
    </Box>
  );
};
