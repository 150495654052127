import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useForm } from 'react-hook-form';
import { DialogContentText } from '@material-ui/core';

const PaymentMethodNameDialog = ({
  open,
  paymentMethod,
  handleClose,
  handleUpdate,
}) => {
  const { handleSubmit, errors, register } = useForm();

  const submit = data => {
    handleUpdate({ paymentMethodId: paymentMethod.id, paymentMethod: { name: data.name }});
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <form noValidate onSubmit={handleSubmit(submit)}>
        <DialogTitle id="form-dialog-title">
          Update Payment Method Name
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            {`Give this card (${paymentMethod.cardBrand}: **** ${paymentMethod.cardLast4}) a name that is more identifiable.`}
          </DialogContentText>
          <TextField
            fullWidth
            autoFocus
            margin="dense"
            required
            id="name"
            name="name"
            label="Payment Method Name"
            defaultValue={paymentMethod.name}
            error={!!errors.name}
            helperText={errors.name && 'Payment method name is required'}
            inputRef={register({
              required: true,
            })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Update
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PaymentMethodNameDialog;
