import { Box, Typography } from '@material-ui/core';
import PinIcon from '@material-ui/icons/Room';
import { makeStyles } from '@material-ui/core/styles';
import ShowMoreText from 'react-show-more-text';
import { Location } from '../generated/graphql';
import { LocationPhoto } from './rides/LocationPhoto';

const useStyles = makeStyles(theme => ({
  name: {
    marginBottom: -1 * theme.spacing(1),
    maxHeight: theme.spacing(3),
    overflow: 'hidden',
  },
  root: {
    marginBottom: theme.spacing(1),
  },
  instructionText: {
    fontSize: '0.75rem',
    fontStyle: 'italic',
  },
  truncated: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  addressContainer: {
    overflow: 'hidden',
  },
  roomNumbers: {
    display: 'flex',
    alignItems: 'baseline',
    gap: theme.spacing(0.5),
  },
  locationPhoto: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}));

const LocationRow = ({
  location,
  showAddress,
  showInstructions,
  locationPhoto,
  roomNumber,
  roomNumberTitle,
}: {
  location?: Location | null;
  showAddress: boolean;
  showInstructions: boolean;
  locationPhoto?: string | null;
  roomNumber?: string | null;
  roomNumberTitle?: string;
}) => {
  const classes = useStyles();

  if (!location) {
    return null;
  }

  return (
    <Box className={classes.root} display="flex" width="100%">
      <PinIcon color="primary" />
      <Box
        className={classes.addressContainer}
        display="flex"
        flexDirection="column"
        alignItems="stretch"
      >
        <Typography className={classes.name} variant="body1">
          {location.name}
        </Typography>
        {showAddress && (
          <Typography className={classes.truncated} variant="caption">
            {location.address}
          </Typography>
        )}
        {showInstructions && (
          <ShowMoreText lines={2} className={classes.instructionText}>
            {location.instructions}
          </ShowMoreText>
        )}
        {roomNumber && (
          <Box className={classes.roomNumbers}>
            <Typography variant="subtitle2">{roomNumberTitle}:</Typography>
            <Typography variant="caption">{roomNumber}</Typography>
          </Box>
        )}
        {locationPhoto && (
          <Box className={classes.locationPhoto}>
            <LocationPhoto locationPhoto={locationPhoto} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LocationRow;
