import * as Sentry from '@sentry/react';
import ENV, { getEnvironment } from '../constants/Env';

/**
 * Determine whether errors should be reported to Sentry.
 *
 * @returns {boolean} Whether errors should be reported to Sentry.
 */
function shouldReportErrors() {
  return getEnvironment() !== 'localhost';
}

/**
 * Initialize error logging with Sentry.
 */
export function initErrorHandler() {
  if (shouldReportErrors()) {
    const environment = getEnvironment();

    Sentry.init({
      dsn: ENV.sentryDsn,
      environment,
      // This comes from Heroku automatically.
      // https://devcenter.heroku.com/articles/dyno-metadata
      release: process.env.HEROKU_SLUG_COMMIT,
    });
  }
}

/**
 * Capture an error with Sentry.
 *
 * @param error An error object or a string message.
 */
export function captureException(error: any) {
  console.error(error);

  if (shouldReportErrors()) {
    Sentry.captureException(error instanceof Error ? error : new Error(error));
  }
}

/**
 * Capture a message with Sentry.
 *
 * @param message The message to log.
 */
export function captureMessage(message: string) {
  console.log(message);

  if (shouldReportErrors()) {
    Sentry.captureMessage(message);
  }
}
