import onwardColors from 'lib/onwardColors';

import React from 'react';

const Pickup = ({ color = onwardColors.onwardBlue }) => (
  <svg width={100} height={38}>
    <defs>
      <path id="prefix__a" d="M.072.788h194.945v73.009H.072z" />
    </defs>
    <g transform="scale(.5)" fill="none" fillRule="evenodd">
      <path
        d="M99.387 71.834c54.89 0 99.387.711 99.387 1.59 0 .877-44.498 1.587-99.387 1.587-54.89 0-99.386-.71-99.386-1.587 0-.879 44.496-1.59 99.386-1.59"
        fill="#B3B3B3"
      />
      <g transform="translate(3.6 -.588)">
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <path
          d="M154.135 42.548c8.63 0 15.625 6.996 15.625 15.625 0 8.628-6.996 15.624-15.625 15.624-8.628 0-15.624-6.996-15.624-15.624 0-8.63 6.996-15.625 15.624-15.625zm-123.816 0c8.63 0 15.625 6.996 15.625 15.625 0 8.628-6.996 15.624-15.625 15.624-8.628 0-15.624-6.996-15.624-15.624 0-8.63 6.996-15.625 15.624-15.625zm29.69-19.807c-1.035 0 .526-.628.604-1.952.134-2.239-3.958-2.406-6.498-2.241L64.718 9.02c1.488-1.336 2.471-1.956 4.33-2.801 2.648-1.206 4.859-1.87 7.231-1.87h15.185c1.004-.013.967.41.843 1.244-.45 3.018-1.303 11.065-1.983 14.833-.333 1.849-.73 2.315-2.628 2.315H60.008zm35.058-2.157c.897-7.377 1.31-12.713 1.665-14.773.155-.899.325-1.472 1.434-1.463h4.662c.37 0 .582.855.655 2.18l.728 13.341c.178 3.233-.166 2.872-1.837 2.872h-4.943c-2.56 0-2.644.154-2.364-2.157zm99.586 35.3c.607-2.423.25-6.297.342-8.786.114-3.075-1.195-2.073-2.38-2.903-.252-4.342-.026-11.635-.499-16.095-.552-5.21-2.039-5.856-7.848-5.844l-68.424.147c-1.707.004-2.354-.296-2.717-.54-1.22-.826-1.971-10.736-3.261-16.49-.687-3.067-.671-4.585-4.096-4.585H75.138c-4.559 0-9.105 2.179-12.936 5.29l-17.465 14.18c-13.128 1.147-26.744 3.366-37.327 6.111-3.316.862-4.67 2.392-4.649 6.08l.074 12.333c-.499.041-1.09.14-1.579.304-1.027.344-1.079.695-1.147 1.7-.054.8-.037 1.793-.024 2.286.046 1.973.238 7.612 1.672 8.681.702.523 1.828.94 2.82.959l3.17.063c2.363-.471 2.818-1.24 3.248-3.9 1.535-9.469 9.603-16.322 19.324-16.29 10.105 0 18.407 7.654 19.476 17.478.306 2.81 1.807 2.846 3.872 2.87l78.826.166c1.66.004 1.993-.93 2.117-2.488.795-10.088 9.234-18.026 19.525-18.026 10.187 0 18.56 7.778 19.5 17.718.247 2.62.206 2.884 2.551 2.889l6.355.013c2.715.006 11.381-.41 12.112-3.32z"
          fill={color}
          mask="url(#prefix__b)"
        />
      </g>
    </g>
  </svg>
);

export default Pickup;
