import { Redirect, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/auth-context';

export function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();

  return auth.isLoggedIn ? (
    children
  ) : (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: location },
      }}
    />
  );
}
