import { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles(theme => ({
  errorMessage: {
    padding: theme.spacing(5),
  },
  errorTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  errorBody: {
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.body1.fontWeight,
    lineHeight: theme.typography.body1.lineHeight,
  },
}));

function EhrErrorMessage({ children }: { children: ReactNode }) {
  const classes = useStyles();

  return <div className={classes.errorMessage}>{children}</div>;
}

function Title({ children }: { children: ReactNode }) {
  const classes = useStyles();

  return (
    <Typography variant="h4">
      <div className={classes.errorTitle}>
        <ErrorIcon color="error" fontSize="large" />
        <div>{children}</div>
      </div>
    </Typography>
  );
}

EhrErrorMessage.Title = Title;

function Body({ children }: { children: ReactNode }) {
  const classes = useStyles();

  return <div className={classes.errorBody}>{children}</div>;
}

EhrErrorMessage.Body = Body;

export default EhrErrorMessage;
