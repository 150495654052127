import { Button } from '@material-ui/core';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';
import React from 'react';
import { Rider } from '../../generated/graphql';

export const RiderElementComponent: React.FC<{
  onSelectRider: (rider: Rider) => void;
  riders: Rider[];
}> = ({ riders, onSelectRider }) => {
  const columns: MUIDataTableColumn[] = [
    {
      name: 'firstName',
      options: {
        customHeadLabelRender: () => {
          return (
            <div>
              <p>First Name</p>
              <p>DOB</p>
            </div>
          );
        },
        sort: false,
        customBodyRender: (...args) => {
          const currentRider = riders?.[args[1].rowIndex];
          if (currentRider) {
            const { firstName, lastName, dob } = currentRider;
            return (
              <div>
                <p>
                  {firstName} {lastName}
                </p>
                <p>{dob}</p>
              </div>
            );
          }

          return null;
        },
      },
    },
    {
      name: 'phone',
      options: {
        customHeadLabelRender: () => {
          return (
            <div>
              <p>Phone</p>
              <p>Email</p>
            </div>
          );
        },
        sort: false,
        customBodyRender: (...args) => {
          const currentRider = riders?.[args[1].rowIndex];
          if (currentRider) {
            const { email, phone } = currentRider;
            return (
              <div>
                <p>{phone}</p>
                <p>{email}</p>
              </div>
            );
          }
          return null;
        },
      },
    },
    {
      name: 'paymentMethods',
      options: {
        customHeadLabelRender: () => {
          return (
            <div>
              <p>Payment</p>
              <p>Methods</p>
            </div>
          );
        },
        sort: false,
        customBodyRender: (...args) => {
          const currentRider = riders?.[args[1].rowIndex];
          return currentRider && currentRider.paymentMethods?.length ? (
            <span>Yes</span>
          ) : (
            <span>None</span>
          );
        },
      },
    },
    {
      name: 'historyRidesCount',
      options: {
        customHeadLabelRender: () => {
          return (
            <div>
              <p>Rides Count</p>
              <p>History/Upcoming</p>
            </div>
          );
        },
        sort: false,
        customBodyRender: (...args) => {
          const currentRider = riders?.[args[1].rowIndex];
          if (currentRider) {
            const { historyRidesCount, upcomingRidesCount } = currentRider;
            return (
              <div>
                <p>
                  {historyRidesCount}/{upcomingRidesCount}
                </p>
              </div>
            );
          }
          return null;
        },
      },
    },
    {
      name: '',
      label: '',
      options: {
        sort: false,
        customBodyRender: (...args) => {
          const currentRider = riders?.[args[1].rowIndex];
          return (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => onSelectRider(currentRider)}
            >
              Use this Rider
            </Button>
          );
        },
      },
    },
  ];

  return (
    <div>
      <MUIDataTable
        title={''}
        data={riders}
        columns={columns}
        options={{
          elevation: 0,
          pagination: false,
          download: false,
          print: false,
          search: false,
          filter: false,
          viewColumns: false,
          selectableRows: undefined,
        }}
      />
    </div>
  );
};
