import { useEffect, useState } from 'react';
import ENV from '../../constants/Env';
import {
  CredsData,
  CredsResponse,
  EmailData,
  EmailResponse,
  FacilityData,
  FacilityResponse,
  isFailEmailResponse,
  isSuccessEmailResponse,
} from './types';

const { apiUrl } = ENV;

export function useHttpRequest<Result, Args extends any[] = []>(
  fn: (...args: Args) => Promise<Result>,
  ...args: Args
) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Result | null>(null);

  const call = async () => {
    const result = await fn.call(null, ...args);
    setData(result);
    setLoading(false);
  };

  useEffect(() => {
    call();
  }, []);

  return {
    data,
    loading,
  };
}

export function useLazyHttpRequest<Result, Args extends any[] = []>(
  fn: (...args: Args) => Promise<Result>,
  ...args: Args
) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Result | null>(null);

  const startRun = async () => {
    setLoading(true);
    const result = await fn.call(null, ...args);
    setData(result);
    setLoading(false);
  };

  return [
    startRun,
    {
      data,
      loading,
    },
  ] as const;
}

async function request<Result>(
  url: string,
): Promise<Result | { error: string; success?: boolean }>;
async function request<Result>(
  url: string,
  method: 'post',
): Promise<Result | { error: string; success?: boolean }>;
async function request<Result>(
  url: string,
  method: 'post',
  body: Record<string | number, any>,
): Promise<Result | { error: string; success?: boolean }>;
async function request<Result>(
  url: string,
  method?: 'post',
  body?: Record<string | number, any>,
): Promise<Result | { error: string; success?: boolean }> {
  let responseData:
    | (Result & { error: false; success?: boolean })
    | { error: string; success?: boolean };

  try {
    let args: Partial<RequestInit> = {};
    if (method !== undefined) {
      args.method = method;
      args.body = JSON.stringify(body ?? {});
    }
    const response = await fetch(url, args);

    if (response.status >= 500) {
      responseData = { error: 'Something went wrong' };
      return responseData;
    }

    if (response.status >= 400 && response.status < 500) {
      const data = await response.json();
      if (data.error) {
        return { error: data.error };
      } else {
        return { error: 'Something went wrong' };
      }
    }

    const data = (await response.json()) as Result;
    // @ts-ignore
    return { ...data, error: data?.error || false };
  } catch {
    responseData = { error: 'Something went wrong' };
  }

  return responseData;
}

export const postUserEmail = async (email: string): Promise<EmailData> => {
  const url = `${apiUrl}/signup/?step=1&email=${email}`;

  const result = await request<EmailResponse>(url, 'post');

  if (isSuccessEmailResponse(result)) {
    return {
      parentAccountName: result.parentAccountName,
      emailDomain: result.emailDomain,
    };
  } else if (isFailEmailResponse(result)) {
    return {
      showSignInLink: result.showSignInLink,
      error: result.error,
    };
  }

  return { error: result.error };
};

export const postUserCreds = async (
  email: string,
  firsName: string,
  lastName: string,
  phone: string,
  clinicalCredential?: string,
): Promise<CredsData> => {
  const url = `${apiUrl}/signup/?step=2&email=${email}&first_name=${firsName}&last_name=${lastName}&phone=${phone}&clinical_credential=${clinicalCredential}`;

  const result = await request<CredsResponse>(url, 'post');

  let accountData: CredsData = { error: '' };
  if (result.error) {
    return { error: result.error };
  } else if (result.error === false) {
    accountData = { accounts: result.accounts, error: result.error };
  }

  return accountData;
};

export const postUserFacility = async (
  email: string,
  firsName: string,
  lastName: string,
  phone: string,
  accountId: string,
  department: string,
  jobTitle: string,
  clinicalCredential?: string,
): Promise<FacilityData> => {
  const url = `${apiUrl}/signup/?step=3&email=${email}&first_name=${firsName}&last_name=${lastName}&phone=${phone}&account_id=${accountId}&clinical_credential=${clinicalCredential}&department_name=${department}&title=${jobTitle}`;

  const result = await request<FacilityResponse>(url, 'post');

  let accountData: CredsData = { error: '' };
  if (result.error) {
    return { error: result.error };
  } else if (result.error === false) {
    accountData = { error: '' };
  }

  return accountData;
};
