import { useMutation } from '@apollo/client';
import LayoutWithQuery from 'components/layout/LayoutWithQuery.tsx';
import RideDetailsForm from 'components/rides/RideDetailsForm';
import { loader } from 'graphql.macro';
import { useTracking } from 'lib/analytics/Tracker';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const UPDATE_RIDE_MUTATION = loader(
  '../data/mutations/UpdateRideMutation.graphql',
);
const RIDE_QUERY = loader('../data/queries/RideQuery.graphql');

export default function EditRideDetails() {
  const [updateError, setUpdateError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const tracker = useTracking();
  const history = useHistory();

  useEffect(() => {
    tracker.track('Edit Ride Details Viewed');
  }, [tracker]);

  const onRideUpdated = data => {
    const error = data.updateRide.error;
    if (error) {
      onRideUpdateError(error);
    } else {
      history.replace({ pathname: '/rides/' + data.updateRide.ride.id });
    }
  };

  const onRideUpdateError = error => {
    if (error) {
      setSubmitting(false);
      setUpdateError(error);
    }
  };

  const rideToPersist = data => {
    const { notes, deliverySubstitution, paymentMethodId } = data;
    return {
      notes,
      deliverySubstitution,
      paymentMethodId,
    };
  };

  let { rideId } = useParams();
  rideId = parseInt(rideId);

  const [updateRide] = useMutation(UPDATE_RIDE_MUTATION, {
    onCompleted: onRideUpdated,
    onError: onRideUpdateError,
  });

  const onUpdate = data => {
    tracker.track('Ride Details Updated');
    setSubmitting(true);
    updateRide({ variables: { ride: rideToPersist(data), rideId } });
  };

  return (
    <LayoutWithQuery
      usePadding="no"
      query={RIDE_QUERY}
      variables={{ rideId: parseInt(rideId) }}
      renderView={data => (
        <RideDetailsForm
          account={data.account}
          createError={updateError}
          onSubmit={onUpdate}
          isSubmitting={submitting}
          ride={data.ride}
          actionType="update"
        />
      )}
    />
  );
}
