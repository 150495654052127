import { isAfter, isSameDay, isValid, subYears } from 'date-fns';

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Checks that the date string is valid and is not more than 150 years old.
 *
 * @param dateString The date string in the format of 'MM/DD/YYYY'.
 * @returns Returns true if the date string is valid.
 */
export function isValidDate(dateString: string) {
  const date = new Date(dateString);

  if (isValid(date) === false) {
    return false;
  }

  return (
    isSameDay(date, new Date()) || isAfter(date, subYears(new Date(), 150))
  );
}
