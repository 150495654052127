import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { Ride, useReviewRideMutationMutation } from '../../generated/graphql';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import PersonImage from 'components/PersonImage';
import { firstLastInitial, vehicleDescription } from '@onwardcare/core';
import Rating from '@material-ui/lab/Rating';
import { useTracking } from '../../lib/analytics/Tracker';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    maxWidth: 450,
  },
  container: {
    marginTop: theme.spacing(2),
  },
  driverNameContainer: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  driverFont: {
    fontWeight: 700,
  },
  driver: {
    marginRight: theme.spacing(1),
  },
  sectionHeader: {
    fontWeight: 700,
  },
  starContainer: {
    height: 60,
  },
  icon: {
    fontSize: 48,
  },

  submitButton: {
    marginTop: theme.spacing(2),
  },
}));

export type Props = {
  ride: Ride;
  allowTipping?: boolean;
  closeReview: () => void;
};

export const Review: React.FC<Props> = ({
  ride,
  allowTipping,
  closeReview,
}) => {
  const [creatReview] = useReviewRideMutationMutation({
    onCompleted: () => closeReview(),
  });

  const classNames = useStyles();
  const tracker = useTracking();
  const { tipCents, id: rideId } = ride;

  const [open, setOpen] = React.useState(false);
  const [tipDollars, setTipDollars] = React.useState((tipCents || 0) / 100);
  const [comments, setComments] = React.useState('');
  const [errors, setErrors] = React.useState('');
  const [stars, setStars] = React.useState(0);
  const [commentBlockOpened, openCommentBlock] = React.useState(false);

  const submitReview = () => {
    if (stars === 0) {
      setErrors('Rate the Driver');
      return;
    }

    creatReview({
      variables: {
        rideId,
        ride: {
          tipCents: tipDollars * 100,
          stars,
          driverFeedback: comments,
        },
      },
    });
  };

  useEffect(() => {
    if (stars > 0) {
      setErrors('');
    }
  }, [stars]);

  const closeCommentBlock = () => {
    openCommentBlock(false);
  };

  const openCommentsBlock = () => {
    openCommentBlock(true);
  };

  const showModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const setTip = (tipPercent: number, tipDollars: number) => {
    tracker.track('Preset Tip Selected', { percent: tipPercent });
    setTipDollars(tipDollars);
  };

  const headerText = () => {
    return ride.rideType === 'delivery'
      ? 'Delivery Complete!'
      : 'Ride Complete!';
  };

  const descriptionText = () => {
    return ride.rideType === 'delivery'
      ? 'Thanks for letting us bring you your delivery!'
      : 'Thanks for riding with us.';
  };

  const rateText = () => {
    return ride.rideType === 'delivery'
      ? 'Rate your delivery'
      : 'Rate your ride';
  };

  const renderDriverFeedback = () => {
    return (
      <Box display="flex" flexDirection="column">
        {comments.length > 0 && <Typography>{`“${comments}”`}</Typography>}
        <Box>
          <Button
            onClick={openCommentsBlock}
            endIcon={<ChevronRightRoundedIcon />}
          >
            {comments.length > 0 ? 'Change Comment' : 'Add a comment'}
          </Button>
        </Box>
      </Box>
    );
  };

  const renderFeedback = () => (
    <Box display="flex" flexDirection="column" className={classNames.container}>
      <Typography variant="body1" className={classNames.sectionHeader}>
        {rateText()}
      </Typography>
      <Typography variant="caption">
        Feedback is anonymous and will not be shared directly with your driver.
      </Typography>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classNames.starContainer}
      >
        <Rating
          classes={{
            iconEmpty: classNames.icon,
            iconFilled: classNames.icon,
          }}
          name="hover-feedback"
          value={stars}
          size="large"
          precision={1}
          onChange={(event, newValue) => {
            newValue !== null && setStars(newValue);
          }}
        />
      </Box>
      {renderDriverFeedback()}
    </Box>
  );

  const renderDriver = () => {
    return (
      <Box display="flex" className={classNames.container}>
        <Box>
          <PersonImage person={ride.driver} />
        </Box>
        <Box className={classNames.driverNameContainer} flexGrow={1}>
          <Typography
            className={`${classNames.driverFont} ${classNames.driver}`}
            variant="button"
          >
            Driver:{' '}
          </Typography>
          <Typography className={classNames.driverFont} variant="button">
            {firstLastInitial(ride.driver)}
          </Typography>
          <Typography variant="subtitle2">
            {vehicleDescription(ride.driver?.currentVehicle)}
          </Typography>
        </Box>
      </Box>
    );
  };

  const tipAmount = (percent: number) => {
    if (ride?.estimatedCostCents) {
      return Math.ceil((ride.estimatedCostCents / 10000) * percent);
    }
    return 0;
  };

  const tipAmountFormatted = (percent: number) => {
    return `$${tipAmount(percent)}`;
  };

  const renderPresetTips = () => {
    if (!ride?.estimatedCostCents) {
      return null;
    }

    return (
      <Box display="flex" flexDirection="column" alignItems="end">
        <ButtonGroup fullWidth>
          <Button disabled={tipDollars === 0} onClick={() => setTip(0, 0)}>
            <Typography>No tip</Typography>
          </Button>
          <Button
            disabled={tipDollars === tipAmount(5)}
            onClick={() => setTip(5, tipAmount(5))}
          >
            <Typography>{tipAmountFormatted(5)}</Typography>
          </Button>
          <Button
            disabled={tipDollars === tipAmount(10)}
            onClick={() => setTip(10, tipAmount(10))}
          >
            <Typography>{tipAmountFormatted(10)}</Typography>
          </Button>
          <Button
            disabled={tipDollars === tipAmount(15)}
            onClick={() => setTip(15, tipAmount(15))}
          >
            <Typography>{tipAmountFormatted(15)}</Typography>
          </Button>
        </ButtonGroup>
        {renderCustomOption()}
      </Box>
    );
  };

  const renderCustomOption = () => {
    return (
      <Box>
        <Button endIcon={<ChevronRightRoundedIcon />} onClick={showModal}>
          <Typography>{customText()}</Typography>
        </Button>
      </Box>
    );
  };

  const customText = () => {
    if (
      tipDollars !== 0 &&
      tipDollars !== tipAmount(5) &&
      tipDollars !== tipAmount(10) &&
      tipDollars !== tipAmount(15)
    ) {
      return `Tip amount: $${tipDollars}`;
    }
    return 'Custom tip';
  };

  const increaseTip = () => {
    setTipDollars(tipDollars + 1);
  };

  const decreaseTip = () => {
    const tipDollarsUpdated = tipDollars <= 0 ? 0 : tipDollars - 1;
    setTipDollars(tipDollarsUpdated);
  };

  const renderModal = () => {
    return (
      <div>
        <Dialog
          open={open}
          onClose={closeModal}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Enter Custom Tip</DialogTitle>
          <DialogContent>
            <DialogContentText>Enter a custom tip amount</DialogContentText>
            <Box>
              <IconButton onClick={decreaseTip} color="primary">
                <RemoveIcon />
              </IconButton>
              <TextField
                style={{ width: 60 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
                value={tipDollars}
                onChange={event => {
                  if (
                    typeof Number(event.target.value) !== 'number' ||
                    Number.isNaN(Number(event.target.value))
                  ) {
                    return;
                  }
                  setTipDollars(Number(event.target.value));
                }}
                autoFocus
              />
              <IconButton onClick={increaseTip} color="primary">
                <AddIcon />
              </IconButton>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const renderCommentModal = () => {
    return (
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={commentBlockOpened}
        onClose={closeCommentBlock}
      >
        <DialogTitle id="form-dialog-title">Enter Comment</DialogTitle>
        <DialogContent>
          <TextField
            multiline
            autoFocus
            margin="dense"
            id="name"
            label="Comment"
            fullWidth
            value={comments}
            onChange={event => {
              setComments(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCommentBlock} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderTips = () => (
    <Box display="flex" flexDirection="column" className={classNames.container}>
      <Typography className={classNames.sectionHeader}>Add a tip</Typography>
      <Typography variant="caption">
        Tips are optional. 100% of tips go to drivers.
      </Typography>
      {renderPresetTips()}
      {renderModal()}
      {renderCommentModal()}
    </Box>
  );

  return (
    <Container className={classNames.mainContainer}>
      <Box className={classNames.content}>
        <Box>
          <Typography variant="h5">{headerText()}</Typography>
          <Typography>{descriptionText()}</Typography>
          {ride.driver && renderDriver()}
        </Box>
        {renderFeedback()}
        {allowTipping && renderTips()}
        {errors.length > 0 && (
          <Box className={classNames.container}>
            <Typography className={classNames.sectionHeader} color="error">
              {errors}
            </Typography>
          </Box>
        )}
        <Button
          onClick={() => {
            submitReview();
          }}
          className={classNames.submitButton}
          variant="contained"
          color="primary"
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </Container>
  );
};
