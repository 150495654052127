import { useMutation, useQuery } from '@apollo/client';
import { useLoadingEffect } from 'components/layout/Loading';
import { loader } from 'graphql.macro';
import { useTracking } from 'lib/analytics/Tracker';
import React from 'react';
import FavoriteDriverForm from './FavoriteDriverForm';

const RECENT_DRIVERS_QUERY = loader('../../data/queries/RecentDriversQuery.graphql');
const UPDATE_FAVORITE_DRIVER_MUTATION = loader(
  '../../data/mutations/UpdateFavoriteDriverMutation.graphql',
);
const RIDER_PROFILE_QUERY = loader('../../data/queries/RiderProfileQuery.graphql');

const FavoriteDriverDialog = ({ rider, favoriteDrivers, onClose, open }) => {
  const [updateFavoriteDriver] = useMutation(UPDATE_FAVORITE_DRIVER_MUTATION, {
    refetchQueries: [
      {
        query: RIDER_PROFILE_QUERY,
        variables: { riderId: rider.id },
      },
    ],
  });
  const tracker = useTracking();

  const onDriverSelection = (driverId, favorite) => {
    tracker.track('Updated Favorite Driver');
    updateFavoriteDriver({
      variables: {
        riderId: rider.id,
        driverId,
        favorite,
      },
    });
  };

  const { loading, error, data } = useQuery(RECENT_DRIVERS_QUERY, {
    variables: { riderId: rider.id },
  });
  useLoadingEffect(loading);

  if (loading || error) return null;

  return (
    <FavoriteDriverForm
      open={open}
      recentDrivers={data.recentDrivers}
      favoriteDrivers={favoriteDrivers}
      onClose={onClose}
      onDriverSelection={onDriverSelection}
    />
  );
};

export default FavoriteDriverDialog;
