import { useAuth } from '../contexts/auth-context';
import LayoutWithQuery from 'components/layout/LayoutWithQuery.tsx';
import { RideDetailsView } from 'components/rides/RideDetailsView';
import { loader } from 'graphql.macro';
import { useTracking } from 'lib/analytics/Tracker';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSessionContext } from '../contexts/session-context';

const RIDE_QUERY_V4 = loader('../data/queries/RideQuery.graphql');

function RideDetailsContainer() {
  const auth = useAuth();
  const { session } = useSessionContext();
  const tracking = useTracking();

  let { rideId } = useParams();
  rideId = parseInt(rideId);

  useEffect(() => {
    tracking.track('Ride Details Viewed');
  }, [tracking]);

  return (
    <LayoutWithQuery
      query={RIDE_QUERY_V4}
      variables={{ rideId }}
      withRefetch
      renderView={(data, refetch) => (
        <RideDetailsView
          getRide={refetch}
          ride={data.ride}
          account={session?.account}
          admin={auth.isUsingMagic}
        />
      )}
    />
  );
}

export default RideDetailsContainer;
