import LayoutWithQuery from 'components/layout/LayoutWithQuery.tsx';
import {RidersCardList} from 'components/riders/RidersCardList';
import {RidersTable} from 'components/riders/RidersTable';
import { loader } from 'graphql.macro';
import { useTracking } from 'lib/analytics/Tracker';
import React, { useEffect } from 'react';

const ACCOUNT_QUERY = loader('../data/queries/AccountQuery.graphql');
function RidersContainer() {
  const tracker = useTracking();

  useEffect(() => {
    tracker.track('Riders Page Viewed');
  }, [tracker]);

  return (
    <LayoutWithQuery
      usePadding="yes"
      query={ACCOUNT_QUERY}
      renderView={data => {
        return data?.account?.gridViewEnabled ?
          <RidersTable/>
          :
          <RidersCardList/>
      }}
    />
  );
}

export default RidersContainer;
