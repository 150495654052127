import { MUISortOptions } from 'mui-datatables';
import { FiltersInput, Maybe } from '../generated/graphql';

export type DataStorage =
  | 'rideHistoryTable'
  | 'upcomingRidesTable'
  | 'rideProfileTable'
  | 'accountCustodianTable';

export type DataToStore = {
  page: number;
  columnView: Record<string, 'remove' | 'add'>;
  limit: number;
  sortingValue: [string, MUISortOptions['direction']];
  filters: Array<FiltersInput>;
};

export class PersistDataStorage<DataType> {
  dataStorage: DataStorage;
  constructor(dataStorage: DataStorage) {
    this.dataStorage = dataStorage;
  }

  getData(): Maybe<Partial<DataType>> {
    const storageDataJSON = window.sessionStorage.getItem(this.dataStorage);
    if (storageDataJSON) {
      return JSON.parse(storageDataJSON);
    }

    return null;
  }

  setData(dataToStore: Partial<DataType>) {
    const savedData = this.getData();

    const result = (Object.keys(dataToStore) as [keyof DataType]).reduce(
      (acc, key) => {
        const data = savedData?.[key];
        const dataToSave = dataToStore[key];
        if (Array.isArray(data) && Array.isArray(dataToSave)) {
          return {
            ...acc,
            [key]: [...dataToSave],
          };
        } else if (typeof data === 'object') {
          return {
            ...acc,
            [key]: { ...dataToStore[key] },
          };
        } else {
          return {
            ...acc,
            [key]: dataToStore[key],
          };
        }
      },
      {} as Partial<DataType>,
    );

    if (savedData) {
      const combinedData = {
        ...savedData,
        ...result,
      };
      window.sessionStorage.setItem(
        this.dataStorage,
        JSON.stringify(combinedData),
      );
    } else {
      window.sessionStorage.setItem(
        this.dataStorage,
        JSON.stringify(dataToStore),
      );
    }
  }

  clearStorage() {
    window.sessionStorage.removeItem(this.dataStorage);
  }
}
