import React from 'react';
import { personName, firstLastInitial } from '@onwardcare/core';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

import PersonImage from './PersonImage';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1.5),
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  truncated: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  bold: {
    fontWeight: 600,
  },
}));

const PersonWithName = ({ person, nameType = 'full' }) => {
  const classes = useStyles();

  return (
    <Box display="flex">
      <Box className={classes.avatar}>
        <PersonImage person={person} size="small" />
      </Box>
      <Box className={classes.truncated} flexGrow={1}>
        <Typography className={classes.truncated && classes.bold}>
          {nameType === 'full' ? personName(person) : firstLastInitial(person)}
        </Typography>
      </Box>
    </Box>
  );
};
export default PersonWithName;
