import { Card, Container, InputLabel } from '@material-ui/core';
import TextField from '@material-ui/core/TextField/TextField';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green } from '@material-ui/core/colors';
import { useStyles } from './AccountFormStyles';
import {
  postUserCreds,
  postUserEmail,
  postUserFacility,
  useLazyHttpRequest,
} from './handlers';
import { useEffect, useState } from 'react';
import {
  AccountFormSteps,
  AvailableAccount,
  CredsData,
  EmailData,
  FacilityData,
} from './types';
import Button from '@material-ui/core/Button/Button';
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { validEmail, validPhone } from '@onwardcare/core';
import HelpIcon from '@material-ui/icons/HelpOutline';
import { SimpleDialog } from './FinalDialog';
import { clinicalCredentials } from '../accounts/settingsTabs/ClinicalCredentialsList';

type AccountFormProps = object;

const Row: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.row}>{children}</div>;
};

const validateOnEmpty = (value: string) => {
  return value && value.trim().length !== 0;
};

const AccountForm: React.FC<AccountFormProps> = () => {
  const classes = useStyles();

  const [currentStep, changeStep] = useState<AccountFormSteps>(
    AccountFormSteps.EMAIL,
  );

  const [email, setEmail] = useState<string>('');
  const [emailErrors, setEmailErrors] = useState<string[] | null>(null);
  const [shouldShowSignLink, showSignInLink] = useState<boolean>(false);

  const [firsName, setFirstName] = useState<string>('');
  const [firsNameErrors, setFirstNameErrors] = useState<string[] | null>(null);

  const [lastName, setLastName] = useState<string>('');
  const [lastNameErrors, setLastNameErrors] = useState<string[] | null>(null);

  const [phone, setPhone] = useState<string>('');
  const [phoneErrors, setPhoneErrors] = useState<string[] | null>(null);

  const [department, setDepartment] = useState<string>('');
  const [departmentErrors, setDepartmentErrors] = useState<string[] | null>(
    null,
  );

  const [jobTitle, setJobTitle] = useState<string>('');
  const [jobTitleErrors, setJobTitleErrors] = useState<string[] | null>(null);

  const [selectedAccount, setSelectedAccount] = useState<
    AvailableAccount['id'] | ''
  >('');
  const [selectedAccountErrors, setSelectedAccountErrors] = useState<
    string[] | null
  >(null);

  const [availableAccount, setAvailableAccounts] = useState<
    AvailableAccount[] | null
  >(null);

  const [clinicalCredential, setClinicalCredential] = useState<
    (typeof clinicalCredentials)[number] | ''
  >('');
  const [clinicalCredentialErrors, setClinicalCredentialErrors] = useState<
    string[] | null
  >(null);

  const [formError, setFormError] = useState<string>('');
  const [isEmailChecked, setShowEmailChecked] = useState<boolean>(false);

  const [isShowFinalModal, showFinalModal] = useState<boolean>(false);

  const [sendUserEmail, { data: emailData, loading: emailDataLoading }] =
    useLazyHttpRequest<EmailData, Parameters<typeof postUserEmail>>(
      postUserEmail,
      email,
    );

  const [sendUserCreds, { data: credsData, loading: credsDataLoading }] =
    useLazyHttpRequest<CredsData, Parameters<typeof postUserCreds>>(
      postUserCreds,
      email,
      firsName,
      lastName,
      phone,
      clinicalCredential,
    );

  const [
    sendUserFacility,
    { data: facilityData, loading: facilityDataLoading },
  ] = useLazyHttpRequest<FacilityData, Parameters<typeof postUserFacility>>(
    postUserFacility,
    email,
    firsName,
    lastName,
    phone,
    selectedAccount.toString(),
    department,
    jobTitle,
    clinicalCredential,
  );

  const shouldRenderEmailForm = currentStep >= AccountFormSteps.EMAIL;
  const shouldRenderCredForm = currentStep >= AccountFormSteps.CREDS;
  const shouldRenderAccountSelectorForm =
    currentStep >= AccountFormSteps.UNIT_SELECTION;

  const sendForm = async () => {
    setFormError('');
    switch (currentStep) {
      case AccountFormSteps.EMAIL:
        const isNotEmpty = validEmail(email);
        if (isNotEmpty) {
          await sendUserEmail();
        } else {
          setEmailErrors(['Must be a valid email']);
        }
        break;

      case AccountFormSteps.CREDS:
        const isNotEmptyPhone = validPhone(phone);
        const isNotEmptyFirstName = validateOnEmpty(firsName);
        const isNotEmptyLastName = validateOnEmpty(lastName);
        if (
          !!isNotEmptyPhone &&
          !!isNotEmptyFirstName &&
          !!isNotEmptyLastName
        ) {
          await sendUserCreds();
        } else {
          !isNotEmptyPhone && setPhoneErrors(['Must be a valid phone number']);
          !isNotEmptyFirstName &&
            setFirstNameErrors(['First Name is required']);
          !isNotEmptyLastName && setLastNameErrors(['Last Name is required']);
        }
        break;

      case AccountFormSteps.UNIT_SELECTION:
        const isNotEmptyDepartment = validateOnEmpty(department);
        const isNotEmptySelectedAccount = validateOnEmpty(
          selectedAccount.toString(),
        );
        const isNotEmptyJobTitle = validateOnEmpty(jobTitle);

        if (!!isNotEmptyDepartment && !!isNotEmptySelectedAccount) {
          await sendUserFacility();
        } else {
          !isNotEmptyDepartment &&
            setDepartmentErrors(['Unit / Department is required']);
          !isNotEmptySelectedAccount &&
            setSelectedAccountErrors(['Facility is required']);
          !isNotEmptyJobTitle && setJobTitleErrors(['Job Title is required']);
        }
        break;
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    sendForm();
  };

  useEffect(() => {
    if (emailData) {
      if (emailData.showSignInLink) {
        setFormError(emailData.error);
        showSignInLink(true);
      } else if (emailData.error) {
        setFormError(emailData.error);
      } else if (emailData.error === undefined) {
        setShowEmailChecked(true);
        showSignInLink(false);
        setFormError('');
        changeStep(AccountFormSteps.CREDS);
      }
    }

    if (credsData?.error) {
      setFormError(credsData?.error);
    } else if (credsData?.error === false) {
      setAvailableAccounts(credsData.accounts);
      if (credsData.accounts.length === 1) {
        setSelectedAccount(credsData.accounts[0].id);
      }
      changeStep(AccountFormSteps.UNIT_SELECTION);
    }

    if (facilityData?.error) {
      setFormError(facilityData?.error);
    } else if (facilityData) {
      showFinalModal(true);
    }
  }, [emailData, credsData, facilityData]);

  const changeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailErrors(null);
  };

  const changeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
    setFirstNameErrors(null);
  };

  const changeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
    setLastNameErrors(null);
  };

  const changePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
    setPhoneErrors(null);
  };

  const handleAccountChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setSelectedAccount(event.target.value as AvailableAccount['id']);
    setSelectedAccountErrors(null);
  };

  const handleClinicalCredentialChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setClinicalCredential(
      event.target.value as (typeof clinicalCredentials)[number],
    );
    setClinicalCredentialErrors(null);
  };

  const changeDepartment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDepartment(event.target.value);
    setDepartmentErrors(null);
  };

  const changeJobTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setJobTitle(event.target.value);
    setJobTitleErrors(null);
  };

  return (
    <Container component="main" maxWidth="sm" className={classes.container}>
      <div className={classes.content}>
        <Typography variant="h5">
          <div className={classes.formTitle}>
            <span className={classes.bodyText}>User Provisioning Page</span>
          </div>
        </Typography>
        <div>
          <div className={classes.formBody}>
            <Typography className={classes.bodyText} variant="body2">
              Thank you for accessing the Onward Platform. This form will allow
              you to setup an account that will allow you to order
              transportation
            </Typography>
          </div>
        </div>
        <FormHelperText className={classes.formError} error>
          {formError}{' '}
          {shouldShowSignLink && (
            <a href="https://dashboard.onwardrides.com/login">
              https://dashboard.onwardrides.com/login
            </a>
          )}
        </FormHelperText>

        <Card className={classes.card}>
          <form onSubmit={handleSubmit} className={classes.formRoot}>
            {shouldRenderEmailForm ? (
              <Row>
                <TextField
                  required
                  label={'Email Address'}
                  disabled={currentStep > AccountFormSteps.EMAIL}
                  onChange={changeEmail}
                  value={email}
                  className={classes.input}
                  variant="outlined"
                  error={!!emailErrors}
                  helperText={emailErrors?.map(error => (
                    <span key={error}>{error}</span>
                  ))}
                />
                <div className={classes.iconContainer}>
                  {isEmailChecked ? (
                    <CheckCircleIcon style={{ color: green[600] }} />
                  ) : null}
                </div>
              </Row>
            ) : null}
            {shouldRenderCredForm ? (
              <>
                <Row>
                  <TextField
                    required
                    label={'First Name'}
                    disabled={currentStep > AccountFormSteps.CREDS}
                    onChange={changeFirstName}
                    value={firsName}
                    className={classes.input}
                    variant="outlined"
                    error={!!firsNameErrors}
                    helperText={firsNameErrors?.map(error => (
                      <span key={error}>{error}</span>
                    ))}
                  />
                </Row>
                <Row>
                  <TextField
                    required
                    label={'Last Name'}
                    disabled={currentStep > AccountFormSteps.CREDS}
                    onChange={changeLastName}
                    value={lastName}
                    className={classes.input}
                    variant="outlined"
                    error={!!lastNameErrors}
                    helperText={lastNameErrors?.map(error => (
                      <span key={error}>{error}</span>
                    ))}
                  />
                </Row>
                <Row>
                  <TextField
                    required
                    label={'Phone'}
                    disabled={currentStep > AccountFormSteps.CREDS}
                    onChange={changePhone}
                    value={phone}
                    className={classes.input}
                    variant="outlined"
                    error={!!phoneErrors}
                    helperText={phoneErrors?.map(error => (
                      <span key={error}>{error}</span>
                    ))}
                  />
                </Row>

                <Row>
                  <FormControl
                    error={!!clinicalCredentialErrors}
                    className={classes.input}
                    variant="outlined"
                  >
                    <InputLabel>Clinical Credentials</InputLabel>
                    <Select
                      required
                      label={'Clinical Credentials'}
                      value={clinicalCredential}
                      onChange={handleClinicalCredentialChange}
                    >
                      <MenuItem value={''}>Not specified</MenuItem>
                      {clinicalCredentials.map(clinicalCredential => (
                        <MenuItem
                          key={clinicalCredential}
                          value={clinicalCredential}
                        >
                          {clinicalCredential}
                        </MenuItem>
                      ))}
                    </Select>
                    {!!clinicalCredentialErrors ? (
                      <FormHelperText error>
                        {clinicalCredentialErrors?.map(error => (
                          <span key={error}>{error}</span>
                        ))}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Row>
              </>
            ) : null}

            {shouldRenderAccountSelectorForm && availableAccount ? (
              <>
                <Row>
                  <FormControl
                    error={!!selectedAccountErrors}
                    className={classes.input}
                    variant="outlined"
                  >
                    <InputLabel>Facility</InputLabel>
                    <Select
                      required
                      label={'Facility'}
                      value={selectedAccount}
                      onChange={handleAccountChange}
                    >
                      {availableAccount.map(acc => (
                        <MenuItem value={acc.id}>{acc.name}</MenuItem>
                      ))}
                    </Select>
                    {!!selectedAccountErrors ? (
                      <FormHelperText error>
                        {selectedAccountErrors?.map(error => (
                          <span key={error}>{error}</span>
                        ))}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Row>
                <Row>
                  <TextField
                    required
                    label={'Unit / Department Name'}
                    error={!!departmentErrors}
                    helperText={departmentErrors?.map(error => (
                      <span key={error}>{error}</span>
                    ))}
                    onChange={changeDepartment}
                    className={classes.input}
                    variant="outlined"
                    value={department}
                  />
                </Row>
                <Row>
                  <TextField
                    required
                    label={'Job Title'}
                    error={!!jobTitleErrors}
                    helperText={jobTitleErrors?.map(error => (
                      <span key={error}>{error}</span>
                    ))}
                    onChange={changeJobTitle}
                    className={classes.input}
                    variant="outlined"
                    value={jobTitle}
                  />
                </Row>
              </>
            ) : null}
          </form>
          <div className={classes.bottomBar}>
            <div className={classes.bottomBarContent}>
              <HelpIcon fontSize="large" />
              <div className={classes.bottomBarTextContainer}>
                <span>Have a question? Call 1 (800) 700-4797</span>
              </div>
            </div>

            <Button
              disabled={
                emailDataLoading || credsDataLoading || facilityDataLoading
              }
              onClick={sendForm}
              className={classes.formButton}
              variant="contained"
              color="primary"
            >
              {shouldRenderAccountSelectorForm ? 'Submit' : 'Next'}
            </Button>
          </div>
        </Card>
      </div>
      <SimpleDialog onClose={showFinalModal} open={isShowFinalModal} />
    </Container>
  );
};

export default AccountForm;
