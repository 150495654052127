import onwardColors from 'lib/onwardColors';

import React from 'react';

const Sedan = ({ color = onwardColors.onwardBlue }) => (
  <svg width={83} height={28}>
    <defs>
      <path id="prefix__a" d="M.085.421h164.562V3.6H.085z" />
    </defs>
    <g transform="scale(.5)" fill="none" fillRule="evenodd">
      <g transform="translate(1 52.276)">
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <path
          d="M82.365.421c45.443 0 82.282.713 82.282 1.59 0 .878-36.839 1.59-82.282 1.59C36.923 3.6.085 2.888.085 2.01c0-.877 36.838-1.59 82.28-1.59"
          fill="#B3B3B3"
          mask="url(#prefix__b)"
        />
      </g>
      <path
        d="M132.032 13.429c-.271.793-1.026 2.426-1.505 3.14-.333.5-.753.812-1.552.839-2.192.073-4.55.186-6.993.314l.833-10.868c3.6 1.67 8.176 4.058 8.863 5 .514.701.557.98.354 1.575zm-11.901 4.393c-6.93.381-14.36.85-20.472.924-2.482.03-2.628.636-2.355-1.6.999-8.177 1.179-10.883 1.945-12.87.125-.323.788-.632 1.331-.626l9.53.113c2.848.034 5.79.554 8.601 1.382.466.13 1.348.479 2.442.959l-1.022 11.718zM94.709 4.852c-.957 2.565-1.34 8.106-1.914 11.827-.279 1.797-.704 2.223-2.538 2.237-7.25.061-26.686 1.453-26.597 1.105.081-.321.509-1.538.585-2.817.13-2.163-3.823-2.323-6.274-2.164 4.754-3.535 11.809-7.298 18.126-9.487 2.22-.744 8.25-1.7 12.427-1.79l5.371-.113c.97-.012 1.098.44.814 1.202zM27.095 28.958c6.886 0 12.467 5.581 12.467 12.467 0 6.884-5.581 12.466-12.467 12.466-6.884 0-12.466-5.582-12.466-12.466 0-6.886 5.582-12.467 12.466-12.467zm107.31 0c6.886 0 12.467 5.581 12.467 12.467 0 6.884-5.581 12.466-12.467 12.466-6.885 0-12.467-5.582-12.467-12.466 0-6.886 5.582-12.467 12.467-12.467zM112.842.465c6.875 0 12.582 2.652 19.12 5.544 5.938 2.626 9.746 5.764 14.368 7.226 6.5 2.057 17.106 2.248 17.657 7.421.458 4.31.751 8.952.995 13.147.04.69 1.718.692 1.725 1.354.033 2.72.243 8.027-1.59 9.728-.577.537-1.975.932-3.744 1.235-2.263.39-5.13.628-7.66.821-2.43.187-3.47.49-3.18-3.23.058-.755.08-1.518.08-2.286 0-8.951-7.256-16.208-16.207-16.208s-16.209 7.257-16.209 16.208c0 .934.026 1.852.1 2.745.372 4.466.577 4.107-2.841 4.565l-.194.026-68 .075c-4.924-.246-4.419.691-4.038-4.738.061-.87.079-1.764.079-2.673 0-8.951-7.256-16.208-16.207-16.208s-16.207 7.257-16.207 16.208c0 .493.01.981.034 1.465.157 3.146.617 3.53-1.721 3.98l-.172.033-4.243-.14c-.956-.03-1.76-.42-2.44-.926-.888-.662-1.352-1.534-1.858-3.334-.528-1.875-.562-4.63-.411-6.72.067-.924-.102-1.674.203-2.235.289-.538.324-.304 1.242-.38.573-1.378 1.167-3.656 2.422-6.085 1.151-2.228 2.783-3.963 6.53-4.892 11.582-2.872 24.274-5.194 35.707-6.407 9.447-4.46 18.523-10.18 28.482-13.345C85.064-.867 101.67.465 112.842.465z"
        fill={color}
      />
    </g>
  </svg>
);

export default Sedan;
