import { useMutation } from '@apollo/client';
import CustodianProfileForm from 'components/custodians/CustodianProfileForm';
import LayoutWithQuery from 'components/layout/LayoutWithQuery.tsx';
import { loader } from 'graphql.macro';
import { useTracking } from 'lib/analytics/Tracker';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const PROFILE_QUERY = loader('../data/queries/ProfileQuery.graphql');
const UPDATE_CUSTODIAN_MUTATION = loader(
  '../data/mutations/UpdateCustodianMutation.graphql',
);

function CustodianProfileContainer() {
  const [updateError, setUpdateError] = useState(null);
  const tracker = useTracking();
  const history = useHistory();

  useEffect(() => {
    tracker.track('Coordinator Profile Viewed');
  }, [tracker]);

  const onCustodianUpdated = data => {
    const error = data.updateCustodian.error;

    if (error) {
      onCustodianUpdatedError(error);
    } else {
      history.goBack();
    }
  };

  const onCustodianUpdatedError = error => {
    if (error) {
      setUpdateError(error);
    }
  };

  const [updateCustodian] = useMutation(UPDATE_CUSTODIAN_MUTATION, {
    refetchQueries: [{ query: PROFILE_QUERY }],
    onCompleted: onCustodianUpdated,
    onError: onCustodianUpdatedError,
  });

  const custodianToPersist = data => {
    const { phone, notifPrefs, firstName, lastName, email, password } = data;
    return {
      phone,
      notifPrefs,
      firstName,
      lastName,
      email,
      password,
    };
  };

  const onUpdate = data => {
    tracker.track('Custodian Profile Updated');
    // alert(JSON.stringify(riderToPersist(data), null, 2));

    updateCustodian({ variables: { custodian: custodianToPersist(data) } });
  };

  return (
    <LayoutWithQuery
      query={PROFILE_QUERY}
      renderView={data => (
        <CustodianProfileForm
          custodian={data.profile}
          updateError={updateError}
          onUpdate={onUpdate}
        />
      )}
    />
  );
}

export default CustodianProfileContainer;
