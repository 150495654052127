import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

import onwardColors from './onwardColors';

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: onwardColors.onwardMedPurple,
      contrastText: '#fff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: onwardColors.onwardLightBlue,
      contrastText: '#fff',
    },
    error: {
      main: onwardColors.onwardRed,
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: ['Montserrat', 'Helvetica Neue', 'Arial', 'sans-serif'].join(
      ',',
    ),
  },
});

export default responsiveFontSizes(theme);
