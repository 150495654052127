import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ENV from '../../constants/Env';

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'left',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  showButton: {
    fontSize: '0.75rem',
    fontWeight: 500,
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette.info.main,
    border: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    '&:hover': {
      color: theme.palette.info.dark,
    },
    '&:focus': {
      color: theme.palette.info.dark,
    },
  },
  removeButton: {
    color: theme.palette.grey[600],
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      color: 'white',
    },
  },
  removeIcon: {
    fontSize: '1.1rem',
  },
  photo: {
    width: '100%',
    height: '100%',
  },
}));

const DeleteConfirmation = ({
  show,
  onClose,
  onDelete,
}: {
  show: boolean;
  onClose: () => void;
  onDelete: () => void;
}) => {
  const handleDelete = () => {
    onClose();
    onDelete();
  };

  return (
    <Dialog
      open={show}
      fullWidth={true}
      maxWidth="xs"
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Are you sure you want to remove this photo from the ride? The Driver
          will not be able to see it during the ride.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          No
        </Button>
        <Button onClick={handleDelete} color="primary" variant="contained">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const LocationPhoto = ({
  locationPhoto,
  isDeleted,
  onDeletePhoto,
}: {
  locationPhoto: string | null;
  isDeleted?: boolean;
  onDeletePhoto?: () => void;
}) => {
  const classes = useStyles();
  const [showPhoto, setShowPhoto] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handlePhotoClose = () => {
    setShowPhoto(false);
  };

  const handleDeletePhoto = () => {
    onDeletePhoto?.();
  };

  if (isDeleted === true) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.actions}>
        <button
          type="button"
          className={classes.showButton}
          onClick={() => setShowPhoto(true)}
        >
          Show Location Photo
        </button>

        {onDeletePhoto && (
          <IconButton
            size="small"
            className={classes.removeButton}
            onClick={() => setShowDeleteConfirmation(true)}
          >
            <DeleteIcon className={classes.removeIcon} />
          </IconButton>
        )}
      </div>

      <Dialog
        open={showPhoto}
        fullWidth={true}
        maxWidth="sm"
        keepMounted
        onClose={handlePhotoClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <img
              src={ENV.apiUrl + locationPhoto}
              alt=""
              className={classes.photo}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePhotoClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <DeleteConfirmation
        show={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onDelete={handleDeletePhoto}
      />
    </div>
  );
};
