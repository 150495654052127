import { createContext, ReactNode, useContext } from 'react';

const VETRIDE_QUERY_PARAM = 'vetrideRequestId';

export const VetridesContext = createContext<{
  isVetride: boolean;
  vetrideRequestId: number | null;
}>({ isVetride: false, vetrideRequestId: null });

export default function VetridesProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { search } = window.location;
  const urlParams = new URLSearchParams(search);
  const vetrideRequestIdParam = urlParams.get(VETRIDE_QUERY_PARAM);

  const isVetride = vetrideRequestIdParam ? true : false;
  const vetrideRequestId = vetrideRequestIdParam
    ? Number(vetrideRequestIdParam)
    : null;

  return (
    <VetridesContext.Provider value={{ isVetride, vetrideRequestId }}>
      {children}
    </VetridesContext.Provider>
  );
}

export function useVetridesContext() {
  const { isVetride, vetrideRequestId } = useContext(VetridesContext);

  const generateVetrideParams = () => {
    if (vetrideRequestId) {
      const query = new URLSearchParams();
      query.set(VETRIDE_QUERY_PARAM, vetrideRequestId.toString());
      return query.toString();
    }

    return null;
  };

  return {
    generateVetrideParams,
    isVetride,
    vetrideRequestId,
  };
}
