import { createContext, useContext } from 'react';
import MixpanelAnalytics from './mixpanel-analytics';
import { useAuth } from '../../contexts/auth-context';
import ENV from '../../constants/Env';

const trackerContext = createContext();

export function ProvideTracking({ children }) {
  const tracker = useProvideTracking();
  return (
    <trackerContext.Provider value={tracker}>
      {children}
    </trackerContext.Provider>
  );
}

export const useTracking = () => {
  return useContext(trackerContext);
};

function useProvideTracking() {
  const mixpanel = new MixpanelAnalytics(ENV.mixpanelKey);
  const auth = useAuth();

  const track = (name, props = {}) => {
    mixpanel.track(`Dashboard: ${name}`, {
      ...props,
      adminPerformed: !!(auth && auth.isUsingMagic),
    });
  };

  const identify = userId => {
    mixpanel.identify(userId);
  };

  const alias = userId => {
    mixpanel.alias(userId);
  };

  const reset = () => {
    mixpanel.reset();
  };

  const people_set = props => {
    mixpanel.people_set(props);
  };

  return {
    track,
    identify,
    alias,
    reset,
    people_set,
  };
}
