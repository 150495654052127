import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  centsToFormattedDollars,
  longDateOnly,
  mediumDateOnly,
  timeRange,
} from '@onwardcare/core';
import clsx from 'clsx';
import PersonWithName from 'components/PersonWithName';
import onwardColors from 'lib/onwardColors';
import { Link as RouterLink } from 'react-router-dom';
import LocationRow from '../LocationRow';
import InProgressIndicator from '../rides/InProgressIndicator';
import RideTypeBadge from '../rides/RideTypeBadge';
import TransportTypeBadge from '../rides/TransportTypeBadge';
import { useSessionContext } from '../../contexts/session-context';

const useStyles = makeStyles(theme => ({
  card: {
    borderTopWidth: 3,
    borderTopStyle: 'solid',
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  onewayCard: {
    borderTopColor: onwardColors.onwardGreen,
  },
  roundtripCard: {
    borderTopColor: onwardColors.onwardBlue,
  },
  deliveryCard: {
    borderTopColor: onwardColors.onwardMediumBlue,
  },
  timeLine: {
    lineHeight: '1.25',
  },
  leftSide: {
    marginRight: theme.spacing(3),
  },
  personBox: {
    marginTop: theme.spacing(1),
  },
  fixedHeight: {
    [theme.breakpoints.down('sm')]: {
      height: 220,
    },
    [theme.breakpoints.up('sm')]: {
      height: 200,
    },
  },
  cardContent: {
    height: '100%',
  },
  bold: {
    fontWeight: 600,
  },
}));

const CardHeader = ({ ride }) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      className={classes.header}
    >
      <Box display="flex" alignItems="end">
        <RideTypeBadge rideType={ride.rideType} />
        <Box ml={1}>
          <TransportTypeBadge transportType={ride.transportType} />
        </Box>
      </Box>

      {ride.status === 'inprogress' && <InProgressIndicator />}
      <Box>
        <Typography className={classes.bold} variant="body2">
          {centsToFormattedDollars(ride.estimatedCostCents)}
        </Typography>
      </Box>
    </Box>
  );
};

const CardBody = ({ ride }) => {
  const classes = useStyles();
  const { session } = useSessionContext();
  const theme = useTheme();
  const largeDate = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid container spacing={3} style={{ height: '100%' }}>
      <Grid item xs={6}>
        <Box display="flex" height="100%" flexDirection="column">
          <Box flexGrow={1} height="100%">
            <Typography
              className={classes.timeLine && classes.bold}
              variant="subtitle1"
            >
              {largeDate
                ? longDateOnly(ride.requestedStartTime, {
                    timeZone: session?.account?.tzName,
                  })
                : mediumDateOnly(ride.requestedStartTime, {
                    timeZone: session?.account?.tzName,
                  })}
            </Typography>
            <Typography
              className={classes.timeLine && classes.bold}
              variant="subtitle1"
            >
              {timeRange(ride.requestedStartTime, ride.requestedEndTime, {
                timeZone: session?.account?.tzName,
              })}
            </Typography>
          </Box>
          <Box>
            <Box className={classes.personBox}>
              <Typography variant="subtitle2">Rider</Typography>
              <PersonWithName person={ride.rider} nameType="full" />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" height="100%" flexDirection="column">
          <Box flexGrow={1}>
            <LocationRow
              location={ride.startLocation}
              showAddress={
                !ride.startLocation.address
                  .toLowerCase()
                  .includes(ride.startLocation.name.toLowerCase())
              }
            />
            <LocationRow
              location={ride.endLocation}
              showAddress={
                !ride.endLocation.address
                  .toLowerCase()
                  .includes(ride.endLocation.name.toLowerCase())
              }
            />
          </Box>
          {ride.driver && (
            <Box className={classes.personBox}>
              <Typography variant="subtitle2">Driver</Typography>
              <PersonWithName
                person={ride.driver}
                nameType="firstLastInitial"
              />
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

const RideCard = ({ ride, fixedHeight = true }) => {
  const classes = useStyles();

  const rideCardStyle = () => {
    switch (ride.rideType) {
      case 'oneway':
        return classes.onewayCard;
      case 'delivery':
        return classes.deliveryCard;
      default:
        return classes.roundtripCard;
    }
  };

  return (
    <CardActionArea component={RouterLink} to={`/rides/${ride.id}`}>
      <Card
        className={clsx(
          classes.card,
          rideCardStyle(),
          fixedHeight && classes.fixedHeight,
        )}
      >
        <CardContent className={classes.cardContent}>
          <CardHeader ride={ride} />
          <CardBody ride={ride} />
        </CardContent>
      </Card>
    </CardActionArea>
  );
};

export default RideCard;
