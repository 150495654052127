import React from 'react';
import {
  MncsPromptField,
  useRideMncsPromptsQueryQuery,
} from '../../../../generated/graphql';

export type SubType = {
  name: string;
  promts: {
    name?: string | null;
    value: string;
    notes?: string | null;
    type: MncsPromptField;
  }[];
};

export type MedicalNecessityElementType = {
  group?: string | null;
  subGroup: SubType[];
};

export type MedicalNecessitiesType = Array<MedicalNecessityElementType>;

export const useData = () => {
  const { data } = useRideMncsPromptsQueryQuery();
  return React.useMemo(() => {
    if (data) {
      return data.rideMncsPrompts?.reduce((acc, prompt) => {
        if (prompt.group1) {
          const element = acc.find(el => el.group === prompt.group1);
          if (element) {
            const subElement = element.subGroup.find(
              el => el.name === prompt.group2,
            );

            if (subElement) {
              subElement.promts.push({
                name: prompt.name,
                value: prompt.slug,
                notes: prompt.notes,
                type: prompt.fieldType,
              });
            } else {
              const subElement = {
                name:
                  prompt.fieldType === MncsPromptField.Date
                    ? prompt.name || prompt.group2
                    : prompt.group2,
                promts: [
                  {
                    name: prompt.name,
                    value: prompt.slug,
                    notes: prompt.notes,
                    type: prompt.fieldType,
                  },
                ],
              };
              element.subGroup.push(subElement);
            }
          } else {
            const element: MedicalNecessityElementType = {
              group: prompt.group1,
              subGroup: [
                {
                  name: prompt.group2 || prompt.name || '',
                  promts: [
                    {
                      name: prompt.name,
                      value: prompt.slug,
                      notes: prompt.notes,
                      type: prompt.fieldType,
                    },
                  ],
                },
              ],
            };
            acc.push(element);
          }
        } else {
          let subElement: SubType | undefined;
          let subElementIndex = -1;

          acc.forEach(el => {
            subElementIndex = el.subGroup.findIndex(
              subEl => subEl.name === prompt.group2,
            );
            if (subElementIndex !== -1) {
              subElement = el.subGroup[subElementIndex];
            }
          });

          if (subElement && subElementIndex !== -1) {
            subElement.promts.push({
              name: prompt.name,
              value: prompt.slug,
              notes: prompt.notes,
              type: prompt.fieldType,
            });
          } else {
            subElement = {
              name:
                prompt.fieldType === MncsPromptField.Date
                  ? prompt.name || prompt.group2
                  : prompt.group2,
              promts: [
                {
                  name: prompt.name,
                  value: prompt.slug,
                  notes: prompt.notes,
                  type: prompt.fieldType,
                },
              ],
            };
            acc.push({
              subGroup: [subElement],
            });
          }
        }
        return acc;
      }, [] as MedicalNecessitiesType);
    }
  }, [data]);
};
