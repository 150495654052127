import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { MncsPromptField } from '../../../../generated/graphql';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { LabelWithTooltip } from '../../../LabelWithTooltip';
import { DatePicker } from '@material-ui/pickers';
import { useData } from './data';
import { useStyles } from './styles';
import { SplitNotes } from './SplitNotes';
import { format } from 'date-fns';

type Props = {
  control: Control;
  onGoBack: () => void;
  mncsOptions: Record<string, any>;
  errors: FieldErrors<any>;
  trigger: (name?: string | string[] | undefined) => Promise<boolean>;
  watchTransportType: string;
  showMedicalNecessity: boolean;
};

export const AddMedicalNecessityStatement: React.FC<Props> = React.memo(
  ({
    onGoBack,
    control,
    mncsOptions,
    errors,
    trigger,
    showMedicalNecessity,
    watchTransportType,
  }) => {
    const classes = useStyles();

    const handleSubmit = React.useCallback(async () => {
      let result = true;
      const errorsId = [];
      for (const optionKey of Object.keys(mncsOptions)) {
        const errorTest = await trigger(`mncsOptions.${optionKey}`);

        if (!errorTest) {
          errorsId.push(`mncsOptions.${optionKey}`);
        }

        result = result && errorTest;
      }

      if (result) {
        onGoBack();
      } else {
        document.getElementById(errorsId[0])?.focus();
      }
    }, [mncsOptions, trigger, onGoBack]);

    const medicalNecessities = useData();

    if (!showMedicalNecessity && !watchTransportType.match('ambulance')) {
      return null;
    }

    return (
      <Grid
        container
        wrap="nowrap"
        className={classes.container}
        style={{
          display:
            showMedicalNecessity && !!watchTransportType.match('ambulance')
              ? 'block'
              : 'none',
        }}
      >
        <Grid item xs={12}>
          <Paper className={classes.left}>
            <Button
              color="primary"
              startIcon={<ArrowBackIcon />}
              onClick={onGoBack}
            >
              Back
            </Button>
            <Typography component="h1" variant="h4">
              Medical Necessity Certification Statement
            </Typography>

            <Grid container>
              <Grid item xs={12}>
                <Typography
                  className={classes.header}
                  component="p"
                  variant="body1"
                >
                  Please complete this form to indicate why Ambulance
                  transportation is medically necessary.
                </Typography>

                <Typography component="p" variant="body1">
                  Ambulance transportation is medically necessary only if other
                  means of transport are contraindicated or would be potentially
                  harmful to the patient.
                </Typography>

                <Typography component="p" variant="body1">
                  To meet this requirement, the patient must be "bed confined"
                  and/or suffer from a condition such that transport by means
                  other than ambulance is contraindicated.
                </Typography>

                <Typography component="p" variant="body1">
                  The following questions must be answered by the medical
                  professional signing below
                </Typography>
              </Grid>
            </Grid>
            <Grid container lg={12} md={12} xs={12}>
              {medicalNecessities &&
                medicalNecessities.map(element => {
                  return (
                    <>
                      {element.group ? (
                        <Typography
                          style={{ marginTop: '2rem' }}
                          component="h4"
                          variant="h5"
                        >
                          {element.group}
                        </Typography>
                      ) : null}
                      <Grid
                        className={!!element.group ? classes.group : ''}
                        item
                        lg={!!element.group ? 12 : 6}
                        md={!!element.group ? 12 : 6}
                        xs={12}
                      >
                        {element.subGroup.map(subElement => (
                          <Grid
                            style={{
                              paddingTop: '1rem',
                            }}
                            item
                            xs={12}
                          >
                            <Typography component="h4" variant="h6">
                              {subElement.name}
                            </Typography>
                            {subElement.promts.map(p => {
                              if (p.type === MncsPromptField.Boolean) {
                                return (
                                  <div>
                                    <FormControlLabel
                                      control={
                                        <Controller
                                          name={`mncsOptions.${p.value}`}
                                          control={control}
                                          render={props => {
                                            return (
                                              <Checkbox
                                                id={`mncsOptions.${p.value}`}
                                                onChange={e => {
                                                  props.onChange(
                                                    e.target.checked,
                                                  );
                                                }}
                                                checked={!!props.value}
                                              />
                                            );
                                          }}
                                        />
                                      }
                                      label={
                                        <LabelWithTooltip
                                          labelText={
                                            p.name || (
                                              <SplitNotes notes={p.notes} />
                                            ) ||
                                            null
                                          }
                                          tooltipText={p.name ? p.notes : ''}
                                        />
                                      }
                                    />
                                  </div>
                                );
                              } else if (p.type === MncsPromptField.String) {
                                return (
                                  <FormControl
                                    className={classes.block}
                                    error={!!errors?.['mncsOptions']?.[p.value]}
                                  >
                                    <Controller
                                      name={`mncsOptions.${p.value}`}
                                      control={control}
                                      rules={{
                                        required:
                                          p.value === 'details_of_condition',
                                      }}
                                      render={props => {
                                        return (
                                          <TextField
                                            helperText={
                                              errors?.['mncsOptions']?.[
                                                p.value
                                              ] && (
                                                <FormHelperText error>
                                                  Value is required
                                                </FormHelperText>
                                              )
                                            }
                                            id={`mncsOptions.${p.value}`}
                                            multiline
                                            rows={3}
                                            fullWidth
                                            label={
                                              <FormHelperText
                                                error={
                                                  !!errors?.['mncsOptions']?.[
                                                    p.value
                                                  ]
                                                }
                                              >
                                                {p.name}
                                              </FormHelperText>
                                            }
                                            value={props.value}
                                            onChange={e => {
                                              props.onChange(e.target.value);
                                            }}
                                          />
                                        );
                                      }}
                                    />
                                  </FormControl>
                                );
                              } else if (p.type === MncsPromptField.Date) {
                                return (
                                  <div>
                                    <Controller
                                      name={`mncsOptions.${p.value}`}
                                      control={control}
                                      render={({
                                        ref,
                                        onChange,
                                        value,
                                        ...rest
                                      }) => {
                                        return (
                                          <DatePicker
                                            id={`mncsOptions.${p.value}`}
                                            autoOk
                                            disableFuture
                                            format="l"
                                            label={p.name}
                                            value={value || null}
                                            error={
                                              !!errors?.['mncsOptions']?.[
                                                p.value
                                              ]
                                            }
                                            helperText={
                                              errors?.['mncsOptions']?.[
                                                p.value
                                              ] && 'Date of Birth is required'
                                            }
                                            onChange={result => {
                                              onChange(
                                                result
                                                  ? // TODO: Should this use tz?
                                                    format(result, 'yyyy-MM-dd')
                                                  : null,
                                              );
                                            }}
                                            {...rest}
                                          />
                                        );
                                      }}
                                    />
                                  </div>
                                );
                              }
                              return null;
                            })}
                          </Grid>
                        ))}
                      </Grid>
                    </>
                  );
                })}
            </Grid>

            <Grid style={{ marginTop: '1rem' }}>
              <Typography
                className={classes.header}
                component="p"
                variant="body1"
              >
                By submitting this Medical Necessity Certification Statement, I
                acknowledge that:
              </Typography>
              <Typography component="p" variant="body1">
                a) Use of other methods of transportation is contraindicated by
                the patient's condition.
              </Typography>

              <Typography component="p" variant="body1">
                b) Medicare coverage is not available if the decision to use an
                ambulance service is based on the convenience of the patient,
                family, physician, or some other element of personal preference.
              </Typography>
              <Typography component="p" variant="body1">
                c) I certify that the information listed above represents an
                accurate assessment of the patient's status and need for
                ambulance transportation.
              </Typography>
              <Grid style={{ display: 'flex' }}>
                <Button
                  key="okButton"
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleSubmit}
                >
                  Save and continue
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  },
);
