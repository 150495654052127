import {
  Avatar,
  Box,
  Button,
  Container,
  Typography,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTracking } from 'lib/analytics/Tracker';
import onwardColors from 'lib/onwardColors';
import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import onwardPin from '../images/logo192.png';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://onwardrides.com/">
        Onward Health, Inc.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(18),
    height: theme.spacing(18),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  welcomeText: {
    color: onwardColors.onwardDarkGray,
    marginBottom: theme.spacing(3),
  },
  getStarted: {
    marginTop: theme.spacing(8),
  },
  loginLink: {
    marginLeft: theme.spacing(0.5),
  },
}));

export default function Welcome() {
  const classes = useStyles();
  const tracker = useTracking();

  useEffect(() => {
    tracker.track('Welcome Page Viewed');
  }, [tracker]);

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar alt="Onward Pin" className={classes.avatar} src={onwardPin} />
        <Typography component="h1" variant="h4" className={classes.welcomeText}>
          Welcome to Onward
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Get started by creating an account so we can provide your riders with
          safe and convenient transportation.
        </Typography>
        <Button
          color="secondary"
          href="https://onwardrides.com/"
          target="_blank"
        >
          Learn More
        </Button>
        <Button
          className={classes.getStarted}
          color="primary"
          variant="contained"
          component={RouterLink}
          to="/start"
          fullWidth
        >
          Get Started
        </Button>
        <Box display="flex" alignItems="center" mt={2}>
          <Typography>Have an account?</Typography>
          <Link
            className={classes.loginLink}
            component={RouterLink}
            to="/login"
            variant="body2"
          >
            Log in
          </Link>
        </Box>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
