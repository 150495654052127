import * as React from 'react';

type CommonIconProp = {
  color?: string;
  height?: number;
  width?: number;
};

export type Ref = SVGSVGElement;

export const ElderlyIcon = React.forwardRef<Ref, CommonIconProp>(
  function MyComponent(props, ref) {
    const { color, height = 24, width = 24, ...rest } = props;
    return (
      <svg
        ref={ref}
        {...rest}
        aria-hidden="true"
        viewBox="0 0 24 24"
        data-testid="ElderlyIcon"
        height={height}
        width={width}
        color={color}
      >
        <path d="M13.5 5.5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6.5 7V23h-1V12.5c0-.28-.22-.5-.5-.5s-.5.22-.5.5v1h-1v-.69a6.02 6.02 0 0 1-3.51-2.52c-.31.87-.49 1.78-.49 2.71 0 .23.02.46.03.69L15 16.5V23h-2v-5l-1.78-2.54L11 19l-3 4-1.6-1.2L9 18.33V13c0-1.15.18-2.29.5-3.39l-1.5.85V14H6V9.3l5.4-3.07v.01a2 2 0 0 1 1.94.03c.36.21.63.51.8.85l.79 1.67A3.987 3.987 0 0 0 18.5 11c.83 0 1.5.67 1.5 1.5z" />
      </svg>
    );
  },
);
