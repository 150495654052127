import { List, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSessionContext } from '../contexts/session-context';

const useStyles = makeStyles(theme => ({
  sessionInfo: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(2),
  },
  sessionInfoItem: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(0),
  },
}));

export default function SessionInformation() {
  const classes = useStyles();
  const { session } = useSessionContext();
  const { account, profile } = session || {};

  return session ? (
    <List dense className={classes.sessionInfo}>
      {account?.name && (
        <ListItem className={classes.sessionInfoItem}>
          <ListItemText secondary={account.name} />
        </ListItem>
      )}

      {(profile?.firstName || profile?.lastName) && (
        <ListItem className={classes.sessionInfoItem}>
          <ListItemText
            secondary={`${profile?.firstName} ${profile?.lastName}`}
          />
        </ListItem>
      )}
    </List>
  ) : null;
}
