const METRIC_KEY = 'metric';

/**
 * Writes the "is metric" setting to local storage.
 *
 * @param isMetric Whether metric units should be used.
 */
export function writeIsMetric(isMetric: boolean) {
  window.localStorage.setItem(METRIC_KEY, isMetric.toString());
}

/**
 * Reads the "is metric" setting from local storage.
 *
 * @returns Returns true if metric units should be used, false otherwise.
 */
export function readIsMetric() {
  const value = window.localStorage.getItem(METRIC_KEY);
  return value === 'true';
}
