import CircularProgress from '@material-ui/core/CircularProgress';

export default function PageLoadingSpinner() {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        left: 'calc(50% - 60px)',
      }}
    >
      <CircularProgress size={60} />
    </div>
  );
}
