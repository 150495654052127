import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';
import {
  Maybe,
  useRequestRideFeedbackMutationMutation,
} from '../../generated/graphql';

type Props = {
  email?: Maybe<string> | undefined;
  phone?: Maybe<string> | undefined;
  handleClose: () => void;
  showDialog: boolean;
  setFeedbackSent: (sent: boolean) => void;
  rideId: string;
};

export const FeedbackForm: React.FC<Props> = ({
  phone,
  email,
  showDialog,
  handleClose,
  setFeedbackSent,
  rideId,
}) => {
  const [errorMessage, setErrorMessage] = useState('');

  const defaultValues = {
    email,
    phone,
    shouldUpdateProfile: true,
  };
  const { handleSubmit, errors, control } = useForm({ defaultValues });

  const onCustodianAddedError = (error: any) => {
    if (error) {
      setErrorMessage(
        'There was an unexpected error inviting the new coordinator. Please wait a minute and then try again.',
      );
    }
  };

  const [
    requestRIdeFeedback,
    { loading },
  ] = useRequestRideFeedbackMutationMutation({
    onCompleted: data => {
      setFeedbackSent(true);
      handleClose();
    },
    onError: onCustodianAddedError,
  });

  const submit = (data: typeof defaultValues) => {
    requestRIdeFeedback({
      variables: {
        RequestRideFeedback: {
          email: data.email,
          phone: data.phone,
          updateRiderProfile: data.shouldUpdateProfile,
          rideId,
        },
      },
    });
  };

  return (
    <Dialog
      open={showDialog}
      fullWidth={true}
      maxWidth="sm"
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Send Feedback Request
        </DialogContentText>
        <form noValidate onSubmit={handleSubmit(submit)}>
          <Grid container>
            <Grid item xs={6}>
              <FormControl error={!!errors.email}>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="email"
                  render={({ onChange, ...rest }) => (
                    <TextField
                      disabled={loading}
                      error={!!errors.email}
                      helperText={errors.email && 'Email is required'}
                      label="Email *"
                      onChange={e => {
                        errorMessage && setErrorMessage('');
                        onChange(e.target.value);
                      }}
                      {...rest}
                    />
                  )}
                ></Controller>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl error={!!errors.phone}>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="phone"
                  render={({ onChange, ...rest }) => (
                    <TextField
                      disabled={loading}
                      error={!!errors.phone}
                      helperText={errors.phone && 'Phone is required'}
                      label="Phone *"
                      onChange={e => {
                        errorMessage && setErrorMessage('');
                        onChange(e.target.value);
                      }}
                      {...rest}
                    />
                  )}
                ></Controller>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Controller
                      name="shouldUpdateProfile"
                      control={control}
                      render={props => {
                        return (
                          <Checkbox
                            disabled={loading}
                            onChange={e => {
                              props.onChange(e.target.checked);
                            }}
                            checked={!!props.value}
                          />
                        );
                      }}
                    />
                  }
                  label="Update Rider profile?"
                />
              </FormControl>
            </Grid>

            {!!errorMessage ? (
              <Grid>
                <Typography>{errorMessage}</Typography>
              </Grid>
            ) : null}
            <Grid xs={12} container direction="row" justifyContent="flex-end">
              <Button disabled={loading} variant="outlined" type="submit">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
