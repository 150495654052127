import {
  Button,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  CollectionMetadata,
  Rider,
  useRidersPaginationQueryLazyQuery,
} from '../../generated/graphql';
import debounce from 'lodash/debounce';
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
  MUISortOptions,
} from 'mui-datatables';
import cloneDeep from 'lodash/cloneDeep';
import { ridersStorage } from '../../storages/storage';
import { RiderImpairments } from './RiderImpairments';

const useStyles = makeStyles(theme => ({
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  root: {
    width: '100%',
  },
  search: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 300,
    marginBottom: theme.spacing(2),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

type SortType = {
  sortDirection: MUISortOptions['direction'];
  sortBy: string;
};

const defaultLimits = {
  page: 0,
  limit: 25,
  sort: null,
};

export const RidersTable = () => {
  const classes = useStyles();
  const history = useHistory();

  const [filteredRiders, setFilteredRiders] = useState<
    Rider[] | undefined | null
  >(null);

  const [
    getPagingRider,
    { data: pagingRiderData },
  ] = useRidersPaginationQueryLazyQuery({ fetchPolicy: 'no-cache' });

  const [limits, setLimits] = useState<{
    page: number;
    limit: number;
    sort: SortType | null;
  }>(defaultLimits);

  const [search, setSearch] = useState('');

  const [metaData, setMetaData] = useState<CollectionMetadata>({
    currentPage: 0,
    limitValue: 0,
    totalCount: 0,
    totalPages: 0,
  });

  const debouncedSearch = useCallback(debounce(setSearch, 300), []);

  useEffect(() => {
    getPagingRider({
      variables: {
        query: search,
        page: limits.page,
        limit: limits.limit,
        ...limits.sort,
      },
    });
  }, [getPagingRider, limits, search]);

  useEffect(() => {
    const rideHistoryTableOptions = ridersStorage.getData();

    if (rideHistoryTableOptions) {
      const { limit, sortingValue, columnView, page } = rideHistoryTableOptions;

      setLimits(prev => ({
        ...prev,
        page: page || 0,
        limit: limit || 25,
        sort: sortingValue
          ? {
              sortBy: sortingValue[0],
              sortDirection: sortingValue[1],
            }
          : null,
      }));

      if (columnView) {
        setColumns(prev => {
          let changedColumns = cloneDeep(prev);
          const newDefaultColumns = cloneDeep(defaultColumns);

          Object.entries(columnView).forEach(([key, value]) => {
            changedColumns = newDefaultColumns.map(changeColumn => {
              if (changeColumn.name === key) {
                changeColumn.options = {
                  ...changeColumn.options,
                  display: value !== 'remove',
                };
              }

              return changeColumn;
            });
          });
          return changedColumns;
        });
      }
    }
  }, []);

  useEffect(() => {
    if (pagingRiderData && pagingRiderData.ridersCollection) {
      setFilteredRiders([...pagingRiderData.ridersCollection.collection]);
      setMetaData({ ...pagingRiderData.ridersCollection.metadata });
    }
  }, [pagingRiderData]);

  const optionsMemo = useMemo<MUIDataTableOptions>(() => {
    return {
      customToolbar: () => {
        return (
          <Tooltip title={'Reset Options to Default'}>
            <IconButton
              onClick={() => {
                setColumns(prev => {
                  let changedColumns = cloneDeep(prev);

                  changedColumns = changedColumns.map((changeColumn, ind) => {
                    changeColumn.options = {
                      ...changeColumn.options,
                      display: defaultColumns[ind]?.options?.display ?? true,
                    };

                    return changeColumn;
                  });
                  return changedColumns;
                });
                setLimits(defaultLimits);
                ridersStorage.clearStorage();
              }}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        );
      },
      onColumnSortChange: (
        changedColumn: string,
        sortDirection: MUISortOptions['direction'],
      ) => {
        setLimits(prev => ({
          ...prev,
          sort: { sortDirection, sortBy: changedColumn },
        }));

        ridersStorage.setData({ sortingValue: [changedColumn, sortDirection] });
      },
      print: false,
      filter: false,
      filterType: 'dropdown',
      tableBodyHeight: '75%',
      selectableRows: 'none',
      expandableRowsHeader: false,
      jumpToPage: true,
      page: metaData.currentPage - 1,
      rowsPerPage: limits.limit,
      rowsPerPageOptions: [5, 10, 15, 25],
      onChangeRowsPerPage: (limit: number) => {
        setLimits(prev => ({
          ...prev,
          limit,
        }));
        ridersStorage.setData({ limit });
      },
      onViewColumnsChange: (changedColumn: string, action: string) => {
        setColumns(prev => {
          let changedColumns = cloneDeep(prev);

          changedColumns.forEach(column => {
            if (column.name === changedColumn) {
              column.options = {
                ...column.options,
                display: action !== 'remove',
              };
            }
          });

          return changedColumns;
        });
        ridersStorage.setData({
          columnView: { [changedColumn]: action as 'remove' | 'add' },
        });
      },
      onSearchChange: text => {
        if (text) {
          debouncedSearch(text);
        }
      },
      onSearchClose: () => setSearch(''),
      onChangePage: currentPage => {
        setLimits(prev => ({
          ...prev,
          page: currentPage + 1,
        }));
        ridersStorage.setData({ page: currentPage + 1 });
      },
      count: metaData.totalCount,
      serverSide: true,
      onRowClick: (rowData, rowMeta) => {
        if (!filteredRiders) {
          return;
        }
        history.push(`/riders/${filteredRiders[rowMeta.rowIndex].id}`);
      },
      textLabels: {
        body: {
          noMatch:
            filteredRiders === null || filteredRiders === undefined
              ? "Before you can book a ride, you'll need to add a rider."
              : 'There are no riders that match your filters',
        },
      },
    };
  }, [limits, metaData, filteredRiders, debouncedSearch]);

  const defaultColumns: MUIDataTableColumn[] = React.useMemo(
    () => [
      {
        name: 'id',
        options: {
          display: true,
          filter: true,
          sortThirdClickReset: true,
        },
      },
      {
        name: 'firstName',
        label: 'First Name',
        options: {
          sort: true,
          sortThirdClickReset: true,
        },
      },
      {
        name: 'lastName',
        label: 'Last Name',
        options: {
          sort: true,
          sortThirdClickReset: true,
        },
      },
      {
        name: 'phone',
        label: 'Phone',
        options: {
          sort: true,
          sortThirdClickReset: true,
        },
      },
      {
        name: 'email',
        label: 'Email',
        options: {
          sort: true,
          sortThirdClickReset: true,
        },
      },
      {
        name: 'dob',
        label: 'DOB',
        options: {
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (...args) => {
            const currentRider = filteredRiders?.[args[1].rowIndex];
            return (
              currentRider && (
                <span style={{ whiteSpace: 'nowrap' }}>{currentRider.dob}</span>
              )
            );
          },
        },
      },
      {
        name: 'mobilityAssistance',
        label: 'Rider Impairments',
        options: {
          sort: false,
          customBodyRender: (...args) => {
            const currentRider = filteredRiders?.[args[1].rowIndex];
            return (
              currentRider && (
                <RiderImpairments
                  rider={currentRider}
                  style={{ textAlign: 'center', height: 22 }}
                />
              )
            );
          },
        },
      },
    ],
    [filteredRiders],
  );

  const [columns, setColumns] = React.useState<MUIDataTableColumn[]>(
    defaultColumns,
  );

  useEffect(() => {
    const rideHistoryTableOptions = ridersStorage.getData();
    if (rideHistoryTableOptions) {
      const { columnView } = rideHistoryTableOptions;
      if (columnView) {
        setColumns(prev => {
          let changedColumns = cloneDeep(prev);
          const newDefaultColumns = cloneDeep(defaultColumns);

          Object.entries(columnView).forEach(([key, value]) => {
            changedColumns = newDefaultColumns.map(defaultColumn => {
              if (defaultColumn.name === key) {
                defaultColumn.options = {
                  ...defaultColumn.options,
                  display: value !== 'remove',
                };
              }

              return defaultColumn;
            });
          });
          return changedColumns;
        });
      } else {
        setColumns(defaultColumns);
      }
    } else {
      setColumns(defaultColumns);
    }
  }, [defaultColumns]);

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <Typography variant="h4" gutterBottom>
          Rider Profiles
        </Typography>

        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to="/riders/select"
        >
          Add Rider
        </Button>
      </header>

      <MUIDataTable
        title={'Profiles'}
        data={filteredRiders || []}
        columns={columns}
        options={optionsMemo}
      />
    </div>
  );
};
