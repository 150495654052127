import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  sectionHeader: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
  },
}));

export default function SectionHeader({ title }) {
  const classes = useStyles();
  return (
    <Typography component="h2" variant="h5" className={classes.sectionHeader}>
      {title}
    </Typography>
  );
}
