import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ZeroStateText from 'components/ZeroStateText';
import React, { useEffect, useState } from 'react';
import CardRow from 'components/accounts/CardRow';
import { Elements } from '@stripe/react-stripe-js';
import { useLazyQuery } from '@apollo/client';
import { loadStripe } from '@stripe/stripe-js';
import { loader } from 'graphql.macro';
import { StripeForm } from '../../layout/StripeForm';
import {
  AccountQueryQuery,
  RemoveAccountPaymentMethodMutationVariables,
  StripeSetupIntentQueryQuery,
  StripeSetupIntentQueryQueryVariables,
  UpdateAccountPaymentMethodMutationMutationVariables,
} from '../../../generated/graphql';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { Stripe } from '@stripe/stripe-js/types/stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js/types/stripe-js/elements-group';
import { RemoveCardDialog } from '../../common/RemoveCardDialog';

const STRIPE_SETUP_INTENT_QUERY = loader(
  '../../../data/queries/StripeSetupIntentQuery.graphql',
);

const useStyles = makeStyles(theme => ({
  section: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  actionButton: {
    marginRight: theme.spacing(2),
  },
  rowSpacing: {
    marginBottom: theme.spacing(1),
  },
  helperText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cardStyle: {
    width: '50%',
  },
}));

export type PaymentsTabProps<TData> = {
  account: AccountQueryQuery['account'];
  onRemoveCard: (
    paymentMethodId: RemoveAccountPaymentMethodMutationVariables['paymentMethodId'],
  ) => void;
  onUpdatePaymentMethod: ({
    paymentMethodId,
    paymentMethod,
  }: UpdateAccountPaymentMethodMutationMutationVariables) => void;
  getAccount: (() => Promise<ApolloQueryResult<TData>>) | undefined;
};

export function PaymentsTab<TData>(
  props: PaymentsTabProps<TData>,
): React.ReactElement {
  const { account, onRemoveCard, onUpdatePaymentMethod, getAccount } = props;

  const classes = useStyles();

  const [isPrompt, setPrompt] = useState(false);
  const [selectedCard, setSelectedCard] = useState<string | null>(null);
  console.log('selectedCard', selectedCard);
  const [getSetupIntent, { data }] = useLazyQuery<
    StripeSetupIntentQueryQuery,
    StripeSetupIntentQueryQueryVariables
  >(STRIPE_SETUP_INTENT_QUERY, { fetchPolicy: 'no-cache' });

  const [
    stripeData,
    setStripeData,
  ] = useState<StripeSetupIntentQueryQuery | null>(null);
  const [
    stripePromise,
    setStripePromise,
  ] = useState<Promise<Stripe | null> | null>(null);
  const [
    stripeOptions,
    setStripeOptions,
  ] = useState<StripeElementsOptions | null>(null);

  useEffect(() => {
    if (data) {
      setStripeData(data);
      data?.stripeSetupIntent?.publishableKey &&
        setStripePromise(loadStripe(data?.stripeSetupIntent?.publishableKey));
      setStripeOptions({
        locale: 'en',
        clientSecret: data?.stripeSetupIntent?.setupIntent,
        appearance: {
          theme: 'flat',
          variables: {
            borderRadius: '0px',
          },
        },
      });
    }
  }, [data]);

  const handleClick = () => {
    if (account) {
      getSetupIntent({
        variables: { ownerType: 'Account', ownerId: account.id },
      });
    }
  };

  const renderCards = () => {
    if (account?.paymentMethods?.length) {
      return account?.paymentMethods?.map(pm => (
        <CardRow
          key={pm.id}
          paymentMethod={pm}
          onRemoveCard={(id: string) => {
            setPrompt(true);
            setSelectedCard(id);
          }}
          onUpdatePaymentMethod={onUpdatePaymentMethod}
        />
      ));
    } else {
      return (
        <Box className={classes.rowSpacing}>
          <ZeroStateText>
            You have no saved payment methods. To book a ride, add a payment
            method.
          </ZeroStateText>
        </Box>
      );
    }
  };

  return (
    <Box display="flex" flexDirection="column" className={classes.section}>
      <Typography variant="body1" className={classes.helperText}>
        Payment methods associated with your account will be available to use
        when booking a ride for any rider. You can also add rider specific
        payment methods on their profiles.
      </Typography>
      {renderCards()}

      <span>
        <Button
          onClick={handleClick}
          className={classes.actionButton}
          color="primary"
          startIcon={<AddIcon />}
        >
          Add Payment Method
        </Button>
      </span>

      {stripePromise && stripeOptions && (
        <div className={classes.cardStyle}>
          <Elements stripe={stripePromise} options={stripeOptions}>
            <StripeForm
              shouldShowAddButton={true}
              stripeData={stripeData}
              getSetupIntent={getSetupIntent}
              getAccount={getAccount}
              ownerType="Account"
              account={account}
            />
          </Elements>
        </div>
      )}

      <RemoveCardDialog
        handleClose={setPrompt}
        selectedCard={selectedCard}
        onRemoveCard={onRemoveCard}
        open={isPrompt}
      />
    </Box>
  );
}
