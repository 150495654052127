import MUIDataTable, {
  MUIDataTableOptions,
  MUISortOptions,
} from 'mui-datatables';
import React, {
  BaseSyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  AccountCustodiansPaginationQueryQuery,
  AccountQueryQuery,
  AddCustodianMutation,
  AddCustodianMutationVariables,
  Custodian,
  UpdateCustodianMutation,
  useAccountCustodiansPaginationQueryLazyQuery,
  useAccountCustodiansQueryLazyQuery,
  useAddCustodianMutation,
  useResetCustodianPasswordMutation,
  useUpdateCustodianMutation,
} from '../../../generated/graphql';
import ClearIcon from '@material-ui/icons/Clear';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import debounce from 'lodash/debounce';

import {
  IconButton,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { CSVLink } from 'react-csv';
import { cloneDeep } from 'lodash';
import {
  MUIDataTableColumn,
  useCSVCustodianData,
} from '../../useCSVCustodianData';
import { AddCoordinatorDialog } from './AddCoordinatorDialog';
import { useTracking } from 'lib/analytics/Tracker';
import { useToast } from '../../layout/Toast';
import { EditCoordinatorDialog } from './EditCoordinatorDialog';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { accountCustodianStorage } from '../../../storages/storage';

type SortType = {
  sortDirection: MUISortOptions['direction'];
  sortBy: string;
};

const defaultLimits = {
  page: 0,
  limit: 25,
  sort: null as any,
};

const ActionMenu: React.FC<{
  custodianId: string;
  setEditCustodianId: (id: string) => void;
  onResetPassword: (id: string) => void;
}> = ({ custodianId, setEditCustodianId, onResetPassword }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: BaseSyntheticEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const editMenuClicked = () => {
    handleClose();
    setEditCustodianId(custodianId);
  };

  const resetMenuClicked = () => {
    handleClose();
    onResetPassword(custodianId);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="coordinator-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={editMenuClicked}>Edit Coordinator Details</MenuItem>
        <MenuItem onClick={resetMenuClicked}>Reset Password</MenuItem>
      </Menu>
    </div>
  );
};

export const CoordinatorTabTable: React.FC<{
  account: AccountQueryQuery['account'];
}> = ({ account }) => {
  const tracker = useTracking();
  const toast = useToast();

  const [
    getPagingAccountCustodians,
    { data: accountCustodians },
  ] = useAccountCustodiansPaginationQueryLazyQuery({ fetchPolicy: 'no-cache' });

  const [
    getCustodianAccount,
    { data: custodianAccount },
  ] = useAccountCustodiansQueryLazyQuery({ fetchPolicy: 'no-cache' });

  const [
    getAllPagingAccountCustodians,
    {
      data: accountAllCustodians,
      loading: allDataLoading,
      error: allDataError,
    },
  ] = useAccountCustodiansPaginationQueryLazyQuery({ fetchPolicy: 'no-cache' });

  const onCustodianAdded = (data: AddCustodianMutation, message: string) => {
    if (data?.addCustodian?.error) {
      onCustodianAddedError(data.addCustodian.error);
    } else {
      closeAddModal();
      setErrorMessage(null);
      toast?.showMessage(message);
      getPagingAccountCustodians({
        variables: {
          page: limits.page,
          limit: limits.limit,
          query: search,
          ...limits.sort,
        },
      });
      getAllPagingAccountCustodians({
        variables: {
          page: 0,
          limit: 1000000,
          query: '',
        },
      });
    }
  };

  const onCustodianUpdatedError = (error: any) => {
    if (error) {
      setUpdateErrorMessage(
        'There was an unexpected error updating the coordinator. Please wait a minute and then try again.',
      );
    }
  };

  const onCustodianUpdated = (
    data: UpdateCustodianMutation,
    message: string,
  ) => {
    if (data?.updateCustodian?.error) {
      onCustodianUpdatedError(data.updateCustodian.error);
    } else {
      setEditCustodianId(null);
      setErrorMessage(null);
      toast?.showMessage(message);
      getPagingAccountCustodians({
        variables: {
          page: limits.page,
          limit: limits.limit,
          query: search,
          ...limits.sort,
        },
      });
      getAllPagingAccountCustodians({
        variables: {
          page: 0,
          limit: 1000000,
          query: '',
        },
      });
    }
  };

  const onCustodianAddedError = (error: any) => {
    if (error) {
      setErrorMessage(
        'There was an unexpected error inviting the new coordinator. Please wait a minute and then try again.',
      );
    }
  };

  const [addCustodian] = useAddCustodianMutation({
    onCompleted: (data: AddCustodianMutation) =>
      onCustodianAdded(data, 'Coordinator added successfully.'),
    onError: onCustodianAddedError,
  });

  const [resetCustodianPassword] = useResetCustodianPasswordMutation();
  const [updateCustodian] = useUpdateCustodianMutation({
    onCompleted: (data: UpdateCustodianMutation) =>
      onCustodianUpdated(data, 'Coordinator updated successfully.'),
  });

  const [
    result,
    setResult,
  ] = useState<AccountCustodiansPaginationQueryQuery | null>(null);

  const custodians = result?.accountCustodiansCollection.collection;
  const metaData = result?.accountCustodiansCollection.metadata;

  const accountCustodiansOptions = accountCustodianStorage.getData();

  let initialLimits = defaultLimits;

  if (accountCustodiansOptions) {
    const { limit, sortingValue, page } = accountCustodiansOptions;
    initialLimits = {
      page: page || 0,
      limit: limit || 25,
      sort: sortingValue
        ? {
            sortBy: sortingValue[0],
            sortDirection: sortingValue[1],
          }
        : null,
    };
  }

  useEffect(() => {
    getCustodianAccount();
  }, [getCustodianAccount]);

  useEffect(() => {
    if (accountCustodians) {
      setResult({ ...accountCustodians });
    }
  }, [accountCustodians]);

  const [limits, setLimits] = useState<{
    page: number;
    limit: number;
    sort: SortType | null;
  }>(initialLimits);

  const [search, setSearch] = useState('');

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [updateErrorMessage, setUpdateErrorMessage] = useState<string | null>(
    null,
  );
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [editCustodianId, setEditCustodianId] = useState<string | null>(null);

  useEffect(() => {
    getPagingAccountCustodians({
      variables: {
        page: limits.page,
        limit: limits.limit,
        query: search,
        ...limits.sort,
      },
    });
  }, [getPagingAccountCustodians, limits, search]);

  useEffect(() => {
    getAllPagingAccountCustodians({
      variables: {
        page: 0,
        limit: 1000000,
        query: '',
      },
    });
  }, [getAllPagingAccountCustodians]);

  const onAddCustodian = (data: AddCustodianMutationVariables['custodian']) => {
    tracker.track('Coordinator Added');
    addCustodian({ variables: { custodian: { ...data } } });
  };

  const debouncedSearch = useCallback(debounce(setSearch, 300), [setSearch]);

  const onResetPassword = (custodianId: string) => {
    tracker.track('Coordinate Reset Password from Settings');
    resetCustodianPassword({ variables: { custodianId } });
  };

  const onUpdateCustodian = (
    custodianId: string,
    data: AddCustodianMutationVariables['custodian'],
  ) => {
    tracker.track('Coordinator Updated');
    updateCustodian({ variables: { custodianId, custodian: { ...data } } });
  };

  const defaultColumns: MUIDataTableColumn[] = React.useMemo(
    () => [
      {
        name: 'id',
        options: {
          display: false,
          filter: false,
          sort: true,
          sortThirdClickReset: true,
        },
      },
      {
        name: 'firstName',
        label: 'Name',
        options: {
          sortDescFirst: true,
          filter: false,
        },
      },
      {
        name: 'lastName',
        label: 'Last Name',
        options: {
          display: false,
        },
      },
      {
        name: 'email',
        label: 'Email',
        options: {
          sort: true,
          sortThirdClickReset: true,
        },
      },
      {
        name: 'clinicalCredential',
        label: 'Clinical Credential',
        options: {
          display: account?.channel === 'b2b',
          sort: true,
          sortThirdClickReset: true,
        },
      },
      {
        name: 'phone',
        label: 'phone',
        options: {
          display: false,
        },
      },
      {
        name: 'notifPrefs',
        label: 'Notify Prefs',
        options: {
          display: false,
          customBodyRender: (value: Array<any>, tableMeta) => {
            return (
              <Typography>
                {value.reduce((acc, current) => {
                  return `${acc} ${current}`;
                }, '')}
              </Typography>
            );
          },
        },
      },
      {
        name: 'admin',
        label: 'Admin',
        options: {
          display: false,
          customBodyRender: (value, tableMeta) => {
            return value ? (
              <Typography>Yes</Typography>
            ) : (
              <Typography>No</Typography>
            );
          },
        },
      },

      {
        name: 'promptForCancellationReason',
        label: 'Prompt For Cancellation Reason',
        options: {
          display: false,
          customBodyRender: (value, tableMeta) => {
            return value ? (
              <Typography>Yes</Typography>
            ) : (
              <Typography>No</Typography>
            );
          },
        },
      },
      {
        name: 'actions',
        label: 'Actions',
        options: {
          customBodyRender: (value, tableMeta) => {
            return (
              <ActionMenu
                custodianId={
                  // @ts-ignore
                  tableMeta.currentTableData[tableMeta.rowIndex].data[0]
                }
                setEditCustodianId={setEditCustodianId}
                onResetPassword={onResetPassword}
              />
            );
          },
        },
      },
    ],
    [custodians],
  );

  const closeAddModal = () => {
    setAddDialogOpen(false);
    setErrorMessage(null);
  };

  const openAddModal = () => {
    setAddDialogOpen(true);
  };

  const [columns, setColumns] = useState<MUIDataTableColumn[]>(defaultColumns);

  const csvData = useCSVCustodianData(accountAllCustodians, columns, [
    'actions',
  ]);

  if (!custodians || !metaData || !custodianAccount) {
    return null;
  }

  const selectedCustodian = custodians.find(c => c.id === editCustodianId);

  const options: MUIDataTableOptions = {
    download: false,
    filter: false,
    serverSide: true,
    confirmFilters: true,
    enableNestedDataAccess: '?.',
    onSearchChange: text => {
      if (text) {
        debouncedSearch(text);
      }
    },
    onSearchClose: () => setSearch(''),
    customToolbar: () => {
      return (
        <>
          <Tooltip title={'Invite Coordinator'}>
            <IconButton
              onClick={() => {
                if (custodianAccount?.profile?.canInvite) {
                  openAddModal();
                }
              }}
            >
              <PersonAddIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Download CSV'}>
            <CSVLink
              style={{
                pointerEvents:
                  allDataLoading || !!allDataError ? 'none' : 'auto',
              }}
              filename={'Custodians.csv'}
              headers={csvData.headers}
              data={csvData.result}
            >
              <IconButton disabled={allDataLoading || !!allDataError}>
                <CloudDownloadIcon />
              </IconButton>
            </CSVLink>
          </Tooltip>
          <Tooltip title={'Reset Options to Default'}>
            <IconButton
              onClick={() => {
                accountCustodianStorage.clearStorage();

                setColumns(prev => {
                  let changedColumns = cloneDeep(prev);

                  changedColumns = changedColumns.map((changeColumn, ind) => {
                    changeColumn.options = {
                      ...changeColumn.options,
                      display: defaultColumns[ind]?.options?.display ?? true,
                    };

                    return changeColumn;
                  });
                  return changedColumns;
                });

                setLimits(defaultLimits);
              }}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </>
      );
    },
    onColumnSortChange: (
      changedColumn: string,
      sortDirection: MUISortOptions['direction'],
    ) => {
      setLimits(prev => ({
        ...prev,
        sort: { sortDirection, sortBy: changedColumn },
      }));

      accountCustodianStorage.setData({
        sortingValue: [changedColumn, sortDirection],
      });
    },
    onViewColumnsChange: (changedColumn: string, action: string) => {
      setColumns(prev => {
        let changedColumns = cloneDeep(prev);

        changedColumns.forEach(column => {
          if (column.name === changedColumn) {
            column.options = {
              ...column.options,
              display: action !== 'remove',
            };
          }
        });

        return changedColumns;
      });
      accountCustodianStorage.setData({
        columnView: { [changedColumn]: action as 'remove' | 'add' },
      });
    },
    page: metaData.currentPage - 1,
    count: metaData.totalCount,
    rowsPerPage: metaData.limitValue,
    rowsPerPageOptions: [5, 10, 15, 25],
    jumpToPage: true,
    onChangePage: currentPage => {
      setLimits(prev => ({
        ...prev,
        page: currentPage + 1,
      }));
      accountCustodianStorage.setData({ page: currentPage + 1 });
    },
    onChangeRowsPerPage: (limit: number) => {
      setLimits(prev => ({
        ...prev,
        limit,
      }));
      accountCustodianStorage.setData({ limit });
    },
    filterType: 'dropdown',
    tableBodyHeight: '80%',
    selectableRows: 'none',
    expandableRowsHeader: false,
    textLabels: {
      body: {
        noMatch:
          custodians.length > 0
            ? 'There are no custodians that match your filters.'
            : 'No custodians',
      },
    },
  };

  return (
    <>
      <MUIDataTable
        title={'Coordinators'}
        data={custodians}
        columns={columns}
        options={options}
      />
      <AddCoordinatorDialog
        handleCreate={onAddCustodian}
        handleClose={closeAddModal}
        errorMessage={errorMessage}
        open={!!addDialogOpen}
        channel={account?.channel}
      />
      {selectedCustodian && (
        <EditCoordinatorDialog
          custodian={selectedCustodian as Custodian}
          handleUpdate={onUpdateCustodian}
          handleClose={() => setEditCustodianId(null)}
          errorMessage={updateErrorMessage}
          open={!!editCustodianId}
          channel={account?.channel}
        />
      )}
    </>
  );
};
