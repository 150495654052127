import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Link as MUILink,
} from '@material-ui/core';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import GroupIcon from '@material-ui/icons/Group';
import HistoryIcon from '@material-ui/icons/History';
import FaceIcon from '@material-ui/icons/Face';
import AccountIcon from '@material-ui/icons/AccountCircle';
import TimelineIcon from '@material-ui/icons/Timeline';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useLocation } from 'react-router-dom';
import { useZendesk } from 'components/zendesk/Zendesk';
import { useAuth } from '../../contexts/auth-context';
import { useApolloClient } from '@apollo/client';
import HelpInformation from 'components/HelpInformation';
import {
  accountCustodianStorage,
  historyStorage,
  ridersStorage,
  upcomingRidesStorage,
} from '../../storages/storage';
import ENV from '../../constants/Env';

const useStyles = makeStyles(theme => ({
  header: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  bookButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  drawer: {
    height: '100%',
    display: 'flex',
  },
  termsBox: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
  },
}));

export default function DrawerContent({ account, profile }) {
  const classes = useStyles();
  const location = useLocation();

  const auth = useAuth();
  const apolloClient = useApolloClient();

  useZendesk(profile.id);
  return (
    <Grid container className={classes.drawer} direction="column">
      <Box flexGrow={1}>
        <div className={classes.header}>
          <Typography variant="subtitle2" noWrap>
            {account.name}
          </Typography>
          <Typography variant="h6" noWrap>
            Onward Dashboard
          </Typography>
          {!location.pathname.includes('/rides/new') &&
            !location.pathname.includes('/copy') &&
            !location.pathname.includes('/edit') && (
              <Button
                className={classes.bookButton}
                variant="contained"
                color="primary"
                component={Link}
                to="/rides/new"
              >
                Book Transportation
              </Button>
            )}
        </div>
        <List>
          <ListItem button key="upcoming" component={Link} to="/">
            <ListItemIcon>
              <DirectionsCarIcon color="primary" />
            </ListItemIcon>
            <ListItemText color="primary" primary="Upcoming Rides" />
          </ListItem>
          <ListItem button key="riders" component={Link} to="/riders">
            <ListItemIcon>
              <GroupIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Rider Profiles" />
          </ListItem>
          <ListItem button key="history" component={Link} to="/rideHistory">
            <ListItemIcon>
              <HistoryIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Ride History" />
          </ListItem>
          {profile.admin && !auth.isUsingMagic && (
            <ListItem button key="magic" component={Link} to="/magic">
              <ListItemIcon>
                <FaceIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Magic" />
            </ListItem>
          )}
          {auth.isUsingMagic && (
            <ListItem
              button
              key="unmagic"
              onClick={() => {
                ridersStorage.clearStorage();
                upcomingRidesStorage.clearStorage();
                historyStorage.clearStorage();
                accountCustodianStorage.clearStorage();
                auth.removeBecomeCustodian(apolloClient);
              }}
            >
              <ListItemIcon>
                <FaceIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="UnMagic" />
            </ListItem>
          )}
          {profile.canAccessBi && (
            <ListItem button key="portal" component="a" href={ENV.portalUrl}>
              <ListItemIcon>
                <TimelineIcon color="primary" />
              </ListItemIcon>
              <ListItemText color="primary" primary="BI Portal" />
            </ListItem>
          )}
          {profile.canCreate && (
            <ListItem button key="accounts" component={Link} to="/accounts">
              <ListItemIcon>
                <AccountIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Accounts" />
            </ListItem>
          )}
        </List>
        {/*<Zendesk />*/}
      </Box>
      <Box>
        <Divider className={classes.divider} />
        <HelpInformation />
      </Box>
      <Box className={classes.termsBox}>
        <Typography variant="subtitle2">
          <MUILink
            href="https://onwardrides.com/terms"
            target="_blank"
            color="inherit"
          >
            Terms of Service
          </MUILink>
        </Typography>
        <Typography variant="subtitle2">
          <MUILink
            href="https://onwardrides.com/privacy"
            target="_blank"
            color="inherit"
          >
            Privacy
          </MUILink>
        </Typography>
      </Box>
    </Grid>
  );
}
