import { useMutation } from '@apollo/client';
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { useAuth } from '../contexts/auth-context';
import { loader } from 'graphql.macro';
import { useTracking } from 'lib/analytics/Tracker';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import onwardPin from '../images/logo192.png';

const UPDATE_CUSTODIAN_MUTATION = loader(
  '../data/mutations/UpdateCustodianMutation.graphql',
);

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(5),
  },
  helpText: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  alert: {
    marginTop: theme.spacing(2),
  },
}));

function UpdatePasswordComponent({ updateError, onUpdate }) {
  const classes = useStyles();
  const { register, handleSubmit, getValues, errors } = useForm();

  const onSubmit = data => {
    onUpdate(data);
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar alt="Onward Pin" className={classes.avatar} src={onwardPin} />

        <Typography component="h1" variant="h5">
          New Password
        </Typography>
        {updateError && (
          <Alert severity="error" className={classes.alert}>
            There was an error updating your password. Try again and if this
            persists please contact Onward at 1-800-700-4797.
          </Alert>
        )}

        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box className={classes.section}>
            <Typography variant="body1" className={classes.helpText}>
              Enter a new password and then you'll be taken to your dashboard.
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="password"
                  fullWidth
                  name="password"
                  label="Password"
                  error={errors.password}
                  autoComplete="current-password"
                  type="password"
                  helperText={
                    errors.password &&
                    'The entered password does not match the confirmation'
                  }
                  inputRef={register({
                    validate: p => {
                      let passwordConfirmation = getValues(
                        'passwordConfirmation',
                      );
                      if (p || passwordConfirmation) {
                        return p === passwordConfirmation;
                      }
                    },
                  })}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="passwordConfirmation"
                  fullWidth
                  name="passwordConfirmation"
                  label="Password Confirmation"
                  type="password"
                  error={errors.password}
                  autoComplete="current-password"
                  inputRef={register()}
                />
              </Grid>
            </Grid>
          </Box>
          <Button
            key="updateButton"
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.submit}
          >
            Update Password
          </Button>
        </form>
      </div>
    </Container>
  );
}

export default function UpdatePassword() {
  const [updateError, setUpdateError] = useState(false);
  const tracker = useTracking();
  let history = useHistory();
  let auth = useAuth();

  useEffect(() => {
    auth.storeHeadersFromQuery();
    tracker.track('Update Password Page Viewed');
  }, [auth, tracker]);

  const onCompleted = () => {
    tracker.track('Password Successfully Updated');

    history.replace({ pathname: '/' });
  };

  const onError = () => {
    tracker.track('Error Updating Password');

    setUpdateError(true);
  };

  const [updateCustodian] = useMutation(UPDATE_CUSTODIAN_MUTATION, {
    onCompleted,
    onError,
  });

  const onUpdate = data => {
    updateCustodian({ variables: { custodian: { password: data.password } } });
  };

  return (
    <UpdatePasswordComponent updateError={updateError} onUpdate={onUpdate} />
  );
}
