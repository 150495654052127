import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import Help from '@material-ui/icons/Help';
import { makeStyles } from '@material-ui/core/styles';
import { ReactNode } from 'react';
import { useEhrContext } from '../contexts/ehr-context';

const useStyles = makeStyles(theme => ({
  helpIcon: {
    minWidth: '40px',
  },
  needHelp: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const contactInfo = {
  email: 'support@onwardrides.com',
  telephone: {
    display: '1 (800) 700-4797',
    value: '+18007004797',
  },
};

function EmailListItem({
  showAsLink,
  children,
}: {
  showAsLink: boolean;
  children: ReactNode;
}) {
  return showAsLink ? (
    <ListItem
      button
      key="email"
      component="a"
      href={`mailto:${contactInfo.email}`}
      target="_blank"
    >
      {children}
    </ListItem>
  ) : (
    <ListItem key="email">{children}</ListItem>
  );
}

function TelephoneListItem({
  showAsLink,
  children,
}: {
  showAsLink: boolean;
  children: ReactNode;
}) {
  return showAsLink ? (
    <ListItem
      button
      key="call"
      component="a"
      href={`tel://${contactInfo.telephone.value}`}
    >
      {children}
    </ListItem>
  ) : (
    <ListItem key="call">{children}</ListItem>
  );
}

export default function HelpInformation() {
  const classes = useStyles();
  const { isEhrPatientContext } = useEhrContext();

  return (
    <List dense>
      <Typography className={classes.needHelp} variant="subtitle2">
        Need help?
      </Typography>

      <TelephoneListItem showAsLink={isEhrPatientContext === false}>
        <ListItemIcon className={classes.helpIcon}>
          <PhoneIcon />
        </ListItemIcon>
        <ListItemText secondary={contactInfo.telephone.display} />
      </TelephoneListItem>
      <EmailListItem showAsLink={isEhrPatientContext === false}>
        <ListItemIcon className={classes.helpIcon}>
          <MailIcon />
        </ListItemIcon>
        <ListItemText secondary={contactInfo.email} />
      </EmailListItem>

      {isEhrPatientContext === false && (
        <ListItem
          button
          key="help"
          component="a"
          href="https://onwardrides.zendesk.com/hc/en-us/categories/8697550582935-Health-System-Facility-Partners"
          target="_blank"
        >
          <ListItemIcon className={classes.helpIcon}>
            <Help />
          </ListItemIcon>
          <ListItemText secondary="Help Library" />
        </ListItem>
      )}
    </List>
  );
}
