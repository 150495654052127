import React from 'react';

import Convertible from 'images/cars/Convertible';
import Coupe from 'images/cars/Coupe';
import Hatchback from 'images/cars/Hatchback';
import Minivan from 'images/cars/Minivan';
import Pickup from 'images/cars/Pickup';
import Sedan from 'images/cars/Sedan';
import Suv from 'images/cars/Suv';
import Wagon from 'images/cars/Wagon';

const VehicleImage = ({ vehicle }) => {
  const vehicleColor = () => {
    switch (vehicle.color) {
      case 'Beige':
        return '#ddd1b4';
      case 'Black':
        return '#31352d';
      case 'Blue':
        return '#42a7c4';
      case 'Brown':
        return '#57280b';
      case 'Bronze':
        return '#be9853';
      case 'Copper':
        return '#bd8648';
      case 'Cream':
        return '#f6e8d7';
      case 'Gold':
        return '#b69a74';
      case 'Gray':
        return '#727272';
      case 'Green':
        return '#3d9265';
      case 'Maroon':
        return '#6f1a43';
      case 'Metallic':
        return '#a3a5a8';
      case 'Navy':
        return '#205261';
      case 'Orange':
        return '#ae5017';
      case 'Pink':
        return '#d59595';
      case 'Purple':
        return '#895881';
      case 'Red':
        return '#d26262';
      case 'Silver':
        return '#dfdfdf';
      case 'Tan':
        return '#b0a38a';
      case 'Turquoise':
        return '#1cc1b2';
      case 'White':
        return '#fbf9f9';
      case 'Yellow':
        return '#ddcd6e';
      default:
        return '#31352d';
    }
  };

  const props = { color: vehicleColor() };

  switch (vehicle.vehicleType) {
    case 'Convertible':
      return <Convertible {...props} />;
    case 'Coupe':
      return <Coupe {...props} />;
    case 'Hatchback':
      return <Hatchback {...props} />;
    case 'Van/Minivan':
      return <Minivan {...props} />;
    case 'Pickup':
      return <Pickup {...props} />;
    case 'Sedan':
      return <Sedan {...props} />;
    case 'SUV':
      return <Suv {...props} />;
    case 'Wagon':
      return <Wagon {...props} />;
    default:
      return <Sedan {...props} />;
  }
};

export default VehicleImage;
