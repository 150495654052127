const groupBy = function <T extends Array<any>>(
  arr: T,
  criteria: ((value: T[number]) => string) | number | string,
) {
  return arr.reduce<Record<string, Array<T[number]>>>((obj, item) => {
    const key =
      typeof criteria === 'function' ? criteria(item) : item[criteria];

    if (!obj.hasOwnProperty(key)) {
      obj[key] = [];
    }

    obj[key].push(item);

    return obj;
  }, {});
};

export { groupBy };
