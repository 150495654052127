import { ReactNode } from 'react';
import { Box, Typography } from '@material-ui/core';
import { TooltipButton } from './TooltipButton';

type Props = {
  labelText: ReactNode;
  tooltipText: ReactNode;
  style?: any;
  wrapText?: boolean;
  onHover?: boolean;
  icon?: ReactNode;
  labelStyle?: any;
};

export const LabelWithTooltip = ({
  labelText,
  tooltipText,
  style = {},
  wrapText = false,
  onHover = false,
  icon,
  labelStyle,
}: Props) => {
  return (
    <Box display="flex" alignItems="center" style={style}>
      <div style={{ marginRight: 3 }}>
        <Typography noWrap={wrapText} variant="body1" style={{ ...labelStyle }}>
          {labelText}
        </Typography>
      </div>
      <TooltipButton title={tooltipText} onHover={onHover} icon={icon} />
    </Box>
  );
};
