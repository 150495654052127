import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { RideTimeHelperProps } from './props';
import { useStyles } from './styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { RideTimeError } from './RideTimeError/RideTimeError';
import { RideTimeHelperForm } from './RideTimeHelperForm/RideTimeHelperForm';
import { convertLocalToISOInTimeZone, timeOnly } from '@onwardcare/core';
import { isBefore } from 'date-fns';
import { useSessionContext } from '../../../../contexts/session-context';

const DEFAULT_BUFFER_MINUTES = 15;

const _RideTimeHelper: React.FC<RideTimeHelperProps> = ({
  defaultDate,
  defaultTime,
  disabled,
  ride,
  bufferMinutes,
  handleSubmit,
  isFlexibleRide,
}) => {
  const classes = useStyles();
  const { session } = useSessionContext();
  const timeZone = session?.account?.tzName ?? undefined;

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [arrivalTime, setArrivalTime] = useState<string>('');
  const [arrivalDate, setArrivalDate] = useState<Date | null>(null);
  const [pickupTime, setPickupTime] = useState<any>('');
  const [additionalTime, setAdditionalTime] = useState<number>(
    bufferMinutes ?? DEFAULT_BUFFER_MINUTES,
  );

  const [localDisabled, setLocalDisabled] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const additionalTimeOptions = [0, 10, 15, 30, 45, 60];

  const setDataToDefault = () => {
    setArrivalDate(null);
    setArrivalTime('');
    setPickupTime('');
    setAdditionalTime(DEFAULT_BUFFER_MINUTES);
  };

  const handleTimeHelperClick = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDataToDefault();
    setDialogOpen(false);
  };

  const onSubmit = () => {
    // We don't want to convert this to a time zone as we just want to take the
    // time string string the user entered.
    const timeString = timeOnly(pickupTime, {
      timeFormat: '24',
      hideTimeZone: true,
    });
    handleSubmit(additionalTime, timeString, pickupTime);
    setDialogOpen(false);
  };

  useEffect(() => {
    // Since the pickup time is entered in the specified time zone, but the local
    // time zone could be different, we need to convert the pickup time to the
    // specified time zone first.
    const pickup =
      pickupTime && new Date(convertLocalToISOInTimeZone(pickupTime, timeZone));
    setLocalDisabled(isBefore(pickup, new Date()));
  }, [pickupTime, setLocalDisabled, timeZone]);

  const buttonDisabled = disabled || isFlexibleRide;

  return (
    <div>
      <IconButton
        className={classes.buttonIcon}
        disabled={buttonDisabled}
        onClick={handleTimeHelperClick}
      >
        <Button
          disabled={buttonDisabled}
          onClick={handleTimeHelperClick}
          variant="contained"
          color="primary"
        >
          <div className={classes.button}>
            <AccessTimeIcon className={classes.icon} />
            <Typography className={classes.label}>
              Arrive by Calculator
            </Typography>
          </div>
        </Button>
      </IconButton>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Arrive By Time Calculator</DialogTitle>
        <DialogContent>
          <Typography>
            Use the form below to help calculate a pickup time to ensure on time
            arrival.
          </Typography>
          <RideTimeHelperForm
            pickupTime={pickupTime}
            setPickupTime={setPickupTime}
            defaultDate={defaultDate}
            defaultTime={defaultTime}
            bufferMinutes={additionalTime}
            setBufferMinutes={setAdditionalTime}
            arrivalDate={arrivalDate}
            setArrivalDate={setArrivalDate}
            arrivalTime={arrivalTime}
            setArrivalTime={setArrivalTime}
            bufferMinutesOptions={additionalTimeOptions}
            ride={ride}
            setError={setError}
          />
        </DialogContent>
        {localDisabled && (
          <RideTimeError error="The calculated pickup time is in the past. Please select a future time." />
        )}
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
          {!error && (
            <Button
              onClick={onSubmit}
              color="primary"
              variant="contained"
              disabled={localDisabled}
            >
              Update Pickup Time
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const RideTimeHelper = React.memo(_RideTimeHelper);
